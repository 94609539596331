<template>
  <div class="container mt-3">
    <div style="padding: 0px;" ref="document" id="my_invoice">
      <div class="row">
        <div class="col-md-6"><input value="คำขอที่" readonly class="title" style="width:15%;" /><input
            v-model="datas.request_at" readonly type="text" class="content" name="name" style="width:30%;" /></div>
      </div>
      <div class="row">
        <div class="col-md-6"><input value="รับวันที่" readonly class="title" style="width:15%;" /><input
            v-model="datas.request_date" readonly type="text" class="content" name="name" style="width:30%;" /></div>
      </div>
      <div class="row">
        <div class="col-md-6"><input value="ผู้รับ" readonly class="title" style="width:15%;" /><input
            v-model="datas.request_receiver" readonly type="text" class="content" name="name" style="width:30%;" /></div>
      </div>
      <div style="text-align: center; background-color: white; margin: 0px;" class="mb-3">
        <label style="text-align: center; font-size: 18px">คำขออนุญาตให้ใช้รถผิดประเภท</label><br />
        <label style="text-align: center; font-size: 18px">และใช้รถทำการขนส่งนอกเส้นทางหรือนอกท้องที่</label>
      </div>
      <div class="row">
        <div class="col-md-8" style="text-align:right"></div>
        <div class="col-md-4"><input value="เขียนที่" readonly class="title" style="width:20%;" /><input
            v-model="datas.write_at" readonly type="text" class="content" name="name" style="width:80%;" /></div>
      </div>
      <div class="row">
        <div class="col-md-7" style="text-align:right"></div>
        <div class="col-md-5"><input value="วันที่" readonly class="title" style="width:12%;" /><input
            v-model="datas.write_dates" readonly type="text" class="content" name="name" style="width:15%;" />
          <input value="เดือน" readonly class="title" style="width:15%;" /><input v-model="datas.write_month" readonly
            type="text" class="content" name="name" style="width:25%;" />
          <input value="พ.ศ." readonly class="title" style="width:11%;" /><input v-model="datas.write_year" readonly
            type="text" class="content" name="name" style="width:22%;" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <input value="เรียน นายทะเบียน" readonly class="title" style="width:100%;" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" style="margin-left:60px;"><input value="ด้วยข้าพเจ้า (นาย, นาง, นางสาว)" readonly
            class="title" style="width:30%;" /><input v-model="datas.name" readonly type="text" class="content"
            name="name" style="width:60%;" /></div>

        <div class="col-md-5"><input value="ซึ่งมีอำนาจลงนามผูกพัน" readonly class="title" style="width:55%;" /> <input
            v-model="datas.relate" readonly type="text" class="content" name="name" style="width:40%;" /></div>
        <div class="col-md-4"><input value="สำนักงานตั้งอยู่ที่" readonly class="title" style="width:50%;height:90%;" />
          <input v-model="datas.address" readonly type="text" class="content" name="name" style="width:50%;" />
        </div>
        <div class="col-md-3"><input value="หมู่ที่" readonly class="title" style="width:13%;" /> <input
            v-model="datas.moo" readonly type="text" class="content" name="name" style="width:83%;" /></div>

        <div class="col-md-2"><input value="ซอย" readonly class="title" style="width:30%;" /> <input v-model="datas.soi"
            readonly type="text" class="content" name="name" style="width:50%;" /></div>
        <div class="col-md-2"><input value="ถนน" readonly class="title" style="width:30%;height:90%;" /> <input
            v-model="datas.road" readonly type="text" class="content" name="name" style="width:60%;" /></div>
        <div class="col-md-4"><input value="ตำบล" readonly class="title" style="width:20%;" /> <input
            v-model="datas.tambon_th" readonly type="text" class="content" name="name" style="width:70%;" /></div>
        <div class="col-md-4"><input value="เขต/อำเภอ" readonly class="title" style="width:35%;" /> <input
            v-model="datas.amphur_th" readonly type="text" class="content" name="name" style="width:60%;" /></div>
        <div class="col-md-3"><input value="จังหวัด" readonly class="title" style="width:30%;" /> <input
            v-model="datas.province_th" readonly type="text" class="content" name="name" style="width:60%;height:80%;" />
        </div>
        <div class="col-md-3"><input value="โทรศัพท์" readonly class="title" style="width:35%;" /> <input
            v-model="datas.phone" readonly type="text" class="content" name="name" style="width:60%;height:80%;" />
        </div>
        <div class="col-md-6"><input value="ได้รับใบอนุญาตประกอบการขนส่งประเภท" readonly class="title"
            style="width:80%;" />
          <input v-model="datas.type_name" readonly type="text" class="content" name="name"
            style="width:20%;height:80%;" />
        </div>
        <div class="col-md-2"><input value="เลขที่" readonly class="title" style="width:40%;" />
          <input v-model="datas.number" readonly type="text" class="content" name="name" style="width:60%;height:80%;" />
        </div>
        <div class="col-md-10"><input value="มีความประสงค์ที่จะขอใช้รถดังต่อไปนี้" readonly class="title"
            style="width:70%;" />
        </div>
        <div class="col-md-12" style="margin-left:60px;"><input value="๑. เลขทะเบียน" readonly class="title"
            style="width:15%;" />
          <input v-model="datas.license_no1" readonly type="text" class="content" name="name"
            style="width:30%;height:80%;" />
        </div>
        <div class="col-md-12" style="margin-left:60px;"><input value="๒. เลขทะเบียน" readonly class="title"
            style="width:15%;" />
          <input v-model="datas.license_no2" readonly type="text" class="content" name="name"
            style="width:30%;height:80%;" />
        </div>
        <div class="col-md-12" style="margin-left:60px;"><input value="๓. เลขทะเบียน" readonly class="title"
            style="width:15%;" />
          <input v-model="datas.license_no3" readonly type="text" class="content" name="name"
            style="width:30%;height:80%;" />
        </div>
        <div class="col-md-12" style="margin-left:60px;"><input
            value="(ในกรณีที่เป็นรถประจำทางหรือรถขนาดเล็กให้ระบุหมายเลขเส้นทางและหมายเลขประจำรถด้วย)" readonly
            class="title" style="width:100%;" />
        </div>
        <div style="text-align: justify;" class="col-md-12"><input value="ทำการขนส่ง" readonly class="title"
            style="width:15%;" />
          <input class="form-check-input" readonly type="checkbox" style="width:2%;" value="wrongtype" id="wrongtype"
            v-model="datas.wrongtype">
          <input value="ผิดไปจากประเภทที่จดทะเบียนไว้" readonly class="title" style="width:35%;" />
          <input class="form-check-input" readonly type="checkbox" style="width:2%;" value="offtrack" id="offtrack"
            v-model="datas.offtrack">
          <input value="นอกเส้นทาง" readonly class="title" style="width:15%;" />
          <input class="form-check-input" readonly type="checkbox" style="width:2%;" value="outside" id="outside"
            v-model="datas.outside">
          <input value="นอกท้องที่" readonly class="title" style="width:10%;" />
          <input value="เพื่อใช้ในกิจการ" readonly class="title" style="width:15%;" />
          <input v-model="datas.business" readonly type="text" class="content" name="name"
            style="width:10%;height:80%;" />

        </div>
        <div class="col-md-3">
          <input value="ของ" readonly class="title" style="width:20%;" />
          <input v-model="datas.of" readonly type="text" class="content" name="name" style="width:80%;height:80%;" />
        </div>
        <div class="col-md-3">
          <input value="ตั้งแต่วันที่" readonly class="title" style="width:40%;" />
          <input v-model="datas.from_dates" readonly type="text" class="content" name="name"
            style="width:50%;height:80%;" />
        </div>
        <div class="col-md-3">
          <input value="เดือน" readonly class="title" style="width:25%;" />
          <input v-model="datas.from_month" readonly type="text" class="content" name="name"
            style="width:70%;height:80%;" />
        </div>
        <div class="col-md-3">
          <input value="พ.ศ." readonly class="title" style="width:25%;" />
          <input v-model="datas.to_year" readonly type="text" class="content" name="name" style="width:70%;height:80%;" />
        </div>
        <div class="col-md-3">
          <input value="เวลา" readonly class="title" style="width:20%;" />
          <input v-model="datas.to_time" readonly type="text" class="content" name="name" style="width:80%;height:80%;" />
        </div>
        <div class="col-md-3">
          <input value="ถึงวันที่" readonly class="title" style="width:40%;" />
          <input v-model="datas.to_dates" readonly type="text" class="content" name="name"
            style="width:50%;height:80%;" />
        </div>
        <div class="col-md-3">
          <input value="เดือน" readonly class="title" style="width:25%;" />
          <input v-model="datas.to_month" readonly type="text" class="content" name="name"
            style="width:70%;height:80%;" />
        </div>
        <div class="col-md-3">
          <input value="พ.ศ." readonly class="title" style="width:25%;" />
          <input v-model="datas.to_year" readonly type="text" class="content" name="name" style="width:70%;height:80%;" />
        </div>
        <div class="col-md-2">
          <input value="เวลา" readonly class="title" style="width:35%;" />
          <input v-model="datas.to_time" readonly type="text" class="content" name="name" style="width:60%;height:80%;" />
        </div>
        <div class="col-md-5">
          <input value="โดยมีต้นทางที่" readonly class="title" style="width:32%;" />
          <input v-model="datas.origin" readonly type="text" class="content" name="name" style="width:60%;height:80%;" />
        </div>
        <div class="col-md-5">
          <input value="ผ่านจังหวัด" readonly class="title" style="width:30%;" />
          <input v-model="datas.stop" readonly type="text" class="content" name="name" style="width:65%;height:80%;" />
        </div>
        <div class="col-md-4">
          <input value="สิ้นสุดปลายทางที่" readonly class="title" style="width:50%;" />
          <input v-model="datas.destination" readonly type="text" class="content" name="name"
            style="width:48%;height:80%;" />
        </div>
        <div class="col-md-8">
          <input value="และกลับจากปลายทางโดยผ่านจังหวัด" readonly class="title" style="width:50%;" />
          <input v-model="datas.stop_through" readonly type="text" class="content" name="name"
            style="width:48%;height:80%;" />
        </div>
        <div class="col-md-12" style="margin-left:60px;"><input
            value="ข้าพเจ้าขอรับรองว่าเมื่อนำรถโดยสารประจำทางหรือรถขนาดเล็กจำนวนดังกล่าวข้างต้นไปใช้ผิดประเภท" readonly
            class="title" style="width:100%;" />
        </div>
        <div class="col-md-12"><input
            value="หรือใช้ทำการขนส่งนอกเส้นทางแล้ว จะมีรถเหลืออยู่ ไม่น้อยกว่าจำนวนรถขั้นต่ำตามเงื่อนไขที่กำหนดไว้ในใบอนุญาตประกอบการขนส่ง"
            readonly class="title" style="width:100%;" />
        </div>
        <div class="col-md-12"><input value="ประกอบการขนส่ง" readonly class="title" style="width:100%;" />
        </div>

      </div>


      <!-- <div class="row mt-3" style="text-align: center; font-size: 14px">
      <div class="col-sm-6">
        <span style="text-align: center"
          >ลงชื่อ...............................................................ผู้รับเดินรถร่วม</span
        >
        <br />
        <span v-if="datas.signone"
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{datas.signone}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
        <span v-else
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
      </div>
      <div class="col-sm-6">
        <span style="text-align: center"
          >ลงชื่อ..................................................................ผู้เดินรถร่วม</span
        >
        <br />
        <span v-if="datas.signtwo"
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{datas.signtwo}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
        <span v-else
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
      </div>
      <div class="col-sm-6 mt-3">
        <span style="text-align: center"
          >ลงชื่อ.............................................................................พยาน</span
        >
        <br />
        <span v-if="datas.signthree"
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{datas.signthree}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
        <span v-else
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
      </div>
      <div class="col-sm-6 mt-3">
        <span style="text-align: center"
          >ลงชื่อ.......................................................................พยาน</span
        >
        <br />
       <span v-if="datas.signfour"
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{datas.signfour}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
        <span v-else
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
      </div>
    </div> -->
    </div>
    <div class="row mt-3 mb-5">
      <div class="col-md-12 text-center">
        &nbsp;
        <button class="btn btn-warning" style="color: white; width: 200px" @click="edit()">
          แก้ไข
        </button>
        &nbsp;
        <button class="btn btn-info" style="color: white; width: 200px" @click="exportToPDF()">
          ดาวน์โหลดไฟล์
        </button>
      </div>
    </div>
  </div>
</template>

<script src="https://api-owb.visdchiangrai.com/picker_date.js"></script>

<script>
import PermissionDocService from '../services/PermissionDocService.js'
import html2pdf from 'html2pdf.js'
import LinkImageService from '../services/LinkImageService'
import axios from 'axios';

export default {
   
  name: "Cars",
  props: {
    msg: String,
  },
  components: {
  },
  data() {
    return {
      brands: [],
      characteristics: [],
      symbols: [],
      car_types: [],
      datas: [],
      car_id: 0,
      image: "",
      itemPreviewImage: "",
      symbol: [],
      statusedit: false,
      docs:[]
    };
  },
  methods: {
    dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
},

    async exportToPDF () {
        var element = this.$refs.document
        var opt = {
          margin: [1,1.5,0,1.2],
          filename: 'คำขออนุญาตให้ใช้รถผิดประเภท.pdf',
          jsPDF: { unit: 'cm', format: 'a4', orientation: 'p' },
          pagebreak: {after: 'section'}
    };
let blobString = await html2pdf().from(element).set(opt).toPdf().output('datauristring');
    // console.log(blobString);
var name = this.datas.request_at.replace('/','-')
var selectedFile = this.dataURLtoFile(blobString,name+'.pdf');

const formData = new FormData();
      formData.append("file", selectedFile); 
      // console.log(selectedFile);
      var link = LinkImageService.getLink()
      var http = link + '/uploadimage?type=permissiondocs'+'&&name=' +selectedFile.name;
      axios
        .post(http, formData)
        .then(() => {    
        
        })
        .catch((err) => {
          console.log(err);
        });
        
        // this.$router.push("/permissiondocedit/" + this.car_id);
			},
    edit(){
      this.$router.push('/permissiondoc/' + this.car_id);
    },
    getdata(){
      PermissionDocService.getpermission_doc(this.car_id).then((res)=>{
        // console.log(res.data);
        this.datas = res.data;
        this.datas.request_date = this.getdateformat(this.datas.request_date)
        this.datas.write_dates = this.getdatesplit(this.datas.write_date)
        this.datas.write_month = this.getmonthsplit(this.datas.write_date)
        this.datas.write_year = this.getyearsplit(this.datas.write_date)
        this.datas.write_year = this.datas.write_year.replace("พ.ศ.",'')
        this.datas.from_dates = this.getdatesplit(this.datas.from_date)
        this.datas.from_month = this.getmonthsplit(this.datas.from_date)
        this.datas.from_year = this.getyearsplit(this.datas.from_date)
        this.datas.from_year = this.datas.from_year.replace("พ.ศ.",'')
        this.datas.from_time = this.converttime(this.datas.from_time)
        this.datas.to_dates = this.getdatesplit(this.datas.to_date)
        this.datas.to_month = this.getmonthsplit(this.datas.to_date)
        this.datas.to_year = this.getyearsplit(this.datas.to_date)
        this.datas.to_year = this.datas.to_year.replace("พ.ศ.",'')
        this.datas.to_time = this.converttime(this.datas.to_time)
        
        setTimeout(function () {
          this.exportToPDF()
        }, 500);
      })
    },
    converttime(time){
      var t = time.split(":")
      t = t[0]+'.'+t[1] +' น.'
      return t
    },
    getdateformat(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
})
// console.log(result);
return result
    },
    getdatesplit(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  day: 'numeric',
})
// console.log(result);
return result
    },
    getmonthsplit(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  month: 'short',
})
// console.log(result);
return result
    },
    getyearsplit(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  year: 'numeric',
})
// console.log(result);
return result
    }
    
  },
  mounted() {
    this.car_id = this.$route.params.id;
    // console.log(this.car_id);
    if (this.car_id != 0) {
      this.getdata()

    } else {
      this.statusedit = true;
      this.datas.status = 1;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  font-size: 14px;
  font-weight: bold;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

span {
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
  text-align: justify;
}

body {
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  font-size: 14px;
  color: black;
}

div.aa {
  text-align: justify;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

p {
  text-indent: 25px;
  text-align: justify;
  margin-bottom: 0px;
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

div {
  font-family: 'Niramit';
  font-size: 14px;
}

.title {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.content {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom-style: dotted;
  border-bottom: 1.6px dotted;
}

.printableArea {
  width: 8.5cm;
  height: 100%;
}

@page {
  size: 8cm auto;
  width: 8cm;
  height: 100%;
  margin: 0;
  margin-left: 5px !important;
}

input {
  margin-bottom: 5px;
}</style>
