<template>
  <div class="container">
    <div
      class="card mb-5"
    >             <div class="card-body login-card-body">
            <form ref="form" @submit.prevent="sendEmail">
              <div class="card-body">
                <label>ผลการพิจารณาจากหัวหน้ากลุ่ม</label>

                <div class="form-group mb-3" style="display:none;">
                  <label for="username">อีเมล<span style="color: red">*</span>  ใช้ Gmail เท่านั้น</label>
                  <input
                  name="to_email"
                    v-model="user.email"
                    type="email"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                </div>
                <div class="form-group mb-3" style="display:none;">
                  <label for="username">form_name</label>
                  <input
                  v-model="user.from_name"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    name="from_name"
                  />
                </div>
                <div class="form-check">
              <input class="form-check-input" value="1" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                v-model="user.consideration_station_master_status">
              <label class="form-check-label" for="flexRadioDefault1">
                อนุญาต
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" value="0" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                v-model="user.consideration_station_master_status">
              <label class="form-check-label" for="flexRadioDefault2">
                ไม่อนุญาต
              </label>
            </div>
            <div class="form-group mt-3">
              <label for="username">นายทะเบียน</label>&nbsp;&nbsp;&nbsp;
              <select style="width:25%" v-model="user.registrar_id">
                <option v-for="(a, i) in registrars" :key="i" :value="a.id"> {{ a.firstname }} {{ a.lastname }}</option>
              </select>
            </div>
                <div class="form-group mb-3 mt-3">
                  <label for="username">ข้อความจากหัวหน้ากลุ่มถึงนายทะเบียน</label>
                  <textarea name="message" v-model="user.station_master_comment" type="text" rows="3"
                  class="form-control form-control-sm"></textarea>
                </div>
                 <div class="row mt-3" v-if="currentUser.role_id == 3">
                  <div class="col-md-6 text-left">
                   <button
                    @click="signIn()"
                    type="button"
                    class="btn btn-success btn-sm"
                  >
                  นำเสนอนายทะเบียน
                  </button></div>
                  <div class="col-md-3"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
    </div>
</template>

<script>
import emailjs from 'emailjs-com';
import PermissionDocService from '../services/PermissionDocService.js'
import LinkImageService from '../services/LinkImageService'
import UserService from '../services/UserService'
import LogNotifyService from '../services/LogNotifyService'

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      menu: [],
      user: {},
      per_id:0,
      registrars:[]
    };
  },
  mounted() {
    UserService.getdatabyrole(4).then((res)=>{
      console.log(res.data);
      this.registrars = res.data
    })
    this.per_id = this.$route.params.id;
    PermissionDocService.getpermission_doc(this.per_id).then((res)=>{
        // console.log(res.data);
    this.user = res.data
    // this.user.from_name = 'เจ้าหน้าที่'
    // this.user.email = 'pornniparj@gmail.com'
    })
  },
  methods: {
    generateGuid() {
  var result, i, j;
  result = '';
  for(j=0; j<32; j++) {
    if( j == 8 || j == 12 || j == 16 || j == 20)
      result = result + '-';
    i = Math.floor(Math.random()*16).toString(16).toUpperCase();
    result = result + i;
  }
  return result;
},
    sendEmail() {
      var templateParams = {
        to_email: this.user.email,
        header: 'หนังสืออนุญาตคำขอที่ '+ this.user.request_at,
    message: 'หนังสืออนุญาตคำขอที่ '+ this.user.request_at+ " "+ this.user.station_master_comment+ ' ตรวจสอบได้ที่ลิงก์ ' +LinkImageService.getLinkFrontend()+'/permissiondocedit/'+this.per_id,
  };
        emailjs.send('service_ziurs0r', 'template_7bfnzks',templateParams,
        'v6IRDEPdvfhe_ir4C').then((result) => {
            console.log('SUCCESS!', result.text);
        }, (error) => {
            console.log('FAILED...', error.text);
        }
        )
        
    },
    signIn() {
      UserService.getUser(this.user.registrar_id).then((res)=>{
        this.user.email = res.data.email
      var commenttoregistrar = {
        registrar_id:this.user.registrar_id,
        consideration_station_master_status:this.user.consideration_station_master_status,
        station_master_comment:this.user.station_master_comment,
        status_id:4,
        update_by:this.currentUser.id
      }
      PermissionDocService.sendtoregistrar(this.per_id,commenttoregistrar).then(async ()=>{
      if (this.user.consideration_station_master_status == true) {      
        
        this.sendEmail()
        if (res.data.line_token != null) {
          await LinkImageService.sendNotify(this.user.station_master_comment+' '+LinkImageService.getLinkFrontend()+' /permissiondocedit/'+this.per_id,res.data.line_token) 
        var notify ={
          permission_docs_id:this.per_id,
          messages:this.user.station_master_comment,
          created_by:this.currentUser.id
}
LogNotifyService.createlog_notify(notify).then(()=>{
// console.log(res.data);
})
        alert('แจ้งไปยังนายทะเบียนเรียบร้อยแล้ว')

      }
        }else{
          alert('บันทึกสำเร็จ')
        }
      })
    })
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  font-size: 14px;
  font-weight: bold;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

span {
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
  text-align: justify;
}

body {
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  font-size: 14px;
  color: black;
}

div.aa {
  text-align: justify;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

p {
  text-indent: 25px;
  text-align: justify;
  margin-bottom: 0px;
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

div {
  font-family: 'Niramit';
  font-size: 14px;
}

.title {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.content {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom-style: dotted;
  border-bottom: 1.6px dotted;
}

input {
  margin-bottom: 5px;
}
</style>
