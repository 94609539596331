import http from "../http-common";

class CharacterService {
    getcharacters(name) {
        return http.get('/characters?name='+name);
    }
    getcharacterstable() {
        return http.get('/characters/getlist');
    }
    getcharacter(id) {
        return http.get('/characters/' + id);
    }
    createcharacter(data) {
        return http.post('/characters', data);
    }
    updatetablemain(id, data) {
        return http.put(`/characters/updatetablemain/${id}`, data);
    }
    updatecharacter(id, data) {
        return http.put(`/characters/${id}`, data);
    }
    deletecharacter(id,status) {
        return http.delete('/characters/' + id+"/"+status);
    }
}

export default new CharacterService();