<template>
  <div class="container">
    <div class="col mb-3 mt-3" style="text-align: right">
      <a>
        <button v-if="currentUser.role_id == 1" type="button" id="get_file" class="btn btn-success" @click="getid(0)"
          data-bs-toggle="modal" data-bs-target="#AddPer">
          <i class="fa fa-plus"></i> เพิ่มคำขออนุญาตใช้รถ
        </button></a>
      <!-- <a href="permissiondoc/0" v-if="currentUser.role_id == 1">
        <button
          type="button"
          id="get_file"
          class="btn btn-success"
        >
          <i class="fa fa-plus"></i> เพิ่มคำขออนุญาตใช้รถ
        </button></a
      > -->
    </div>
    <div v-for="(li, ii) in list" :key="ii">
      <table class="table table-bordered mb-5">
        <thead>
          <tr class="table-active">
            <th scope="col" colspan="6" :style="'text-align:center;background-color:' + li.color">{{ li.name }}</th>
          </tr>
        </thead>
        <thead>
          <tr class="table-active">
            <th scope="col" style="text-align:center">ลำดับที่</th>
            <th scope="col" style="text-align:center">ชื่อบริษัท</th>
            <th scope="col" style="text-align:center">คำขอที่</th>
            <th scope="col" style="text-align:center">วันที่ยื่นคำขอ</th>
            <th scope="col" style="text-align:center">สถานะ</th>
            <th scope="col"></th>
          </tr>
        </thead>

        <tbody v-if="li.data === []" style="text-align: center;">
          <tr>
            <td colspan="5">ไม่พบรายการ</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="(l, i) in li.data" :key="i">
            <td>
              {{ i + 1 }}
            </td>
            <td>
              {{ l.company_name }}
            </td>
            <td>
              {{ l.request_at }}
            </td>
            <td style="text-align:center">
              {{ getdateformat(l.created_date) }}
            </td>
            <td style="text-align:center">
              <!-- {{ l.status_id }}{{ l.consideration_station_master_status }} -->
              <!-- {{ l.consideration_registrar_status }} {{ l.registrar_id }} -->
              <!-- <span v-if="l.status_id == null || l.status_id == 1 ">ยื่นเรื่อง</span>
              <span v-if="l.status_id == 2"> แก้ไขคำขอ</span>
              <span v-if="!l.consideration_registrar_status && !l.registrar_id && l.status_id >2">รอดำเนินการ</span>
              <span v-if="l.consideration_registrar_status && l.registrar_id">อนุญาต</span> -->
              <!-- <span v-if="l.consideration_registrar_status == null">รอดำเนินการ</span> -->
              
              <!-- {{ li.name }} -->
              {{l.statusname}}
            </td>
            <!-- <td>{{ l.firstname }} {{ l.lastname }}</td> -->
            <td>
              <a :href="'permissiondocedit/' + l.id">
                <button type="button" class="btn btn-warning">
                  <i class="fa fa-edit"></i></button></a>&nbsp;
              <a :href="l.file" v-if="checkdate(l.updated_date, l.day, l.status_id, l.file)">
                <button type="button" class="btn btn-info">
                  <i class="fa fa-file-pdf"></i></button></a>&nbsp;
              <!-- <a @click="deletedata(l.id)">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#DeleteOffice"
              >
                <i class="fa fa-trash"></i></button
            ></a> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Modal -->
    <!-- Modal -->
    <div class="modal fade" id="AddPer" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>

          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                <div class="form-group mt-3">
                  <label for="password">บริษัท</label>
                  <select class="form-control form-control-sm" v-model="user.id">
                    <option v-for="(i, r) in agency" :key="r" :value="i.id">{{ i.company_name }}</option>
                  </select>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button id="closedper" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="AddOffice" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>

          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                <div class="form-group mt-3">
                  <label for="username">ชื่อหน่วยงาน</label>
                  <input v-model="user.name" type="text" min="1" class="form-control form-control-sm" id="username" />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button id="closeduser" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="DeleteOffice" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบหน่วยงาน</h5>

          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-danger" @click="deletebyid()">
              ลบข้อมูล
            </button>
            <button id="closeddeleteuser" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://api-owb.visdchiangrai.com/picker_date.js"></script>

<script>
import PermissionDocService from "../services/PermissionDocService";
import AgencyService from "../services/AgencyService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      user_id: 0,
      title: "",
      agency:[],
      request_at:''
    };
  },
  mounted() {
    this.getPermission();
    this.getAgency()
  },
  methods: {
    getdateformat(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
})
// console.log(result);
return result
    },
    checkdate(d,day,status,file){
      var value = false
      if (status == 5 && file != null) {
        var date = new Date(d)
date.setDate(date.getDate() + day);
var datenow = new Date()
// console.log(date);
// console.log(datenow);
if (datenow <= date) {
  value = true
}
      }

return value
    },
    getAgency(){
      AgencyService.getAgencies(this.currentUser.id,1).then((res) => {
        this.agency = res.data;
      });
    },
    getid(id) {
      this.user_id = id;
      if (this.user_id != 0) {
        this.title = "แก้ไขข้อมูลหน่วยงาน";
        // console.log(this.user_id);
        OfficeTypeService.getoffice_type(this.user_id).then((res) => {
          // console.log(res.data);
          this.user = res.data;
        });
      } else {
        this.title = "เพิ่มข้อมูลหน่วยงาน";
        this.user = [];
      }
    },
    deletedata(id){
      this.user_id = id;
    },
    deletebyid(){
OfficeTypeService.deleteoffice_type(this.user_id).then(()=>{
  document.getElementById("closeddeleteuser").click();
            this.getUsers();
})
    },
    save() {
      if (this.user.id == "" || this.user.id == null ) {
        alert("กรุณากรอกเลือกชื่อบริษัท");
      } else {
        this.getperlist()
        
      }
    },
    async getperlist(){
      var d = new Date()
      await PermissionDocService.findlast(d.getFullYear()).then((res) => {
        console.log(res.data);
var result = d.toLocaleDateString('th-TH', {
  year: 'numeric',
})
result= result.replace("พ.ศ.",'')
// console.log(this.list.id);
      this.request_at = (parseInt(res.data.length)+1)+'/'+result
      this.request_at = this.request_at.replace(" ",'')
      console.log(this.request_at);
      var trans = {
          company_id: this.user.id,
          // request_at:this.request_at,
          created_by:this.currentUser.id, 
          update_by:this.currentUser.id,
        };
        console.log(trans);
        PermissionDocService.createpermission_doc(trans).then((res) => {
          document.getElementById("closedper").click();
          this.$router.push("/permissiondoc/" + res.data.id);
        });
        
      });
    },
    getPermission() {
      var created_by = ''
      var officer_id = ''
      var station_master_id = ''
      var registrar_id =''
      var admin_id = ''
      if (this.currentUser.role_id == 1) {
       created_by = this.currentUser.id
      }
      if (this.currentUser.role_id == 2) {
        officer_id = this.currentUser.id
      }
      if (this.currentUser.role_id == 3) {
        station_master_id = this.currentUser.id
      }
      if (this.currentUser.role_id == 4) {
        registrar_id = this.currentUser.id
      }
      if (this.currentUser.role_id == 5) {
        admin_id = this.currentUser.id
      }
      // console.log(this.currentUser);
      PermissionDocService.getgroup('','',created_by,officer_id,station_master_id,registrar_id,admin_id).then((res) => {
        this.list = res.data;
        console.log(res.data);
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}

.card {
  margin: 0 auto;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
}

body {
  background-color: gray;
}
</style>
