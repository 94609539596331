<template>
  <div class="container">
    <div class="card mt-5">
              <div class="card-body">
                <div class="form-group mt-3">
                  <label for="username">ชื่อบริษัท <span style="color:red">*</span></label>
                  <input
                    v-model="data.company_name"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                    placeholder="กรุณากรอกชื่อบริษัท"
                  />
                </div>
                <div class="form-group mt-3">
        <label for="inputPassword">ประเภทผู้ประกอบการ <span style="color:red">*</span></label>
          <!-- <input type="text" v-model="data.office" class="form-control" id="inputPassword"> -->
          <select class="form-control form-control-sm" v-model="data.operatortypesId">
            <option v-for="(i, r) in operatortypes" :key="r" :value="i.id">{{ i.name }}</option>
          </select>
      </div>
                <div class="form-group mt-3" style="margin-bottom:0px" v-if="data.operatortypesId == 2">
                  <label for="username">หนังสือมอบอำนาจ</label>
                 
                </div>
                <div class="form-group" v-if="data.operatortypesId == 2">
                  <a :href="data.file" target="_blank">{{ file_name }}</a><br/>
                  <input type="button" class="btn btn-info btn-sm" id="get_file" value="อัพโหลดหนังสือมอบอำนาจ" @click="upload()">
<input type="file" id="my_file" @change="onFileChange" accept="application/pdf,image/png, image/gif, image/jpeg">
                </div>
                
               
            <div class="social-auth-links text-center mt-3">
              <button type="button" class="btn btn-success btn-sm" @click="savetolist()">
              {{ title }}
            </button>
          </div>
              </div>
            </div>
    <table class="table table-bordered mt-5">
      <thead>
        <tr class="table-active">
          <th scope="col">ลำดับที่</th>
          <th scope="col">ชื่อบริษัท</th>
          <th scope="col">ประเภทบริษัท</th>
          <th scope="col">หนังสือมอบอำนาจ</th>
          <th scope="col">สถานะ</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in datalist" :key="i">
          <td>
            {{ i + 1 }}
          </td>
          <td>
            {{ l.company_name }}
          </td>
          <td>
            {{ l.operatorname }}
          </td>
          <td>
            <a :href="l.file" target="_blank">{{ l.file_name }}</a>
          </td>
          <td>
           <span v-if="l.activate == 1">ผ่านการตรวจสอบ</span> 
           <span v-if="l.activate == 0 && l.id == null"></span> 
           <span v-if="l.activate == 0 && l.id != null">รอเจ้าหน้าที่ตรวจสอบ</span> 
          </td>
          <!-- <td>{{ l.firstname }} {{ l.lastname }}</td> -->
          <td>
            <a @click="getid(l.id)">
              <button
                type="button"
                class="btn btn-warning"
              >
                <i class="fa fa-edit"></i></button
            ></a>&nbsp;
            <a @click="getid(l.id)">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#DeleteAgency"
              >
                <i class="fa fa-trash"></i></button
            ></a>
          </td>
        </tr>
      </tbody>
    </table>
    <button type="button" class="btn btn-success btn-sm" @click="save()">
              บันทึก
            </button>
            <div
      class="modal fade"
      id="DeleteAgency"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบบริษัท</h5>
            
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-danger" @click="deletebyid()">
              ลบข้อมูล
            </button>
            <button
            id="closeddeleteagency"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgencyService from "../services/AgencyService";
import axios from 'axios';
import LinkImageService from "../services/LinkImageService";
import UserService from '../services//UserService'
import LogNotifyService from '../services/LogNotifyService'
import emailjs from 'emailjs-com';
import OperatortypesService from '../services/OperatortypesService'

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      id: 0,
      list: [],
      data: {},
      user_id: 0,
      hash: 0,
      roles:[],
      file_name:'',
      datalist:[],
      title:'เพิ่มข้อมูลบริษัท',
      operatortypes:[]
    };
  },
  mounted() {
    // console.log(this.currentUser.role_id);
    if (this.currentUser.role_id >= 2) {
      this.$router.push("/agencymanager");
    }
    this.getUsers();
    this.getroles()
    this.getoperatortype()
  },
  methods: {
    getoperatortype(){
      OperatortypesService.getoperatortypes(1).then((res)=>{
        this.operatortypes = res.data
      })
    },
    upload(){
      document.getElementById('my_file').click();
    },
    onFileChange(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.onUploadFile(files[0]);
      // this.createBase64Image(selectedImage);
    },
    onUploadFile(selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      var link = LinkImageService.getLink()
      var http = link + '/uploadimage?type=attorney'+'&&name=' +selectedFile.name;
      var path = link + '/uploads/attorney/'+selectedFile.name;
      axios
        .post(http, formData)
        .then(() => {    
          this.data.file = path
          this.data.file_name = selectedFile.name
          this.file_name = selectedFile.name
          console.log(this.data);
          // MapDocService.deletemap_doc_permision(this.doc_id,this.car_id).then((res=>{
          // var file ={
          //   permission_doc_id:this.car_id,
          //   document_id:this.doc_id,
          //   name:selectedFile.name,
          //   path:path,
          //   status:1,
          //   created_by:this.currentUser.id
          // }
          // MapDocService.createmap_doc_permision(file).then(()=>{
          //   // console.log(res.data);
           
          //   this.getdocs()
          // })
            
          // }))
        
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getroles(){
      AgencyService.getAgency().then((res)=>{
        this.roles = res.data
      })
    },
    deletebyid(){
      console.log(this.id);
AgencyService.deleteAgency(this.id).then(()=>{
  document.getElementById("closeddeleteagency").click();
            this.getUsers();
            this.data = {}
})
    },
    getid(id) {
      this.id = id;
      console.log(id);
      if (this.id != 0) {
        this.title = "แก้ไขข้อมูลบริษัท";
        // console.log(this.user_id);
        AgencyService.getAgency(this.id).then((res) => {
          console.log(res.data);
          this.data = {}
          this.data = res.data
          this.file_name = ''
          // this.data.company_name = res.data.company_name;
          this.file_name = res.data.file_name
        });
      } else {
        this.title = "เพิ่มข้อมูลบริษัท";
        this.user = [];
      }
    },
    savetolist() {
      if (this.data.company_name == "" || this.data.company_name == null ) {
        alert("กรุณากรอกชื่อบริษัท");
      } else if (this.data.operatortypesId == "" || this.data.operatortypesId == null) {
        alert("กรุณาเลือกประเภท");
      } else if (this.data.operatortypesId ==2 &&(this.data.file == "" || this.data.file == null)) {
        alert("กรุณาเลือกไฟล์หนังสือมอบอำนาจ");
      } else {
        if (this.data.operatortypesId == 1) {
          this.data.operatorname = 'เจ้าของรถ'
        }else{
          this.data.operatorname = 'เอเจนซี่'
        }
        if (this.data.id == null) {
          this.datalist.push({
          user_id: this.currentUser.id,
          company_name: this.data.company_name,
          file_name: this.data.file_name,
          file: this.data.file,
          activate: false,
          status: true,
          operatortypesId:this.data.operatortypesId,
          operatorname:this.data.operatorname
        })
        }else{
          for (let d = 0; d < this.datalist.length; d++) {
            if (this.datalist[d].id == this.data.id) {
              this.datalist[d].company_name = this.data.company_name
              this.datalist[d].file_name = this.data.file_name
              this.datalist[d].file = this.data.file
              this.datalist[d].operatortypesId = this.data.operatortypesId
              this.datalist[d].operatorname = this.data.operatorname
              
            }
            
          }
        }
        this.data = {}
        this.title = "เพิ่มข้อมูลบริษัท"
        this.file_name = ''
      }
    },
    sendEmail(to_email,title) {
    var templateParams = {
        to_email: to_email,
        header: 'ผู้ประกอบการส่งคำขอตรวจสอบชื่อบริษัทและหนังสือมอบอำนาจ',
    message: title+LinkImageService.getLinkFrontend()+'/agencymanager',
  };
        emailjs.send('service_ziurs0r', 'template_7bfnzks',templateParams,
        'v6IRDEPdvfhe_ir4C').then((result) => {
            console.log('SUCCESS!', result.text);
        }, (error) => {
            console.log('FAILED...', error.text);
        }
        )
    },
    save() {
      for (let index = 0; index < this.datalist.length; index++) {
        var userdata = {
          user_id: this.datalist[index].user_id,
          company_name: this.datalist[index].company_name,
          file_name: this.datalist[index].file_name,
          file: this.datalist[index].file,
          activate: this.datalist[index].activate,
          status: this.datalist[index].status,
          operatortypesId: this.datalist[index].operatortypesId,
          operatorname: this.datalist[index].operatorname,
          
        };
        if (this.datalist[index].id == '' || this.datalist[index].id == null) {
          AgencyService.createAgency(userdata).then(() => {
            // console.log(res.data);
            // this.getUsers();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        }else{
          AgencyService.updateAgency(this.datalist[index].id, userdata).then(() => {
            // console.log(res.data);
            
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        }
        if (index+1 == this.datalist.length) {
          UserService.getdatabyrole(2).then(async (res)=>{
              // console.log(res.data);
              for (let o = 0; o < res.data.length; o++) {
                if (res.data[o].line_token != null) {
                  // console.log(res.data[o].line_token);
                  await LinkImageService.sendNotify('ผู้ประกอบการส่งคำขอตรวจสอบชื่อบริษัทและหนังสือมอบอำนาจ ตรวจสอบที่ '+LinkImageService.getLinkFrontend()+'/agencymanager') 
                }
                // console.log(this.datas.email);
                this.sendEmail(res.data[o].email,'ผู้ประกอบการส่งคำขอตรวจสอบชื่อบริษัทและหนังสือมอบอำนาจ ตรวจสอบที่ ')
                var notify ={
          permission_docs_id:0,
          messages:'ผู้ประกอบการส่งคำขอตรวจสอบชื่อบริษัทและหนังสือมอบอำนาจ ตรวจสอบที่ '+LinkImageService.getLinkFrontend()+'/agencymanager',
          created_by:this.currentUser.id
}
await LogNotifyService.createlog_notify(notify).then(()=>{
// console.log(res.data);
})
if (o+1 == res.data.length) {
  alert('บันทึกสำเร็จ')
           this.getUsers();
}
              }
            })
           

            //     setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
        }
      }
     
    },
    getUsers() {
      AgencyService.getAgencies(this.currentUser.id,'').then((res) => {
        this.datalist = res.data;
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
#my_file {
    display: none;
}
</style>
