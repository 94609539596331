<template>
  <div class="container-fluid">
    <div class="container">
     
    <div class="form-group mt-5">
      <label for="exampleFormControlTextarea1">ชื่อหัวข้อ</label>
      <vue-editor              
              v-model="eva.title"
            />
    </div>
    <div class="form-group row mt-3" style="text-align:center">
      <div class="col-sm-12">
        <button @click="save()" class="btn btn-success mt-3 mb-3 btn-sm">
          บันทึก
        </button>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import HeaderService from '../services/HeaderService'

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      eva: {},
    }
  },
  mounted() {
    HeaderService.getheader(1).then((res)=>{
        // console.log(res.data);
      this.eva = res.data
    })
  },
  methods: {
    save() {
      var head = {
        title:this.eva.title
      }
      // console.log(head);
      HeaderService.updateheader(1,head).then(()=>{
        // console.log(res.data);
        alert('บันทึกสำเร็จ')
      })
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04AA6D;
  color: white;
}

.card {
  margin: 0 auto;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
}

body {
  background-color: gray;
}</style>
