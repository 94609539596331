import http from "../http-common";

class PermissionDocService {
    getpermission_docs(name,year,created_by,officer_id,station_master_id,registrar_id) {
        return http.get('/permission_docs?name='+name+'&&year='+year+'&&created_by='+created_by+'&&officer_id='+officer_id+'&&station_master_id='+station_master_id+'&&registrar_id='+registrar_id);
    }
    
    getgroup(name,year,created_by,officer_id,station_master_id,registrar_id,admin_id) {
        return http.get('/permission_docs/getgroup?name='+name+'&&year='+year+'&&created_by='+created_by+'&&officer_id='+officer_id+'&&station_master_id='+station_master_id+'&&registrar_id='+registrar_id+'&&admin_id='+admin_id);
    }
    findlast(name) {
        return http.get('/permission_docs/findlast?name='+name);
    }
    getpermission_doc(id) {
        return http.get('/permission_docs/' + id);
    }
    createpermission_doc(data) {
        return http.post('/permission_docs', data);
    }
    updatecommenttooperator(id, data) {
        return http.put(`/permission_docs/commenttooperator/${id}`, data);
    }
    sendtostationmaster(id, data) {
        return http.put(`/permission_docs/sendtostationmaster/${id}`, data);
    }
    sendtoregistrar(id, data) {
        return http.put(`/permission_docs/sendtoregistrar/${id}`, data);
    }
    senttooperator(id, data) {
        return http.put(`/permission_docs/senttooperator/${id}`, data);
    }
    updatepermission_doc(id, data) {
        return http.put(`/permission_docs/${id}`, data);
    }
    updatedoc(id, data) {
        return http.put(`/permission_docs/updatedoc/${id}`, data);
    }
    deletepermission_doc(id,status) {
        return http.delete('/permission_docs/' + id+'/'+status);
    }
}

export default new PermissionDocService();