<template>
  <div class="container mt-5">
    <div style="padding: 0px;">
      <div class="card mt-3 mb-3" style="padding-left: 1.5cm; background-color: white; padding-right: 0.5cm">
        <div class="row mt-3">
          <div class="col-sm-6"><input value="คำขอที่" readonly class="title" style="width:15%;" /><input
              v-model="datas.request_at" readonly type="text" class="content" name="name" style="width:30%;" /></div>
        </div>
        <div class="row">
          <div class="col-sm-6"><input value="รับวันที่" readonly class="title" style="width:15%;" /><input
              v-model="datas.request_date" readonly type="text" class="content" name="name" style="width:30%;" /></div>
        </div>
        <div class="row">
          <div class="col-sm-6"><input value="ผู้รับ" readonly class="title" style="width:15%;" /><input
              v-model="datas.request_receiver" readonly type="text" class="content" name="name" style="width:30%;" />
          </div>
        </div>
        <div style="text-align: center; background-color: white; margin: 0px;" class="mb-3">
          <label style="text-align: center; font-size: 1rem">คำขออนุญาตให้ใช้รถผิดประเภท</label><br />
          <label style="text-align: center; font-size: 1rem">และใช้รถทำการขนส่งนอกเส้นทางหรือนอกท้องที่</label>
        </div>
        <div class="row">
          <div class="col-sm-9" style="text-align:right"></div>
          <div class="col-sm-3"><input value="เขียนที่" readonly class="title" style="width:20%;" /><input
              v-model="datas.write_at" readonly type="text" class="content" name="name" style="width:80%;" /></div>
        </div>
        <div class="row">
          <div class="col-md-8" style="text-align:right"></div>
          <div class="col-md-4"><input value="วันที่" readonly class="title" style="width:9%;" /><input
              v-model="datas.write_dates" readonly type="text" class="content" name="name" style="width:17%;" />
            <input value="เดือน" readonly class="title" style="width:11%;" /><input v-model="datas.write_month" readonly
              type="text" class="content" name="name" style="width:30%;" />
            <input value="พ.ศ." readonly class="title" style="width:11%;" /><input v-model="datas.write_year" readonly
              type="text" class="content" name="name" style="width:22%;" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input value="เรียน นายทะเบียน" readonly class="title" style="width:100%;" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" style="margin-left:60px;"><input value="ด้วยข้าพเจ้า (นาย, นาง, นางสาว)" readonly
              class="title" style="width:20%;" /><input v-model="datas.name" readonly type="text" class="content"
              name="name" style="width:75%;" /></div>

          <div class="col-md-5"><input value="ซึ่งมีอำนาจลงนามผูกพัน" readonly class="title" style="width:38%;" /> <input
              v-model="datas.relate" readonly type="text" class="content" name="name" style="width:61%;" /></div>
          <div class="col-md-4"><input value="สำนักงานตั้งอยู่ที่" readonly class="title" style="width:33%;height:90%;" />
            <input v-model="datas.address" readonly type="text" class="content" name="name" style="width:67%;" />
          </div>
          <div class="col-md-3"><input value="หมู่ที่" readonly class="title" style="width:13%;" /> <input
              v-model="datas.moo" readonly type="text" class="content" name="name" style="width:83%;" /></div>

          <div class="col-md-3"><input value="ซอย" readonly class="title" style="width:14%;" /> <input v-model="datas.soi"
              readonly type="text" class="content" name="name" style="width:84%;" /></div>
          <div class="col-md-3"><input value="ถนน" readonly class="title" style="width:14%;height:90%;" /> <input
              v-model="datas.road" readonly type="text" class="content" name="name" style="width:84%;" /></div>
          <div class="col-md-3"><input value="ตำบล" readonly class="title" style="width:16%;" /> <input
              v-model="datas.tambon_th" readonly type="text" class="content" name="name" style="width:82%;" /></div>
          <div class="col-md-3"><input value="เขต/อำเภอ" readonly class="title" style="width:30%;" /> <input
              v-model="datas.amphur_th" readonly type="text" class="content" name="name" style="width:66%;" /></div>
          <div class="col-md-3"><input value="จังหวัด" readonly class="title" style="width:20%;" /> <input
              v-model="datas.province_th" readonly type="text" class="content" name="name"
              style="width:77%;height:80%;" />
          </div>
          <div class="col-md-3"><input value="โทรศัพท์" readonly class="title" style="width:23%;" /> <input
              v-model="datas.phone" readonly type="text" class="content" name="name" style="width:75%;height:80%;" />
          </div>
          <div class="col-md-6"><input value="ได้รับใบอนุญาตประกอบการขนส่งประเภท" readonly class="title"
              style="width:53%;" />
            <input v-model="datas.type_name" readonly type="text" class="content" name="name"
              style="width:46%;height:80%;" />
          </div>
          <div class="col-md-2"><input value="เลขที่" readonly class="title" style="width:23%;" />
            <input v-model="datas.number" readonly type="text" class="content" name="name"
              style="width:77%;height:80%;" />
          </div>
          <div class="col-md-10"><input value="มีความประสงค์ที่จะขอใช้รถดังต่อไปนี้" readonly class="title"
              style="width:30%;" />
          </div>
          <div class="col-md-12" style="margin-left:60px;"><input value="๑. เลขทะเบียน" readonly class="title"
              style="width:10%;" />
            <input v-model="datas.license_no1" readonly type="text" class="content" name="name"
              style="width:30%;height:80%;" />
          </div>
          <div class="col-md-12" style="margin-left:60px;"><input value="๒. เลขทะเบียน" readonly class="title"
              style="width:10%;" />
            <input v-model="datas.license_no2" readonly type="text" class="content" name="name"
              style="width:30%;height:80%;" />
          </div>
          <div class="col-md-12" style="margin-left:60px;"><input value="๓. เลขทะเบียน" readonly class="title"
              style="width:10%;" />
            <input v-model="datas.license_no3" readonly type="text" class="content" name="name"
              style="width:30%;height:80%;" />
          </div>
          <div class="col-md-12" style="margin-left:60px;"><input
              value="(ในกรณีที่เป็นรถประจำทางหรือรถขนาดเล็กให้ระบุหมายเลขเส้นทางและหมายเลขประจำรถด้วย)" readonly
              class="title" style="width:100%;" />
          </div>
          <div class="col-md-12"><input value="ทำการขนส่ง" readonly class="title" style="width:12%;" />
            <input class="form-check-input" readonly type="checkbox" style="width:2%;" value="wrongtype" id="wrongtype"
              v-model="datas.wrongtype" disabled>
            <input value="ผิดไปจากประเภทที่จดทะเบียนไว้" readonly class="title" style="width:25%;" />
            <input class="form-check-input" readonly type="checkbox" style="width:2%;" value="offtrack" id="offtrack"
              v-model="datas.offtrack" disabled>
            <input value="นอกเส้นทาง" readonly class="title" style="width:13%;" />
            <input class="form-check-input" readonly type="checkbox" style="width:2%;" value="outside" id="outside"
              v-model="datas.outside" disabled>
            <input value="นอกท้องที่" readonly class="title" style="width:10%;" />
            <input value="เพื่อใช้ในกิจการ" readonly class="title" style="width:10%;" />
            <input v-model="datas.business" readonly type="text" class="content" name="name"
              style="width:29%;height:80%;" />

          </div>
          <div class="col-md-3">
            <input value="ของ" readonly class="title" style="width:13%;" />
            <input v-model="datas.of" readonly type="text" class="content" name="name" style="width:87%;height:80%;" />
          </div>
          <div class="col-md-3">
            <input value="ตั้งแต่วันที่" readonly class="title" style="width:27%;" />
            <input v-model="datas.from_dates" readonly type="text" class="content" name="name"
              style="width:73%;height:80%;" />
          </div>
          <div class="col-md-3">
            <input value="เดือน" readonly class="title" style="width:16%;" />
            <input v-model="datas.from_month" readonly type="text" class="content" name="name"
              style="width:84%;height:80%;" />
          </div>
          <div class="col-md-3">
            <input value="พ.ศ." readonly class="title" style="width:13%;" />
            <input v-model="datas.to_year" readonly type="text" class="content" name="name"
              style="width:83%;height:80%;" />
          </div>
          <div class="col-md-3">
            <input value="เวลา" readonly class="title" style="width:15%;" />
            <input v-model="datas.from_time" readonly type="text" class="content" name="name"
              style="width:85%;height:80%;" />
          </div>
          <div class="col-md-3">
            <input value="ถึงวันที่" readonly class="title" style="width:18%;" />
            <input v-model="datas.to_dates" readonly type="text" class="content" name="name"
              style="width:82%;height:80%;" />
          </div>
          <div class="col-md-3">
            <input value="เดือน" readonly class="title" style="width:17%;" />
            <input v-model="datas.to_month" readonly type="text" class="content" name="name"
              style="width:83%;height:80%;" />
          </div>
          <div class="col-md-3">
            <input value="พ.ศ." readonly class="title" style="width:13%;" />
            <input v-model="datas.to_year" readonly type="text" class="content" name="name"
              style="width:83%;height:80%;" />
          </div>
          <div class="col-md-2">
            <input value="เวลา" readonly class="title" style="width:23%;" />
            <input v-model="datas.to_time" readonly type="text" class="content" name="name"
              style="width:77%;height:80%;" />
          </div>
          <div class="col-md-5">
            <input value="โดยมีต้นทางที่" readonly class="title" style="width:22%;" />
            <input v-model="datas.origin" readonly type="text" class="content" name="name"
              style="width:78%;height:80%;" />
          </div>
          <div class="col-md-5">
            <input value="ผ่านจังหวัด" readonly class="title" style="width:18%;" />
            <input v-model="datas.stop" readonly type="text" class="content" name="name" style="width:80%;height:80%;" />
          </div>
          <div class="col-md-4">
            <input value="สิ้นสุดปลายทางที่" readonly class="title" style="width:34%;" />
            <input v-model="datas.destination" readonly type="text" class="content" name="name"
              style="width:66%;height:80%;" />
          </div>
          <div class="col-md-8">
            <input value="และกลับจากปลายทางโดยผ่านจังหวัด" readonly class="title" style="width:35%;" />
            <input v-model="datas.stop_through" readonly type="text" class="content" name="name"
              style="width:64%;height:80%;" />
          </div>
          <div class="col-md-12" style="margin-left:60px;"><input
              value="ข้าพเจ้าขอรับรองว่าเมื่อนำรถโดยสารประจำทางหรือรถขนาดเล็กจำนวนดังกล่าวข้างต้นไปใช้ผิดประเภทหรือใช้ทำการขนส่งนอกเส้นทางแล้ว จะมีรถเหลืออยู่"
              readonly class="title" style="width:100%;" />
          </div>
          <div class="col-md-12 mb-3"><input
              value="ไม่น้อยกว่าจำนวนรถขั้นต่ำตามเงื่อนไขที่กำหนดไว้ในใบอนุญาตประกอบการขนส่ง" readonly class="title"
              style="width:100%;" />
          </div>
        </div>
      </div>   <div class="row mb-5">
        <div class="col-md-12 text-center">
          &nbsp;
        </div>
      </div>
      <div class="row">
        <div class="container">   
    <div
      class="card mb-5"
      style="padding-left: 1.5cm; background-color: white; padding-right: 0.5cm"
    >    
    <div>
          <div class="form-group col-md-12 mt-3">
        <label for="exampleFormControlInput1">เอกสาร</label>
         <table class="table mt-3">
          <thead>
            <tr>
              <th scope="col">เอกสาร</th>
              <th scope="col">ไฟล์</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody v-if="docs.length > 0">
            <tr v-for="(dd, i) in docs" :key="i + 1">
              <td><a target="_blank">{{ dd.name }}</a></td>
              <td>
                <a :href="dd.path" target="_blank">{{ dd.name_file }}</a></td>
              
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <section></section>
    <div>
    </div>
    </div>
    <!-- <div id="app" ref="document" v-else>
      <PermissionDocEdit />
	</div> -->
  </div>
      </div>
      <div class="row">
        <div class="container">
        <div
      class="card mb-5"
    >             <div class="card-body login-card-body">
            <form >
              <div class="card-body">
               
                <div class="form-group mb-3">
                  <label for="username">ข้อความจากเจ้าหน้าที่</label>
                  <textarea disabled name="message" v-model="datas.office_to_operator" type="text" rows="3"
                  class="form-control form-control-sm"></textarea>
                </div>
              
              </div>
            </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="container">
    <div
      class="card mb-5"
    >             <div class="card-body login-card-body">
            <form ref="form" @submit.prevent="sendEmail">
              
              <div class="card-body">
                <!-- <div class="col-md-12"> -->
                  <label for="username">เจ้าหน้าที่ส่งเรื่องให้หัวหน้ากลุ่ม</label>&nbsp;&nbsp;&nbsp;
            <div class="form-group mt-3">
              <label for="username">หัวหน้ากลุ่ม</label>&nbsp;&nbsp;&nbsp;
              <select disabled style="width:25%" v-model="datas.station_master_id">
                <option v-for="(a, i) in station_masters" :key="i" :value="a.id"> {{ a.firstname }} {{ a.lastname }}</option>
              </select>
            </div>
                <div class="form-group mb-3">
                  <label for="username">ข้อความจากเจ้าหน้าที่ถึงหัวหน้ากลุ่ม</label>
                  <textarea disabled name="message" v-model="datas.officer_comment" type="text" rows="3"
                  class="form-control form-control-sm"></textarea>
                </div>
                
              </div>
            </form>
          </div>
        </div>
    </div>
      </div>
      <div class="row">
        <div class="container">
    <div
      class="card mb-5"
    >             <div class="card-body login-card-body">
            <form ref="form" @submit.prevent="sendEmail">
              <div class="card-body">
                <label>ผลการพิจารณาจากหัวหน้ากลุ่ม</label>

                <div class="form-check">
              <input disabled class="form-check-input" value="1" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                v-model="datas.consideration_station_master_status">
              <label class="form-check-label" for="flexRadioDefault1">
                อนุญาต
              </label>
            </div>
            <div class="form-check">
              <input disabled class="form-check-input" value="0" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                v-model="datas.consideration_station_master_status">
              <label class="form-check-label" for="flexRadioDefault2">
                ไม่อนุญาต
              </label>
            </div>
            <div class="form-group mt-3">
              <label for="username">นายทะเบียน</label>&nbsp;&nbsp;&nbsp;
              <select disabled style="width:25%" v-model="datas.registrar_id">
                <option v-for="(a, i) in registrars" :key="i" :value="a.id"> {{ a.firstname }} {{ a.lastname }}</option>
              </select>
            </div>
                <div class="form-group mb-3 mt-3">
                  <label for="username">ข้อความจากหัวหน้ากลุ่มถึงนายทะเบียน</label>
                  <textarea disabled name="message" v-model="datas.station_master_comment" type="text" rows="3"
                  class="form-control form-control-sm"></textarea>
                </div>
                
              </div>
            </form>
          </div>
        </div>
    </div>
      </div>
      <div class="row">
        <div class="container">
    <div
      class="card mb-5"
    >             <div class="card-body login-card-body">
            <form ref="form" @submit.prevent="sendEmail">
              <div class="card-body">
                <label>ผลการพิจารณาของนายทะเบียน</label>
                <div class="form-check">
              <input disabled class="form-check-input" value="1" type="radio" name="consideration_registrar_status" id="consideration_registrar_status1"
                v-model="datas.consideration_registrar_status">
              <label class="form-check-label" for="consideration_registrar_status1">
                อนุญาต
              </label>
            </div>
            <div class="form-check">
              <input disabled class="form-check-input" value="0" type="radio" name="consideration_registrar_status" id="consideration_registrar_status2"
                v-model="datas.consideration_registrar_status">
              <label class="form-check-label" for="consideration_registrar_status2">
                ไม่อนุญาต
              </label>
            </div>
            <div class="form-group mb-3 mt-3">
                  <label for="username">ข้อความจากนายทะเบียน</label>
                  <textarea disabled name="message" v-model="datas.registrar_comment" type="text" rows="3"
                  class="form-control form-control-sm"></textarea>
                </div>
                 
              </div>
            </form>
          </div>
        </div>
    </div>
      
      </div>

    </div>
  </div>
</template>

<script src="https://api-owb.visdchiangrai.com/picker_date.js"></script>

<script>
import emailjs from 'emailjs-com'; 
import PermissionDocService from '../services/PermissionDocService.js'
// import ImportDocs from './ImportDocs'
// import SendtoOperator from './SendtoOperator.vue'
// import Sendtostationmaster from './Sendtostationmaster.vue'
// import SendRegistrar from './SendRegistrar.vue'
// import Sentoperatorfromstation from './Sentoperatorfromstation.vue'
import axios from 'axios';
import LinkImageService from '../services/LinkImageService'
import UserService from '../services/UserService'
import DocumentService from "../services/DocumentService";
import MapDocService from "../services/MapDocService";

export default {
  name: "Cars",
  props: {
    msg: String,
  },
  components: {
    // ImportDocs,
    // SendtoOperator,
    // Sendtostationmaster,
    // SendRegistrar,
    // Sentoperatorfromstation
  },
  data() {
    return {
      brands: [],
      characteristics: [],
      symbols: [],
      car_types: [],
      datas: [],
      car_id: 0,
      image: "",
      itemPreviewImage: "",
      symbol: [],
      statusedit: false,
      docs:[],
      station_masters:[],
      url:'',
      registrars:[]
    };
  },
  methods: {
    getdocs(){
      DocumentService.getdocuments().then(async (res)=>{
        // console.log(res.data);
        this.docstemp = res.data
        await MapDocService.getmap_doc_permision(this.car_id).then((docs)=>{
        // console.log(docs.data);
        var doclist = docs.data
        if (doclist.length > 0) {
         for (let d = 0; d < this.docstemp.length; d++) {
          for (let dd = 0; dd < doclist.length; dd++) {
            // console.log(this.docstemp[d].id, doclist[dd].id);
            if (this.docstemp[d].id == doclist[dd].id) {
              this.docstemp[d].path = doclist[dd].path
              this.docstemp[d].name_file = doclist[dd].name_file
            }
          }
         }
        }

        this.docs = this.docstemp
      });
      })
// MapDocService.getmap_doc_permision(this.car_id).then((res)=>{
//         console.log(res.data);
//         if (res.data.length == 0) {
         
//         }else{
//           this.docs = res.data
//         }
//       });
    },
    sendEmail() {
        emailjs.sendForm('service_ziurs0r', 'template_7bfnzks',this.$refs.form,
        'v6IRDEPdvfhe_ir4C').then((result) => {
            console.log('SUCCESS!', result.text);
        }, (error) => {
            console.log('FAILED...', error.text);
        }
        )
    },
    sendemail(){
      this.sendEmail()
    },
    adddoc(){
      var commenttooperator = {
        officer_id:this.currentUser.id,
        office_to_operator:this.datas.office_to_operator,
        status_id:2,
        update_by:this.currentUser.id
      }
      PermissionDocService.updatecommenttooperator(this.car_id,commenttooperator).then((res)=>{
        alert('แจ้งอีเมลไปยังผู้ประกอบการเพื่ออัพโหลดเอกสารเพิ่มเติมเรียบร้อยแล้ว')
      })
      
    },
    sendtostationmaster(){
      var commenttooperator = {
        station_master_id:3,
        officer_comment:this.datas.officer_comment,
        status_id:3,
        update_by:this.currentUser.id
      }
      PermissionDocService.sendtostationmaster(this.car_id,commenttooperator).then((res)=>{
        alert('แจ้งอีเมลไปยังหัวหน้ากลุ่มเรียบร้อยแล้ว')
      })
    },
    sendtoregistrar(){
      var commenttoregistrar = {
        registrar_id:4,
        consideration_station_master_status:this.datas.consideration_station_master_status,
        station_master_comment:this.datas.station_master_comment,
        status_id:4,
        update_by:this.currentUser.id
      }
      PermissionDocService.sendtoregistrar(this.car_id,commenttoregistrar).then((res)=>{
        
        if (this.datas.consideration_station_master_status == true) {
          alert('แจ้งอีเมลไปยังนายทะเบียนเรียบร้อยแล้ว')
        }else{
          alert('บันทึกสำเร็จ')
        }
      })
    },
    senttooperator(){
      
      var senttooperator = {
        consideration_registrar_status:this.datas.consideration_registrar_status,
        status_id:5,
        update_by:this.currentUser.id
      }
      PermissionDocService.senttooperator(this.car_id,senttooperator).then((res)=>{
        
        if (this.datas.consideration_registrar_status == true) {
          alert('แจ้งอัเมลไปยังเจ้าหน้าที่เรียบร้อยแล้ว')
        }else{
          alert('บันทึกสำเร็จ')
        }
      })
    },
    importdoc(){
      this.$router.push("/importdocs/" + this.car_id);
    },
    exportToPDF () {
      this.$router.push("/PrintPermissionDoc/" + this.car_id);
      // this.downloadstatus = true
			// 	html2pdf(this.$refs.document, {
			// 		margin: [1,1.5,0,1.2],
			// 		filename: 'คำขออนุญาตให้ใช้รถผิดประเภท.pdf',
			// 		jsPDF: { unit: 'cm', format: 'a4', orientation: 'p' },
      //     pagebreak: {after: 'section'}
			// 	})
			},
    edit(){
      this.$router.push('/permissiondoc/' + this.car_id);
    },
    getdata(){
      PermissionDocService.getpermission_doc(this.car_id).then((res)=>{
        // console.log(res.data);
        this.datas = res.data;
        this.datas.request_date = this.getdateformat(this.datas.request_date)
        this.datas.write_dates = this.getdatesplit(this.datas.write_date)
        this.datas.write_month = this.getmonthsplit(this.datas.write_date)
        this.datas.write_year = this.getyearsplit(this.datas.write_date)
        this.datas.write_year = this.datas.write_year.replace("พ.ศ.",'')
        this.datas.from_dates = this.getdatesplit(this.datas.from_date)
        this.datas.from_month = this.getmonthsplit(this.datas.from_date)
        this.datas.from_year = this.getyearsplit(this.datas.from_date)
        this.datas.from_year = this.datas.from_year.replace("พ.ศ.",'')
        this.datas.from_time = this.converttime(this.datas.from_time)
        this.datas.to_dates = this.getdatesplit(this.datas.to_date)
        this.datas.to_month = this.getmonthsplit(this.datas.to_date)
        this.datas.to_year = this.getyearsplit(this.datas.to_date)
        this.datas.to_year = this.datas.to_year.replace("พ.ศ.",'')
        this.datas.to_time = this.converttime(this.datas.to_time)
        var name = this.datas.request_at.replace('/','-')
    this.url = LinkImageService.getLink()+'/uploads/permissiondocs/หนังสืออนุญาต'+name+'.pdf'
    this.datas.from_name="หนังสืออนุญาต"
        // console.log(this.datas);
      })
    },
    converttime(time){
      var t = time.split(":")
      t = t[0]+'.'+t[1] +' น.'
      return t
    },
    getdateformat(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
})
// console.log(result);
return result
    },
    getdatesplit(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  day: 'numeric',
})
// console.log(result);
return result
    },
    getmonthsplit(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  month: 'short',
})
// console.log(result);
return result
    },
    getyearsplit(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  year: 'numeric',
})
// console.log(result);
return result
    },
     test() {
      axios({
    method: 'post',
    url: 'https://notify-api.line.me/api/notify',
    headers: {
      'Authorization': 'Bearer ' + 'nRKWjr5P0EZS4e4qU0rGpYo27QQOpOYexwkA9BFglyW',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*'
    },
    data: JSON.stringify({
      message: 'something you would like to push',
    })
  })
.then( function(res) {
  console.log(res.data);
})
.catch( function(err) {
  console.error(err);
});

}
  },
   mounted() {
    UserService.getdatabyrole(3).then((res)=>{
      // console.log(res.data);
      this.station_masters = res.data
    })
    UserService.getdatabyrole(4).then((res)=>{
      // console.log(res.data);
      this.registrars = res.data
    })
    this.car_id = this.$route.params.id;
    // console.log(this.car_id);
    if (this.car_id != 0) {
      this.getdocs()
      this.getdata()
    } else {
      this.statusedit = true;
      this.datas.status = 1;
    }


  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  font-size: 14px;
  font-weight: bold;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

span {
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
  text-align: justify;
}

body {
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  font-size: 14px;
  color: black;
}

div.aa {
  text-align: justify;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

p {
  text-indent: 25px;
  text-align: justify;
  margin-bottom: 0px;
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

div {
  font-family: 'Niramit';
  font-size: 14px;
}

.title {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.content {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom-style: dotted;
  border-bottom: 1.6px dotted;
}

input {
  margin-bottom: 5px;
}
</style>
