import http from "../http-common";

class EvaluationService {
    getevas() {
        return http.get('/evaluation');
    }
    geteva(id) {
        return http.get('/evaluation/' + id);
    }
    createeva(data) {
        return http.post('/evaluation', data);
    }
    updateeva(id, data) {
        return http.put(`/evaluation/${id}`, data);
    }
    getevainv() {
        return http.get('/evaluation/getevainv');
    }
    getevaioff() {
        return http.get('/evaluation/getevaoff');
    }
    deleteeva(id) {
        return http.delete('/evaluation/' + id);
    }
    getevainvreport(startdate,enddate) {
        return http.get('/evaluation/getevainvreport?startdate='+startdate+"&&enddate="+enddate);
    }
    getevaoffreport(startdate,enddate) {
        return http.get('/evaluation/getevaoffreport?startdate='+startdate+"&&enddate="+enddate);
    }
}

export default new EvaluationService();