<template>
  <div class="container">
    <h5 class="mt-3">รายการ{{ main.name }} &nbsp;{{ item.item }}</h5>
    <div class="col mb-3 mt-3" style="text-align: right">
      <a>
        <button
          type="button"
          id="get_file"
          class="btn btn-success"
          @click="getid(0)"
          data-bs-toggle="modal"
          data-bs-target="#AddMainitem"
        >
          <i class="fa fa-plus"></i> เพิ่มรายการ
        </button></a
      >
    </div>
    <table class="table table-bordered">
      <thead>
        <tr class="table-active">
          <th scope="col">ลำดับที่</th>
          <th scope="col">วิธีการ</th>
          <th scope="col">หลักเกณฑ์</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>
            {{ i + 1 }}
          </td>
          <td>
            {{ l.method }}
          </td>
          <td>
            {{ l.guidelines }}
          </td>
          <!-- <td>{{ l.firstname }} {{ l.lastname }}</td> -->
          <td>
            <a>
              <button
                type="button"
                @click="getid(l.id)"
                class="btn btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#AddMainitem"
              >
                <i class="fa fa-edit"></i></button
            ></a>&nbsp;
            <!-- <a @click="deletedata(l.id)">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#DeleteOffice"
              >
                <i class="fa fa-trash"></i></button
            ></a> -->
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal -->
    <div
      class="modal fade"
      id="AddMainitem"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            
          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                <div class="form-group mt-3">
                  <label for="username">วิธีการ</label>
                  <textarea
                    v-model="user.method"
                    type="text"
                    row="5"
                    class="form-control form-control-sm"
                    id="username"
                 ></textarea>
                </div>
                <div class="form-group mt-3">
                  <label for="username">หลักเกณฑ์</label>
                  <textarea
                    v-model="user.guidelines"
                    type="text"
                    row="5"
                    class="form-control form-control-sm"
                    id="username"
                  ></textarea>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button
            id="closedmainlist"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="DeleteOffice"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบหน่วยงาน</h5>
            
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-danger" @click="deletebyid()">
              ลบข้อมูล
            </button>
            <button
            id="closeddeleteuser"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaintenanceService from "../services/MaintenanceService";
import MaintenanceItemsService from "../services/MaintenanceItemsService";
import MaintenancelistService from "../services/MaintenancelistService";

import OfficeTypeService from "../services/OfficeTypeService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      master:{},
      user_id: 0,
      title: "",
      roles:[],
      id:0,
      item:{},
      main:{}
    };
  },
  mounted() {
    this.id = this.$route.params.id
    console.log(this.id)
    this.getMasters();
    MaintenanceItemsService.getmaint_items(this.id).then((res) => {
        this.item = res.data;
        console.log(this.item);
        MaintenanceService.getmaintenance(this.item.maintence_id).then((res) => {
        this.main = res.data;
      });
      });

  },
  methods: {
    getid(id) {
      this.user_id = id;
      if (this.user_id != 0) {
        this.title = "แก้ไขข้อมูลรายการ";
        // console.log(this.user_id);
        MaintenancelistService.getmaint_list(this.user_id).then((res) => {
          console.log(res.data);
          this.user = res.data;
        });
      } else {
        this.title = "เพิ่มข้อมูลรายการ";
        this.user = [];
      }
    },
    deletedata(id){
      this.user_id = id;
    },
    deletebyid(){
OfficeTypeService.deleteoffice_type(this.user_id).then(()=>{
  document.getElementById("closeddeleteuser").click();
            this.getUsers();
})
    },
    save() {
      if (this.user.method == "" || this.user.method == null ) {
        alert("กรุณากรอกวิธีการ");
      }else if (this.user.guidelines == "" || this.user.guidelines == null ) {
        alert("กรุณากรอกหลักเกณฑ์");
      } else {
        var userdata = {
          method: this.user.method,
          guidelines:this.user.guidelines,
          maint_item_id:parseInt(this.item.id),
          status:true,
          created_by:this.currentUser.id
        };
        console.log(userdata);
        if (this.user_id == 0) {
          MaintenancelistService.createmaint_list(userdata).then(() => {
            document.getElementById("closedmainlist").click();
            this.getMasters();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        } else {
          MaintenancelistService.updatemaint_list(this.user_id, userdata).then(() => {
            // console.log(res.data);
            document.getElementById("closedmainlist").click();
            this.getMasters();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        }
      }
    },
    getMasters() {
      MaintenancelistService.getmaint_lists(this.id).then((res) => {
        this.list = res.data;
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
