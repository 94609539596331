import http from "../http-common";

class PositionMachineService {
    getposition_machines(name) {
        return http.get('/position_machine?name='+name);
    }
    getposition_machine(id) {
        return http.get('/position_machine/' + id);
    }
    createposition_machine(data) {
        return http.post('/position_machine', data);
    }
    updateposition_machine(id, data) {
        return http.put(`/position_machine/${id}`, data);
    }
    deleteposition_machine(id,status) {
        return http.delete('/position_machine/' + id+'/'+status);
    }
}

export default new PositionMachineService();