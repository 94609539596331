<template>
  <div class="container">
    <h5 class="mt-3">ข้อมูล{{name }}</h5>
    <div class="col mb-3 mt-3" style="text-align: right">
      <a>
        <button
          type="button"
          id="get_file"
          class="btn btn-success"
          @click="getid(0)"
          data-bs-toggle="modal"
          data-bs-target="#AddOffice"
        >
          <i class="fa fa-plus"></i> เพิ่ม{{ name }}
        </button></a
      >
    </div>
    <table class="table table-bordered">
      <thead>
        <tr class="table-active">
          <th scope="col">ลำดับที่</th>
          <th scope="col">ประเภทรถ</th>
          <th scope="col">สถานะ</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>
            {{ i + 1 }}
          </td>
          <td>
            {{ l.name }}
          </td>
          <td>
            <label v-if="l.status == 1">ใช้งานอยู่</label>
            <label v-else>ไม่ใช้งาน</label>
          </td>
          <!-- <td>{{ l.firstname }} {{ l.lastname }}</td> -->
          <td>
            <a @click="getid(l.id)">
              <button
                type="button"
                class="btn btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#AddOffice"
              >
                <i class="fa fa-edit"></i></button
            ></a>&nbsp;
            <a :href="'/maintenanceitems/'+l.id" v-if="master_id =='maintenance'">
              <button
                type="button"
                class="btn btn-info"
              >
                <i class="fa fa-eye"></i></button
            ></a>
            &nbsp;
            <a @click="deletedata(l.id,0)" v-if="l.status == 1">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#DeleteStatus"
              >
                <i class="fa fa-trash"></i></button
            ></a>
            <a @click="deletedata(l.id,1)" v-else>
              <button
                type="button"
                class="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#DeleteStatus"
              >
                <i class="fa fa-repeat"></i></button
            ></a>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal -->
    <div
      class="modal fade"
      id="AddOffice"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            
          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                
                <div class="form-group mt-3">
                  <label for="username">{{ name }}</label>
                  <input
                    v-model="data.name"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button
            id="closeduser"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="DeleteStatus"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel" v-if="typestatus == 0">ยืนยันการลบ{{ name }}</h5>
            <h5 class="modal-title" id="exampleModalLabel" v-else>ยืนยันการกู้คืน{{ name }}</h5>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-danger btn-sm" @click="deletebyid()">
              <label v-if="typestatus == 0">ลบ{{ name }}</label>
              <label v-else>กู้คืน{{ name }}</label>
            </button>
            <button
            id="closeddeleteuser"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TypeLicenseService from "../services/TypeLicenseService";
import MasterService from "../services/MasterService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      data: {},
      data_id: 0,
      title: "",
      name:'',
      master_id:0,
      types:[],
      typestatus:1,
      months :[
  { name: "มกราคม", month: 1 },
  { name: "กุมภาพันธ์", month: 2 },
  { name: "มีนาคม", month: 3 },
  { name: "เมษายน", month: 4 },
  { name: "พฤษภาคม", month: 5 },
  { name: "มิถุนายน", month: 6 },
  { name: "กรกฎาคม", month: 7 },
  { name: "สิงหาคม", month: 8 },
  { name: "กันยายน", month: 9 },
  { name: "ตุลาคม", month: 10 },
  { name: "พฤศจิกายน", month: 11 },
  { name: "ธันวาคม", month: 12 },
]
    };
  },
  mounted() {
    console.log(this.$route.params);
    MasterService.getmaster(this.$route.params.id).then((res)=>{
      // console.log(res.data);
      this.name = res.data.name
    })
    this.master_id = this.$route.params.id;
    this.getdata()
    // console.log(this.currentUser);
  
  },
  methods: {
    getround(m){
const result = this.months.find(({ month }) => month === m);

// console.log(result); // { name: 'cherries', quantity: 5 }
      if (m.toString().length == 1) {
      m = "0" + m;
    }
      const d = new Date("2022-"+m+"-01");
      var lastDayOfMonth = new Date(d.getFullYear(), d.getMonth()+1, 0);
      var day = lastDayOfMonth.getDate()
      // console.log(day);
     var r = day +' '+result.name 
return r;
    },
    getdata(){
      TypeLicenseService.gettype_licenses('').then((res)=>{
        // console.log(res.data);
        this.list = res.data;
      })
    },
    getid(id) {
      this.data_id = id;
      if (this.data_id != 0) {
        this.title = "แก้ไขข้อมูล"+this.name;
        // console.log(this.data_id);
        TypeLicenseService.gettype_license(this.data_id).then((res)=>{
        // console.log(res.data);
        this.data = res.data;
      })
      } else {
        this.title = "เพิ่มข้อมูล"+this.name;
        this.data = {};
      }
    },
    deletedata(id,typestatus){
      this.data_id = id;
      this.typestatus = typestatus
    },
    deletebyid(){
      var data = {
        status:this.typestatus
      }
        TypeLicenseService.deletetype_license(this.data_id,data).then((res)=>{
        // console.log(res.data);
        this.list = res.data; document.getElementById("closeddeleteuser").click();
            this.getdata();
      })
   
    },
    save() {
        if (this.data.name == "" || this.data.name == null ) {
        alert("กรุณากรอกชื่อ"+this.name);
      } else {
        var datatype = {
          name: this.data.name,
          status: true,
          created_by:this.currentUser.id
        };
        if (this.data_id == 0) {
          TypeLicenseService.createtype_license(datatype).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
    }else{
      console.log(this.data_id);
      TypeLicenseService.updatetype_license(this.data_id,datatype).then(()=>{
        document.getElementById("closeduser").click();
        this.getdata()
        
      })
    }
  }
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
