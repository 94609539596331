import http from "../http-common";

class MasterService {
    getmasters() {
        return http.get('/master');
    }
    getmaster(id) {
        return http.get('/master/' + id);
    }
    createmaster(data) {
        return http.post('/master', data);
    }
    updatemaster(id, data) {
        return http.put(`/master/${id}`, data);
    }
    deletemaster(id) {
        return http.delete('/master/' + id);
    }
}

export default new MasterService();