import http from "../http-common";

class BrandsService {
    getbrands(name) {
        return http.get('/brands?name='+name);
    }
    getbrand(id) {
        return http.get('/brands/' + id);
    }
    createbrand(data) {
        return http.post('/brands', data);
    }
    updatebrand(id, data) {
        return http.put(`/brands/${id}`, data);
    }
    deletebrand(id,status) {
        return http.delete('/brands/' + id+'/'+status);
    }
}

export default new BrandsService();