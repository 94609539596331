import http from "../http-common";

class OwnershipService {
    getownerships() {
        return http.get('/ownerships');
    }
    getownership(id) {
        return http.get('/ownerships/' + id);
    }
    createownership(data) {
        return http.post('/ownerships', data);
    }
    updateownership(id, data) {
        return http.put(`/ownerships/${id}`, data);
    }
    deleteownership(id) {
        return http.delete('/ownerships/' + id);
    }
}

export default new OwnershipService();