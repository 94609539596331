import http from "../http-common";

class TaxCycleService {
    getTaxCycles(name) {
        return http.get('/TaxCycle?name='+name);
    }
    getTaxCycle(id) {
        return http.get('/TaxCycle/' + id);
    }
    createTaxCycle(data) {
        return http.post('/TaxCycle', data);
    }
    updateTaxCycle(id, data) {
        return http.put(`/TaxCycle/${id}`, data);
    }
    deleteTaxCycle(id,status) {
        return http.delete('/TaxCycle/' + id+'/'+status);
    }
}

export default new TaxCycleService();