import http from "../http-common";

class AddressService {
    getaddresss() {
        return http.get('/address');
    }
    getaddress(id) {
        return http.get('/address/' + id);
    }
    createaddress(data) {
        return http.post('/address', data);
    }
    updateaddress(id, data) {
        return http.put(`/address/${id}`, data);
    }
    deleteaddress(id,status) {
        return http.delete('/address/' + id+'/'+status);
    }
}

export default new AddressService();