import http from "../http-common";

class MaintenanceService {
    getmaintenances() {
        return http.get('/maintenance');
    }
    getmaintenanceslist() {
        return http.get('/maintenance/getlist');
    }
    getmaintenance(id) {
        return http.get('/maintenance/' + id);
    }
    createmaintenance(data) {
        return http.post('/maintenance', data);
    }
    updatemaintenance(id, data) {
        return http.put(`/maintenance/${id}`, data);
    }
    deletemaintenance(id,status) {
        return http.delete('/maintenance/' + id+'/'+status);
    }
}

export default new MaintenanceService();