import http from "../http-common";

class AgencyService {
    getAgencies(name,status ){
        return http.get('/agancy?name='+name+'&&status='+status);
    }
    getAgency(id) {
        return http.get('/agancy/' + id);
    }
    createAgency(data) {
        return http.post('/agancy', data);
    }
    updateAgencyActivate(id, data) {
        return http.put(`/agancy/updateAgencyActivate/${id}`, data);
    }
    updateAgency(id, data) {
        return http.put(`/agancy/${id}`, data);
    }
    deleteAgency(id) {
        return http.delete('/agancy/' + id);
    }
}

export default new AgencyService();