import http from "../http-common";

class ColorsService {
    getcolors(name) {
        return http.get('/colors?name='+name);
    }
    getcolor(id) {
        return http.get('/colors/' + id);
    }
    createcolor(data) {
        return http.post('/colors', data);
    }
    updatecolor(id, data) {
        return http.put(`/colors/${id}`, data);
    }
    deletecolor(id,status) {
        return http.delete('/colors/' + id+'/'+status);
    }
}

export default new ColorsService();