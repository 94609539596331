import http from "../http-common";

class OfficeService {
    getoffices() {
        return http.get('/office');
    }
    getoffice(id) {
        return http.get('/office/' + id);
    }
    createoffice(data) {
        return http.post('/office', data);
    }
    updateoffice(id, data) {
        return http.put(`/office/${id}`, data);
    }
    deleteoffice(id) {
        return http.delete('/office/' + id);
    }
}

export default new OfficeService();