import http from "../http-common";

class BrandsMachineService {
    getbrandmachines(name) {
        return http.get('/brand_machine?name='+name);
    }
    getbrandmachine(id) {
        return http.get('/brand_machine/' + id);
    }
    createbrandmachine(data) {
        return http.post('/brand_machine', data);
    }
    updatebrandmachine(id, data) {
        return http.put(`/brand_machine/${id}`, data);
    }
    deletebrandmachine(id,status) {
        return http.delete('/brand_machine/' + id+'/'+status);
    }
}

export default new BrandsMachineService();