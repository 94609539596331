import http from "../http-common";

class LicenseService {
    getlicenses(name) {
        return http.get('/license?name='+name);
    }
    getlicenseitem(id){
        return http.get('/license/getlicenseitem/' + id);
    }
    getmemberdetail(id){
        return http.get('/license/getmemberdetail/' + id);
    }
    getlicense(id) {
        return http.get('/license/' + id);
    }
    createlicense(data) {
        return http.post('/license', data);
    }
    updatemember(id, data) {
        return http.put(`/license/updatemember/${id}`, data);
    }
    updatelicense(id, data) {
        return http.put(`/license/${id}`, data);
    }
    deletelicense(id,status) {
        return http.delete('/license/' + id+'/'+status);
    }
}

export default new LicenseService();