import axios from "axios";

class LinkImageService {
  getLinkImage() {
    // return 'http://localhost:8081/uploadimage?name='
    return 'https://api-owb.visdchiangrai.com/uploadimage?name='
  } 
  getLink() {
    // return 'http://localhost:8081'
    return 'https://api-owb.visdchiangrai.com'
  } 
  getLinkFrontend(){
    // return 'http://localhost:8082'
    return 'https://owb.visdchiangrai.com'
  }
  sendNotify(message,token){
    // return axios.get('http://localhost:8081/notify?message=' + message+'&&token=' + token);
    return axios.get('https://api-owb.visdchiangrai.com/notify?message=' + message+'&&token=' + token);
  }
  gettoken(code){
    // return axios.get('http://localhost:8081/gettoken?code=' + code);
    return axios.get('https://api-owb.visdchiangrai.com/gettoken?code=' + code);
  }
}

export default new LinkImageService();