import Vue from "vue";
import Router from "vue-router";
import Login from './components/Login.vue';
import Users from './components/Users.vue';
import Menu from './components/Menu.vue';
import qrcode from './components/qrcode.vue';
import addqrcode from './components/addqrcode.vue';
import evaluation from './components/evaluation.vue';
import header from './components/header.vue';
import Office from './components/Office.vue';
import evaluationall from './components/evaluationall.vue';
import Menumanage from './components/Menumanage.vue';
import report from './components/report.vue';
import master from './components/master.vue';
import typelicense from './components/typelicense.vue';
import documents from './components/documents.vue';
import maintenanceitems from './components/maintenanceitems.vue';
import maintenancelist from './components/maintenancelist.vue';
import maintenance from './components/maintenance.vue';
import tablemaintenance from './components/tablemaintenance.vue';
import ownership from './components/ownership.vue';
import ownershipedit from './components/ownershipedit.vue';
import members from './components/members.vue';
import memberedit from './components/memberedit.vue';
import owners from './components/owners.vue';
import owneredit from './components/owneredit.vue';
import license from './components/license.vue';
import licenseedit from './components/licenseedit.vue';
import vehicleedit from './components/vehicleedit.vue';
import vehicles from './components/vehicles.vue';
import PermissionDoc from './components/PermissionDoc.vue';
import PermissionDocEdit from './components/PermissionDocEdit.vue';
import PrintPermissionDoc from './components/PrintPermissionDoc.vue';
import ImportDocs from './components/ImportDocs.vue';
import PermissionList from './components/PermissionList.vue';
import Roles from './components/Roles.vue';
import Register from './components/Register.vue';
import ActivateEmail from './components/ActivateEmail.vue';
import ApprovePermission from './components/ApprovePermission.vue';
import line from './components/Line.vue';
import LoginLine from './components/LoginLine.vue';
import PermissionDocPB from './components/PermissionDocPB.vue';
import Agency from './components/Agency.vue';
import AgencyManager from './components/AgencyManager.vue';
import Profile from './components/Profile.vue';

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      alias: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/user",
      alias: "/user",
      name: "user",
      component: Users
    },
    {
      path: "/menu",
      alias: "/menu",
      name: "menu",
      component: Menu
    },
    {
      path: "/qrcode",
      alias: "/qrcode",
      name: "qrcode",
      component: qrcode
    },
    {
      path: "/addqrcode/:type/:id",
      alias: "/addqrcode",
      name: "addqrcode",
      component: addqrcode
    },
    {
      path: "/evaluation/:type/:id",
      alias: "/evaluation",
      name: "evaluation",
      component: evaluation
    },
    {
      path: "/header",
      alias: "/header",
      name: "header",
      component: header
    },
    {
      path: "/office",
      alias: "/office",
      name: "office",
      component: Office
    },
    {
      path: "/evaluationall",
      alias: "/evaluationall",
      name: "evaluationall",
      component: evaluationall
    },
    {
      path: "/Menumanage",
      alias: "/Menumanage",
      name: "Menumanage",
      component: Menumanage
    },
    {
      path: "/report",
      alias: "/report",
      name: "report",
      component: report
    },
    {
      path: "/master",
      alias: "/master",
      name: "master",
      component: master
    },
    {
      path: "/master/types/:id",
      alias: "/master",
      name: "master",
      component: typelicense
    },
    {
      path: "/master/documents/:id",
      alias: "/master",
      name: "master",
      component: documents
    },
    {
      path: "/permissionlist",
      alias: "/permissionlist",
      name: "permissionlist",
      component: PermissionList
    },

    {
      path: "/maintenanceitems/:id",
      alias: "/maintenanceitems",
      name: "maintenanceitems",
      component: maintenanceitems
    },
    {
      path: "/maintenancelist/:id",
      alias: "/maintenancelist",
      name: "maintenancelist",
      component: maintenancelist
    },
    {
      path: "/maintenance",
      alias: "/maintenance",
      name: "maintenance",
      component: maintenance
    },
    {
      path: "/tablemaintenance",
      alias: "/tablemaintenance",
      name: "tablemaintenance",
      component: tablemaintenance
    },
    {
      path: "/ownerships",
      alias: "/ownerships",
      name: "ownerships",
      component: ownership
    },
    {
      path: "/ownership/:id",
      alias: "/ownership",
      name: "ownership",
      component: ownershipedit
    },
    {
      path: "/members",
      alias: "/members",
      name: "members",
      component: members
    },
    {
      path: "/member/:id",
      alias: "/member",
      name: "member",
      component: memberedit
    },
    {
      path: "/owners",
      alias: "/owners",
      name: "owners",
      component: owners
    },
    {
      path: "/owner/:id",
      alias: "/owner",
      name: "owner",
      component: owneredit
    },
    {
      path: "/licenses",
      alias: "/licenses",
      name: "licenses",
      component: license
    },
    {
      path: "/license/:id",
      alias: "/license",
      name: "license",
      component: licenseedit
    },
    {
      path: "/vehicles",
      alias: "/vehicles",
      name: "vehicles",
      component: vehicles
    },
    {
      path: "/vehicle/:id",
      alias: "/vehicle",
      name: "vehicle",
      component: vehicleedit
    },
    {
      path: "/permissiondoc/:id",
      alias: "/permissiondoc",
      name: "permissiondoc",
      component: PermissionDoc
    },
    {
      path: "/permissiondocedit/:id",
      alias: "/permissiondocedit",
      name: "permissiondocedit",
      component: PermissionDocEdit
    },
    {
      path: "/permissiondocpb/:id",
      alias: "/permissiondocpb",
      name: "permissiondocpb",
      component: PermissionDocPB
    },
    {
      path: "/PrintPermissionDoc/:id",
      alias: "/PrintPermissionDoc",
      name: "PrintPermissionDoc",
      component: PrintPermissionDoc
    },
    {
      path: "/ApprovePermission/:id",
      alias: "/ApprovePermission",
      name: "ApprovePermission",
      component: ApprovePermission
    },
    {
      path: "/importdocs/:id",
      alias: "/importdocs",
      name: "importdocs",
      component: ImportDocs
    },
    {
      path: "/roles",
      alias: "/roles",
      name: "roles",
      component: Roles
    },
    {
      path: "/register",
      alias: "/register",
      name: "register",
      component: Register
    },
    {
      path: "/activateemail/:id",
      alias: "/activateemail",
      name: "activateemail",
      component: ActivateEmail
    },
  {
    path: "/line",
    alias: "/line",
    name: "line",
    component: line
  },
  {
    path: "/loginline",
    alias: "/loginline",
    name: "loginline",
    component: LoginLine
  },
  {
    path: "/agency",
    alias: "/agency",
    name: "Agency",
    component: Agency
  },
  {
    path: "/agencymanager",
    alias: "/agencymanager",
    name: "AgencyManager",
    component: AgencyManager
  },
  {
    path: "/profile",
    alias: "/profile",
    name: "profile",
    component: Profile
  },
  ]
});

Vue.use(Router);

router.beforeEach((to, from, next) => {
  var page = to.path.split("/")
  var qrcode = ''
  if (page[1] == 'activateemail' || page[1] == 'line' || page[1] ==  'permissiondocpb') {
    qrcode = to.path
  }
  // console.log(page[1]);
  // console.log(qrcode);
  const publicPages = ['/', '/login', '/register', qrcode];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('usermdm');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('permissionlist');
  } else {
    next();
  }

});

export default router;
