<template>
  <div class="container">
    <div
      class="card mb-5"
    >             <div class="card-body login-card-body">
            <form ref="form" @submit.prevent="sendEmail">
              <div class="card-body">
                <label>แนบไฟล์หนังสืออนุญาตจากขนส่ง</label>

                <div class="form-group mb-3" style="display:none;">
                  <label for="username">อีเมล<span style="color: red">*</span>  ใช้ Gmail เท่านั้น</label>
                  <input
                  name="to_email"
                    v-model="user.office_email"
                    type="email"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                </div>
                <div class="form-group mb-3" style="display:none;">
                  <label for="username">form_name</label>
                  <input
                  v-model="user.from_name"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    name="from_name"
                  />
                </div>
                <div class="form-group mt-3">
                  <a :href="data.file" target="_blank">{{ file_name }}</a><br/>
                  <input type="button" class="btn btn-warning btn-sm" id="get_file" value="อัพโหลดหนังสืออนุญาตจากขนส่ง" @click="upload()">
<input type="file" id="my_file" @change="onFileChange" accept="application/pdf,image/png, image/gif, image/jpeg">
                </div>
               จำนวนวันที่เปิดให้ดาวน์โหลดเอกสาร <input name="message" v-model="data.day" type="number"/> วัน
              </div>
              <div class="col-md-6 text-left">
                   <button
                    @click="save()"
                    type="button"
                    class="btn btn-success btn-sm"
                  >
                  บันทึก
                  </button></div>
            </form>
          </div>
        </div>
        <div v-if="statuspdf">
        <div style="padding: 0px;" ref="document" id="my_invoice">      
      <div style="text-align: center; background-color: white; margin: 0px;" class="mb-3">
        <label style="text-align: center; font-size: 20px">หนังสืออนุญาต</label><br />
      </div>
      <div class="row">
        <div class="col-md-6" style="text-align:left"> ที่</div>
        <div class="col-md-6">สถานีขนส่งผู้โดยสารจังหวัดเชียงราย แห่งที่ ๒<br/>สำนักงานขนส่งจังหวัดเชียงราย</div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12" style="text-align:center"><span style="margin-left:15%">วันที่ {{ user.write_dates }} เดือน {{ user.write_month }} พ.ศ.{{user.write_year}}</span></div>
        
      </div>
      <div class="row mt-5">
       
      </div>

      <div class="row mt-5">
        <div class="col-md-12" style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;อาศัยอำนาจตามความในมาตรา ๔๑ แห่งพระราชบัญญัติการขนส่งทางบก พ.ศ. ๒๕๒๒ นายทะเบียนกลางอนุญาตให้ {{ user.name }} ใช้รถ คันหมายเลขทะเบียน {{ user.license_no1 }} {{ user.license_no2 }} {{ user.license_no3 }} ทำการขนส่ง <span v-if="user.wrongtype==1">ผิดประเภท</span> <span v-if="user.offtrack==1">นอกเส้นทาง</span> <span v-if="user.outside==1">นอกท้องที่</span> เพื่อใช้ในกิจการ {{ user.business }}
       ของ{{ user.of }} ได้ตั้งแต่วันที่ {{ user.from_dates }} เดือน {{ user.from_month }} {{ user.from_year }} เวลา {{ user.from_time }} ถึงวันที่ {{ user.to_dates }} เดือน {{ user.to_month }} {{ user.to_year }} เวลา {{ user.to_time }} โดยมี ต้นทางที่ {{ user.origin }} สิ้นสุดปลายทางที่ {{ user.destination }} และเดินทาง {{ user.stop_through }}</div>


      </div>

  <!-- <div class="row mt-3" style="text-align: center; font-size: 14px">
    <div class="col-sm-6">
      
      </div>
      <div class="col-sm-6">
        <span style="text-align: center"
          >ลงชื่อ...............................................................</span
        >
        <br />
        <span 
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
      
      </div>
  </div> -->
  <div class="row">
    <div class="col-md-9"></div>
  <div class="col-md-3" style="text-align: right;margin-top: 40%;">
          <qr-code :text="qrcode" size="100"> </qr-code>
</div></div>
  <div class="row" style="text-align: left; font-size: 14px;margin-top: 5%;">
    <div class="col-sm-2">
      หมายเหตุ
    </div>
      <div class="col-sm-10" style="text-align: justify;">
      
      ๑. ให้ปิดหนังสืออนุญาตนี้ไว้ที่ด้านหน้าของรถตลอดเวลาที่นำรถไปใช้<br/>
๒.ผู้รับอนุญาตต้องปฏิบัติตามเงื่อนไขเกี่ยวกับอัตราค่าโดยสารที่กำหนดไว้ในใบอนุญาตประกอบการขนส่งประจำทางในเส้นทางที่ตนไปทำการขนส่งและจำนวนที่นั่งที่กำหนดไว้ในใบอนุญาตประกอบการขนส่งโดยเคร่งครัด<br/>
      ๓. หนังสืออนุญาตนี้ หากตรวจพบรอยถูก ลบ ขูด ฆ่า "ถือว่าใช้ไม่ได้"</div>
  </div>
  
    </div>
  </div>
    </div>
</template>

<script>
import emailjs from 'emailjs-com';
import PermissionDocService from '../services/PermissionDocService.js'
import LinkImageService from '../services/LinkImageService'
import html2pdf from 'html2pdf.js'
import axios from 'axios';
import LogNotifyService from '../services/LogNotifyService'

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components: {
  },
  data() { 
    return {
      concert_id: 0,
      menu: [],
      user: {},
      per_id:0,
      station_masters:[],
      statuspdf:false,
      qrcode:'',
      data:{},
      file_name:''
    };
  },
  mounted() {
    this.per_id = this.$route.params.id;
    
    PermissionDocService.getpermission_doc(this.per_id).then((res)=>{
      this.qrcode = LinkImageService.getLinkFrontend()+'/permissiondocpb/'+this.per_id

        // console.log(res.data);
    this.user = res.data
    // this.user.from_name = 'เจ้าหน้าที่'
    this.data.file = this.user.file
    this.data.day = this.user.day
    this.file_name= this.user.file_name
    this.user.request_date = this.getdateformat(this.user.request_date)
        this.user.write_dates = this.getdatesplit(this.user.write_date)
        this.user.write_month = this.getmonthsplit(this.user.write_date)
        this.user.write_year = this.getyearsplit(this.user.write_date)
        this.user.write_year = this.user.write_year.replace("พ.ศ.",'')
        this.user.from_dates = this.getdatesplit(this.user.from_date)
        this.user.from_month = this.getmonthsplit(this.user.from_date)
        this.user.from_year = this.getyearsplit(this.user.from_date)
        this.user.from_year = this.user.from_year.replace("พ.ศ.",'')
        this.user.from_time = this.converttime(this.user.from_time)
        this.user.to_dates = this.getdatesplit(this.user.to_date)
        this.user.to_month = this.getmonthsplit(this.user.to_date)
        this.user.to_year = this.getyearsplit(this.user.to_date)
        this.user.to_year = this.user.to_year.replace("พ.ศ.",'')
        this.user.to_time = this.converttime(this.user.to_time)
        this.exportToPDF()
    })
  },
  methods: {
    save(){
      if (this.data.day == "" || this.data.day == null ) {
        alert("กรุณาจำนวนวัน");
      } else if (this.data.file == "" || this.data.file == null) {
        alert("กรุณาเลือกไฟล์หนังสืออนุญาต");
      } else {
var file ={
            file:this.data.file,
            file_name:this.file_name,
            day:this.data.day,
            update_by:this.currentUser.id
          }
          PermissionDocService.updatedoc(this.per_id,file).then(()=>{
            // console.log(res.data);
           
            alert('บันทึกสำเร็จ')
            setTimeout(function () {
              // this.exportToPDF()
            }, 300);
          })
        }
    },
    upload(){
      document.getElementById('my_file').click();
    },
    onFileChange(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.onUploadFile(files[0]);
      // this.createBase64Image(selectedImage);
    },
    onUploadFile(selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      var link = LinkImageService.getLink()
      var http = link + '/uploadimage?type=approvedocs'+'&&name=' +selectedFile.name;
      var path = link + '/uploads/approvedocs/'+selectedFile.name;
      axios
        .post(http, formData)
        .then(() => {    
          this.data.file = path
          this.data.file_name = selectedFile.name
          this.file_name = selectedFile.name
          console.log(this.data);
          // MapDocService.deletemap_doc_permision(this.doc_id,this.car_id).then((res=>{
          // var file ={
          //   permission_doc_id:this.car_id,
          //   document_id:this.doc_id,
          //   name:selectedFile.name,
          //   path:path,
          //   status:1,
          //   created_by:this.currentUser.id
          // }
          // MapDocService.createmap_doc_permision(file).then(()=>{
          //   // console.log(res.data);
           
          //   this.getdocs()
          // })
            
          // }))
        
        })
        .catch((err) => {
          console.log(err);
        });
    },
    converttime(time){
      var t = time.split(":")
      t = t[0]+'.'+t[1] +' น.'
      return t
    },
    getdateformat(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
})
// console.log(result);
return result
    },
    getdatesplit(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  day: 'numeric',
})
// console.log(result);
return result
    },
    getmonthsplit(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  month: 'long',
})
// console.log(result);
return result
    },
    getyearsplit(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  year: 'numeric',
})
// console.log(result);
return result
    },
    generateGuid() {
  var result, i, j;
  result = '';
  for(j=0; j<32; j++) {
    if( j == 8 || j == 12 || j == 16 || j == 20)
      result = result + '-';
    i = Math.floor(Math.random()*16).toString(16).toUpperCase();
    result = result + i;
  }
  return result;
},
    sendEmail() {
        emailjs.sendForm('service_ziurs0r', 'template_7bfnzks',this.$refs.form,
        'v6IRDEPdvfhe_ir4C').then((result) => {
            console.log('SUCCESS!', result.text);
        }, (error) => {
            console.log('FAILED...', error.text);
        }
        )
    },
    dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
},

    async exportToPDF () {
        var element = this.$refs.document
        var opt = {
          margin: [3,3,3,2],
          filename: 'หนังสืออนุญาต.pdf',
          jsPDF: { unit: 'cm', format: 'a4', orientation: 'p' },
          pagebreak: {after: 'section'}
    };
let blobString = await html2pdf().from(element).set(opt).toPdf().output('datauristring');
    // console.log(blobString);
var name = this.user.request_at.replace('/','-')
var selectedFile = this.dataURLtoFile(blobString,'หนังสืออนุญาต'+name+'.pdf');
// console.log(selectedFile);
const formData = new FormData();
      formData.append("file", selectedFile); 
      // console.log(selectedFile);
      var link = LinkImageService.getLink()
      var http = link + '/uploadimage?type=permissiondocs'+'&&name=' +selectedFile.name;
      axios
        .post(http, formData)
        .then(() => {    
        
        })
        .catch((err) => {
          console.log(err);
        });
        
        // this.$router.push("/permissiondocedit/" + this.car_id);
			},
    signIn() {
      this.statuspdf = true
            this.exportToPDF()
      var senttooperator = {
        consideration_registrar_status:this.user.consideration_registrar_status,
        registrar_comment:this.user.registrar_comment,
        status_id:5,
        update_by:this.currentUser.id
      }
      PermissionDocService.senttooperator(this.per_id,senttooperator).then(async ()=>{
        if (this.user.consideration_registrar_status == true) {
          this.sendEmail()
          this.exportToPDF()
            setTimeout(function () {
              this.exportToPDF()
            }, 300);
            await LinkImageService.sendNotify(this.user.registrar_comment+' '+LinkImageService.getLinkFrontend()+'/permissiondocedit/'+this.per_id,this.user.office_line_token) 
var notify ={
          permission_docs_id:this.per_id,
          messages:this.user.registrar_comment,
          created_by:this.currentUser.id
}
LogNotifyService.createlog_notify(notify).then(()=>{
  this.exportToPDF()
  setTimeout(function () {
              this.exportToPDF()
            }, 300);
            // setTimeout(function () {
            //   location.reload();
            // }, 1500);
// console.log(res.data);
          alert('แจ้งไปยังเจ้าหน้าที่เรียบร้อยแล้ว')
})
        }else{
          alert('บันทึกสำเร็จ')
        }
      })
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  font-size: 14px;
  font-weight: bold;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

span {
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
  text-align: justify;
}

body {
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  font-size: 14px;
  color: black;
}

div.aa {
  text-align: justify;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

p {
  text-indent: 25px;
  text-align: justify;
  margin-bottom: 0px;
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

div {
  font-family: 'Niramit';
  font-size: 14px;
}

.title {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.content {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom-style: dotted;
  border-bottom: 1.6px dotted;
}

input {
  margin-bottom: 5px;
}
#my_file {
    display: none;
}
</style>
