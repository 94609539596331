import http from "../http-common";

class OperatortypesService {
    getoperatortypes(name ){
        return http.get('/operatortypes?name='+name);
    }
    getOperatortype(id) {
        return http.get('/operatortypes/' + id);
    }
    createOperatortype(data) {
        return http.post('/operatortypes', data);
    }
    updateOperatortype(id, data) {
        return http.put(`/operatortypes/${id}`, data);
    }
    deleteOperatortype(id) {
        return http.delete('/operatortypes/' + id);
    }
}

export default new OperatortypesService();