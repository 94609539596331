<template>
  <div class="container mt-5">
    <div style="padding: 0px;" ref="document" id="my_invoice">      
      <div style="text-align: center; background-color: white; margin: 0px;" class="mb-3">
        <label style="text-align: center; font-size: 20px">หนังสืออนุญาต</label><br />
      </div>
      <div class="row">
        <div class="col-md-8" style="text-align:left"> ที่</div>
        <div class="col-md-4">สถานีขนส่งผู้โดยสารจังหวัดเชียงราย แห่งที่ ๒<br/>สำนักงานขนส่งจังหวัดเชียงราย</div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12" style="text-align:center"><span style="margin-left:15%">วันที่ {{ datas.write_dates }} เดือน {{ datas.write_month }} พ.ศ.{{datas.write_year}}</span></div>
        
      </div>
     
      <div class="row mt-5">
        <div class="col-md-12" style="margin-left:60px;">อาศัยอำนาจตามความในมาตรา ๔๑ แห่งพระราชบัญญัติการขนส่งทางบก พ.ศ. ๒๕๒๒ นายทะเบียนกลางอนุญาตให้ {{ datas.name }} ใช้รถ คันหมายเลขทะเบียน {{ datas.license_no1 }} {{ datas.license_no2 }} {{ datas.license_no3 }} ทำการขนส่ง <span v-if="datas.wrongtype==1">ผิดประเภท</span> <span v-if="datas.offtrack==1">นอกเส้นทาง</span> <span v-if="datas.outside==1">นอกท้องที่</span> เพื่อใช้ในกิจการ {{ datas.business }}
       ของ{{ datas.of }} ได้ตั้งแต่วันที่ {{ datas.from_dates }} เดือน {{ datas.from_month }} {{ datas.from_year }} เวลา {{ datas.from_time }} ถึงวันที่ {{ datas.to_dates }} เดือน {{ datas.to_month }} {{ datas.to_year }} เวลา {{ datas.to_time }} โดยมี ต้นทางที่ {{ datas.origin }} สิ้นสุดปลายทางที่ {{ datas.destination }} และเดินทาง {{ datas.stop_through }}</div>


      </div>

  <div class="row mt-3" style="text-align: center; font-size: 14px">
    <div class="col-sm-6">
      
      </div>
      <div class="col-sm-6">
        <span style="text-align: center"
          >ลงชื่อ...............................................................</span
        >
        <br />
        <span 
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
      
      </div>
  </div>
  <div class="row" style="text-align: left; font-size: 14px;margin-top: 30%;">
    <div class="col-sm-1">
      หมายเหตุ
    </div>
      <div class="col-sm-10">
      
      ๑. ให้ปิดหนังสืออนุญาตนี้ไว้ที่ด้านหน้าของรถตลอดเวลาที่นำรถไปใช้<br/>
๒. ผู้รับอนุญาตต้องปฏิบัติตามเงื่อนไขเกี่ยวกับอัตราค่าโดยสารที่กำหนดไว้ในใบอนุญาตประกอบการขนส่งประจำทางในเส้นทางที่ตนไปทำการขนส่งและจำนวนที่นั่งที่กำหนดไว้ในใบอนุญาตประกอบการขนส่งโดยเคร่งครัด<br/>
      ๓. หนังสืออนุญาตนี้ หากตรวจพบรอยถูก ลบ ขูด ฆ่า "ถือว่าใช้ไม่ได้"</div>
  </div>
    </div>
  
  </div>
</template>

<script src="https://api-owb.visdchiangrai.com/picker_date.js"></script>

<script>
import PermissionDocService from '../services/PermissionDocService.js'
import html2pdf from 'html2pdf.js'
import LinkImageService from '../services/LinkImageService'
import axios from 'axios';

export default {
   
  name: "Cars",
  props: {
    msg: String,
  },
  components: {
  },
  data() {
    return {
      brands: [],
      characteristics: [],
      symbols: [],
      car_types: [],
      datas: [],
      car_id: 0,
      image: "",
      itemPreviewImage: "",
      symbol: [],
      statusedit: false,
      docs:[]
    };
  },
  methods: {
    dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
},

    async exportToPDF () {
        var element = this.$refs.document
        var opt = {
          margin: [1,1.5,0,1.2],
          filename: 'คำขออนุญาตให้ใช้รถผิดประเภท.pdf',
          jsPDF: { unit: 'cm', format: 'a4', orientation: 'p' },
          pagebreak: {after: 'section'}
    };
let blobString = await html2pdf().from(element).set(opt).toPdf().output('datauristring');
    // console.log(blobString);
var name = this.datas.request_at.replace('/','-')
var selectedFile = this.dataURLtoFile(blobString,name+'.pdf');

const formData = new FormData();
      formData.append("file", selectedFile); 
      // console.log(selectedFile);
      var link = LinkImageService.getLink()
      var http = link + '/uploadimage?type=permissiondocs'+'&&name=' +selectedFile.name;
      axios
        .post(http, formData)
        .then(() => {    
        
        })
        .catch((err) => {
          console.log(err);
        });
        
        // this.$router.push("/permissiondocedit/" + this.car_id);
			},
    edit(){
      this.$router.push('/permissiondoc/' + this.car_id);
    },
    getdata(){
      PermissionDocService.getpermission_doc(this.car_id).then((res)=>{
        // console.log(res.data);
        this.datas = res.data;
        this.datas.request_date = this.getdateformat(this.datas.request_date)
        this.datas.write_dates = this.getdatesplit(this.datas.write_date)
        this.datas.write_month = this.getmonthsplit(this.datas.write_date)
        this.datas.write_year = this.getyearsplit(this.datas.write_date)
        this.datas.write_year = this.datas.write_year.replace("พ.ศ.",'')
        this.datas.from_dates = this.getdatesplit(this.datas.from_date)
        this.datas.from_month = this.getmonthsplit(this.datas.from_date)
        this.datas.from_year = this.getyearsplit(this.datas.from_date)
        this.datas.from_year = this.datas.from_year.replace("พ.ศ.",'')
        this.datas.from_time = this.converttime(this.datas.from_time)
        this.datas.to_dates = this.getdatesplit(this.datas.to_date)
        this.datas.to_month = this.getmonthsplit(this.datas.to_date)
        this.datas.to_year = this.getyearsplit(this.datas.to_date)
        this.datas.to_year = this.datas.to_year.replace("พ.ศ.",'')
        this.datas.to_time = this.converttime(this.datas.to_time)
        
        setTimeout(function () {
          this.exportToPDF()
        }, 500);
      })
    },
    converttime(time){
      var t = time.split(":")
      t = t[0]+'.'+t[1] +' น.'
      return t
    },
    getdateformat(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
})
// console.log(result);
return result
    },
    getdatesplit(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  day: 'numeric',
})
// console.log(result);
return result
    },
    getmonthsplit(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  month: 'long',
})
// console.log(result);
return result
    },
    getyearsplit(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  year: 'numeric',
})
// console.log(result);
return result
    }
    
  },
  mounted() {
    this.car_id = this.$route.params.id;
    // console.log(this.car_id);
    if (this.car_id != 0) {
      this.getdata()

    } else {
      this.statusedit = true;
      this.datas.status = 1;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  font-size: 14px;
  font-weight: bold;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

span {
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
  text-align: justify;
}

body {
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  font-size: 14px;
  color: black;
}

div.aa {
  text-align: justify;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

p {
  text-indent: 25px;
  text-align: justify;
  margin-bottom: 0px;
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

div {
  font-family: 'Niramit';
  font-size: 14px;
}

.title {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.content {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom-style: dotted;
  border-bottom: 1.6px dotted;
}

.printableArea {
  width: 8.5cm;
  height: 100%;
}

@page {
  size: 8cm auto;
  width: 8cm;
  height: 100%;
  margin: 0;
  margin-left: 5px !important;
}

input {
  margin-bottom: 5px;
}</style>
