import http from "../http-common";

class MapDocService {
    getmap_doc_permisions() {
        return http.get('/map_doc_permision');
    }
    getmap_doc_permision(id) {
        return http.get('/map_doc_permision/' + id);
    }
    createmap_doc_permision(data) {
        return http.post('/map_doc_permision', data);
    }
    updatemap_doc_permision(id, data) {
        return http.put(`/map_doc_permision/${id}`, data);
    }
    deletemap_doc_permision(id,per_id) {
        return http.delete('/map_doc_permision/' + id+'/'+per_id);
    }
}

export default new MapDocService();