<template>
  <div class="container">   
    <div
      class="card mb-5"
      style="padding-left: 1.5cm; background-color: white; padding-right: 0.5cm"
    >    
    <div>
          <div class="form-group col-md-12 mt-3">
        <label for="exampleFormControlInput1" v-if="currentUser.role_id == 1">แนบไฟล์เอกสาร</label>
        <label for="exampleFormControlInput1" v-if="currentUser.role_id == 2">เอกสาร</label>
         <table class="table mt-3">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">เอกสาร</th>
              <th scope="col">ไฟล์</th>
              <th scope="col" v-if="currentUser.role_id == 1"></th>
            </tr>
          </thead>
          <tbody v-if="docs.length > 0">
            <tr v-for="(dd, i) in docs" :key="i + 1">
              <td><span v-if="dd.name_file != null"><i class="fa fa-check" style="color:green" aria-hidden="true"></i></span> <span v-else><i class="fa fa-times" style="color:red" aria-hidden="true"></i></span> </td>
              <td> <a target="_blank">{{ dd.name }}</a></td>
              <td>
                <a :href="dd.path" target="_blank">{{ dd.name_file }}</a></td>
              <!-- <td v-if="currentUser.role_id == 1"> 
                <input type="button" class="btn btn-info btn-sm" id="get_file" value="อัพโหลด" @click="upload(dd.id)" v-if="datas.status_id != 5">
<input type="file" id="my_file" @change="onFileChange">
              </td> -->
            </tr>
          </tbody>
        </table>
        <!-- <div class="col-md-6 text-left">
                   <button
                    @click="savedoc()"
                    type="button"
                    class="btn btn-success btn-sm"
                  >
                  บันทึกข้อมูล
                  </button></div> -->
      </div>
    </div>
    <section></section>
    <div>
    </div>
    </div>
    <!-- <div id="app" ref="document" v-else>
      <PermissionDocEdit />
	</div> -->
  </div>
</template>

<script src="https://api-owb.visdchiangrai.com/picker_date.js"></script>

<script>
import VueHtml2pdf from "vue-html2pdf";
import ContentToPrint from "./ContentToPrint";

import PermissionDocEdit from "./PermissionDocEdit.vue";
import MapDocService from "../services/MapDocService";
import DocumentService from "../services/DocumentService";

import html2pdf from 'html2pdf.js'
import axios from 'axios';
import PermissionDocService from '../services/PermissionDocService.js'
import ProvinceService from "../services/ProvinceService";
import AmphuresService from "../services/AmphuresService";
import DistrictService from "../services/DistrictService";
import LinkImageService from "../services/LinkImageService";
import LogNotifyService from '../services/LogNotifyService'
import emailjs from 'emailjs-com';

export default {
  name: "Cars",
  props: {
    msg: String,
  },
  components: {
    PermissionDocEdit,
    VueHtml2pdf,
    ContentToPrint,
  },
  data() {
    return {
      brands: [],
      characteristics: [],
      symbols: [],
      car_types: [],
      datas: [],
      car_id: 0,
      image: "",
      itemPreviewImage: "",
      symbol: [],
    editstatus:false,
      tran_id: 0,
      filteredList:[],
      licenses:[],
      license:'',
      docs:[],
      docid:'',
      provinces:[],
      amphurs:[],
      districts:[],
      zipcode:'',
      doc_id:0,
      docstemp:[],
      docsfailed:[],
    };
  },
  methods: {
    upload(id){
      this.doc_id = id
      document.getElementById('my_file').click();
    },
    getdocs(){
      DocumentService.getdocuments().then(async (res)=>{
        // console.log(res.data);
        this.docstemp = res.data
        await MapDocService.getmap_doc_permision(this.car_id).then((docs)=>{
        // console.log(docs.data);
        var doclist = docs.data
        if (doclist.length > 0) {
         for (let d = 0; d < this.docstemp.length; d++) {
          for (let dd = 0; dd < doclist.length; dd++) {
            // console.log(this.docstemp[d].id, doclist[dd].id);
            if (this.docstemp[d].id == doclist[dd].id) {
              this.docstemp[d].path = doclist[dd].path
              this.docstemp[d].name_file = doclist[dd].name_file
            }
          }
         }
        }
        this.docs = this.docstemp
        for (let ff = 0; ff < this.docstemp.length; ff++) {
          if (this.docstemp[ff].name_file == null) {
           this.docsfailed.push({
            id:this.docstemp[ff].id,
            name:this.docstemp[ff].name,
            path :this.docstemp[ff].path,
              name_file :this.docstemp[ff].name_file,
           })
          }
          
        }
      });
      })
// MapDocService.getmap_doc_permision(this.car_id).then((res)=>{
//         console.log(res.data);
//         if (res.data.length == 0) {
         
//         }else{
//           this.docs = res.data
//         }
//       });
    },
    onChangeProvince(){
// console.log(evt.target.value);
this.getamphurs()
    },
    onChangeAmphur(){
// console.log(evt.target.value);
this.getdistricts()
    },
    onChangeDistrict(){
// console.log(evt.target.value);
this.getzipcode()
    },
    getprovinces(){
      ProvinceService.getprovinces().then((res)=>{
        this.provinces = res.data
        // console.log(res.data);
      })
    },
    getamphurs(){
      AmphuresService.getamphures(this.datas.province_id).then((res)=>{
        this.amphurs = res.data
        this.districts = {}
        this.zipcode = ''
        // console.log(res.data);
      })
    },
    getdistricts(){
      DistrictService.getdistricts(this.datas.amphur_id).then((res)=>{
        this.districts = res.data
        // console.log(res.data);
      })
    },
    getzipcode(){
      DistrictService.getdistrict(this.datas.tambon_id).then((res)=>{
        this.zipcode = res.data.zip_code
        // console.log(res.data);
      })
    },
    sendEmail(to_email) {
    var templateParams = {
        to_email: to_email,
        header: 'หนังสืออนุญาตคำขอที่ '+ this.datas.request_at,
    message: 'ผู้ประกอบการอัพโหลดไฟล์คำขอที่ '+this.datas.request_at +' ตรวจสอบที่ '+LinkImageService.getLinkFrontend()+'/permissiondocedit/'+this.car_id
  };
        emailjs.send('service_ziurs0r', 'template_7bfnzks',templateParams,
        'v6IRDEPdvfhe_ir4C').then((result) => {
            console.log('SUCCESS!', result.text);
        }, (error) => {
            console.log('FAILED...', error.text);
        }
        )
    },
    onFileChange(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.onUploadFile(files[0]);
      // this.createBase64Image(selectedImage);
    },
    async savedoc(){
      var notify ={
          permission_docs_id:this.car_id,
          messages:'ผู้ประกอบการอัพโหลดไฟล์ คำขอที่ '+this.datas.request_at +' ตรวจสอบที่ '+LinkImageService.getLinkFrontend()+'/permissiondocedit/'+this.car_id,
          created_by:this.currentUser.id
}
this.sendEmail('เพิ่มไฟล์เอกสาร',this.datas.office_email)
await LinkImageService.sendNotify('ผู้ประกอบการอัพโหลดไฟล์ คำขอที่ '+this.datas.request_at +' ตรวจสอบที่ '+LinkImageService.getLinkFrontend()+'/permissiondocedit/'+this.car_id,this.datas.office_line_token) 

LogNotifyService.createlog_notify(notify).then(()=>{
  setTimeout(function () {
    location.reload()
            }, 1000);
            alert('แจ้งไปยังเจ้าหน้าที่เรียบร้อยแล้ว')
// console.log(res.data);
})
    },
     onUploadFile(selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      var link = LinkImageService.getLink()
      var http = link + '/uploadimage?type='+this.doc_id+'&&name=' +selectedFile.name;
      var path = link + '/uploads/'+this.doc_id+'/'+selectedFile.name;
      axios
        .post(http, formData)
        .then(() => {    
          MapDocService.deletemap_doc_permision(this.doc_id,this.car_id).then((res=>{
          var file ={
            permission_doc_id:this.car_id,
            document_id:this.doc_id,
            name:selectedFile.name,
            path:path,
            status:1,
            created_by:this.currentUser.id
          }
          MapDocService.createmap_doc_permision(file).then(()=>{
            // console.log(res.data);
           
            this.getdocs()
          })
            
          }))
        
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        this.itemPreviewImage = evt.target.result;
        this.image = evt.target.result;
        this.docs.push({
          id: this.docs.length + 1,
          name : file.name,
          image : evt.target.result,
          width:0,
          height:0
        })
        // console.log(this.docs);
      };

      reader.readAsDataURL(file);
    },
    edit(){
      this.editstatus = true;
      window.scrollTo(0, 0);
    },
    exportToPDF () {
      this.downloadstatus = true
				html2pdf(this.$refs.document, {
					margin: [1,1.5,0,1.2],
					filename: 'หนังสือสัญญาเดินรถร่วม ทะเบียน'+this.datas.license+'.pdf',
					jsPDF: { unit: 'cm', format: 'a4', orientation: 'p' },
          pagebreak: {after: 'section'}
				})
			},
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    hasGenerated() {
      alert("PDF generated successfully!");
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    createddate(value) {  
      // console.log(value); 
  var streetaddress = String(value);
    var d = streetaddress.substring(0, streetaddress.indexOf("/"));
    var m = streetaddress.substring(5, streetaddress.indexOf("/"));
    m = m.substring(1, 3);
    var y = streetaddress.substring(5);
    var full = m + "/" + d + y;
    // console.log(full);
    var date = new Date(full);
    // console.log(date);
    var day = date.getDate();
    var month = date.getMonth()+1;
    var year = date.getFullYear()-543;
    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    return  year + "-" + month + "-" + day;
  },
    save() {
      if (this.datas.request_at == null) {
        alert("กรุณากรอกคำขอที่");
      } else if (document.getElementById("request_date").value == null) {
        alert("กรุณากรอกรับวันที่");
      } else if (this.datas.request_receiver == null) {
        alert("กรุณากรอกผู้รับ");
      } else if (this.datas.write_at == null) {
        alert("กรุณากรอกเขียนที่");
      } else if (document.getElementById("write_date").value == null) {
        alert("กรุณากรอกวันที่");
      } else if (this.datas.name == null) {
        alert("กรุณากรอกชื่อ");
      } else if (this.datas.relate == null) {
        alert("กรุณากรอกลงนาม");
      } else if (this.datas.address == null) {
        alert("กรุณากรอกที่อยู่");
      } else if (this.datas.tambon_id == null) {
        alert("กรุณากรอกตำบล");
      } else if (this.datas.amphur_id == null) {
        alert("กรุณากรอกอำเภอ");
      } else if (this.datas.province_id == null) {
        alert("กรุณากรอกจังหวัด");
      } else if (this.datas.phone == null) {
        alert("กรุณากรอกเบอร์โทรศัพท์");
      } else if (this.datas.license_no1 == null) {
        alert("กรุณากรอกเลขทะเบียน");
      } else if (this.datas.business == null) {
        alert("กรุณากรอกกิจการ");
      } else if (this.datas.of == null) {
        alert("กรุณากรอกเจ้่าของกิจการ");
      } else if (document.getElementById("from_date").value == null) {
        alert("กรุณากรอกตั้งแต่วันที่");
      } else if (document.getElementById("to_date").value == null) {
        alert("กรุณากรอกถึงวันที่");
      } else if (this.datas.origin == null) {
        alert("กรุณากรอกต้นทาง");
      } else if (this.datas.stop == null) {
        alert("กรุณากรอกผ่านจังหวัด");
      } else if (this.datas.destination == null) {
        alert("กรุณากรอกปลายทาง");
      } else if (this.datas.stop_through == null) {
        alert("กรุณากรอกจังหวัดปลายทางที่กลับ");
      } else {
        var trans = {
          // car_owner_id: this.car_id,
          request_at:this.datas.request_at,
          request_date: this.createddate(document.getElementById("request_date").value),
          request_receiver: this.datas.request_receiver,
          write_at: this.datas.write_at,
          write_date: this.createddate(document.getElementById("write_date").value),
          name: this.datas.name,
          relate: this.datas.relate,
          address: this.datas.address,
          moo: this.datas.moo,
          soi: this.datas.soi,
          road: this.datas.road,
          tambon_id: this.datas.tambon_id,
          amphur_id: this.datas.amphur_id,
          province_id: this.datas.province_id,
          phone: this.datas.phone,
          type_id: 1,
          number: this.datas.number,
          license_no1: this.datas.license_no1,
          license_no2: this.datas.license_no2,
          license_no3: this.datas.license_no3,
          wrongtype: this.datas.wrongtype,
          offtrack: this.datas.offtrack,
          outside: this.datas.outside,
          business: this.datas.business,
          of: this.datas.of,
          from_date: this.createddate(document.getElementById("from_date").value),
          from_time: this.datas.from_time,
          to_date: this.createddate(document.getElementById("to_date").value),
          to_time: this.datas.to_time,
          origin: this.datas.origin,
          stop: this.datas.stop,
          destination: this.datas.destination,
          stop_through: this.datas.stop_through,
          status_id:this.datas.status_id,
          created_by:1,
          update_by:1
        };
        console.log(trans);
        if (this.car_id != 0) {
          PermissionDocService.updatepermission_doc(this.car_id, trans).then((res) => {
            this.$router.push("/permissiondocedit/" + this.car_id);
            alert("บันทึกสำเร็จ");
            setTimeout(function () {
              location.reload();
            }, 300);
            window.scrollTo(0, 0);
          });
        } else {
          PermissionDocService.createpermission_doc(trans).then((res) => {
            this.car_id = res.data.id
            this.$router.push("/permissiondocedit/" + this.car_id);
            alert("บันทึกสำเร็จ");
            setTimeout(function () {
              location.reload();
            }, 300);
            window.scrollTo(0, 0);
          });
        }
      }
    },
    getdate(value){
      // console.log(value); 
    var date = new Date(value);
    // console.log(date);
    var day = date.getDate();
    var month = date.getMonth()+1;
    var year = date.getFullYear()+543;
    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    return  day + "/" + month + "/" + year;
    },
    getdata() {
      PermissionDocService.getpermission_doc(this.car_id).then((res) => {
        // console.log(res.data);
        this.datas = res.data;
        this.getamphurs()
        this.getdistricts()
        this.getzipcode()
      });
    },
    searchlicense() {
      this.filteredList = []
      this.filteredList = this.licenses.filter((post) => {
        return post.license.toLowerCase().includes(this.datas.license.toLowerCase());
      });
    },
  },
  mounted() {
   
    this.car_id = this.$route.params.id;
this.getdocs()
    // console.log(this.car_id);
    if (this.car_id != 0) {
      this.getdata();
      // this.editstatus = false;
      // this.$router.push('/permissiondocedit/' + this.car_id);
    } else {
      this.editstatus = true;
      this.datas.status = 1;
    }

  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input {
  margin: 5px;
  font-size: 14px;
  /* font-family:Angsana New !important; */
  font-family: 'Niramit';
}
label {
   font-size: 14px;
  font-weight: bold;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
   color: black;
}
span {
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
   color: black;
}
body {
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  font-size: 14px; 
   color: black;
}
div.aa {
  text-align: justify;
  /* font-family:Angsana New; */
   color: black;
   font-family: 'Niramit';
}
p {
  text-indent: 25px;
  text-align: justify;
  margin-bottom: 0px;
  font-size: 14px;
  /* font-family:Angsana New; */
  color: black;
  font-family: 'Niramit';
}
div{
  font-size: 14px;
}
#my_file {
    display: none;
}
</style>
