import http from "../http-common";

class OwnerService {
    getowners(name) {
        return http.get('/owners?name='+name);
    }
    getowner(id) {
        return http.get('/owners/' + id);
    }
    createowner(data) {
        return http.post('/owners', data);
    }
    updateowner(id, data) {
        return http.put(`/owners/${id}`, data);
    }
    deleteowner(id,status) {
        return http.delete('/owners/' + id+'/'+status);
    }
}

export default new OwnerService();