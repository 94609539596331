import http from "../http-common";

class RoleCarService {
    getrole_cars() {
        return http.get('/role_car');
    }
    getrole_car(id) {
        return http.get('/role_car/' + id);
    }
    createrole_car(data) {
        return http.post('/role_car', data);
    }
    updaterole_car(id, data) {
        return http.put(`/role_car/${id}`, data);
    }
    deleterole_car(id,status) {
        return http.delete('/role_car/' + id+'/'+status);
    }
}

export default new RoleCarService();