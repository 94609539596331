<template>
  <div class="container">
    <div class="col mb-3 mt-3" style="text-align: right">
      <a :href="'owner/'+0">
        <button
          type="button"
          id="get_file"
          class="btn btn-success"
        >
          <i class="fa fa-plus"></i> เพิ่มเจ้าของรถ
        </button></a
      >
    </div>
    <table class="table table-bordered">
      <thead>
        <tr class="table-active">
          <th scope="col">ลำดับที่</th>
          <th scope="col">ผู้ถือกรรมสิทธิ์</th>
          <th scope="col">ที่อยู่</th>
          <th scope="col">โทร</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>
            {{ i + 1 }}
          </td>
          <td>
            {{ l.name }}
          </td>
          <td>
            <span> {{ l.number }}&nbsp;</span>
            <span v-if="l.village_no">หมู่ที่ {{ l.village_no }}&nbsp;</span>
            <span v-if="l.lane">ซอย {{ l.lane }}&nbsp;</span>
            <span v-if="l.road">ถนน {{ l.road }}&nbsp;</span>
            <span>ตำบล{{ l.district }}&nbsp;</span>
            <span>อำเภอ{{ l.amphure }}&nbsp;</span>
            <span>จังหวัด{{ l.province }}&nbsp;</span>
            <span>{{ l.zip_code }}&nbsp;</span>
          </td>
          <td>
            {{ l.phone }}
          </td>
          <td>
            <a :href="'/owner/'+l.id">
              <button
                type="button"
                class="btn btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#AddUser"
              >
                <i class="fa fa-edit"></i></button
            ></a>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal -->
    <div
      class="modal fade"
      id="AddUser"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            
          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                <div class="form-group mt-3">
                  <label for="username">ชื่อผู้ใช้งาน</label>
                  <input
                    v-model="user.username"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                    placeholder="กรุณากรอกชื่อผู้ใช้งาน"
                  />
                </div>
<div class="form-group mt-3">
                  <label for="password">สิทธิ์การใช้งาน</label>
                  <select class="form-control" v-model="user.role_id">
  <option v-for="(i,r) in roles" :key="r" :value="i.id">{{i.name}}</option>
</select>
                </div>
                <div class="form-group mt-3">
                  <label for="password">รหัสผ่าน</label>
                  <input
                    v-model="user.password"
                    type="password"
                    class="form-control form-control-sm"
                    id="password"
                    placeholder="กรุณากรอกรหัสผ่าน"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button
            id="closeduser"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/UserService";
import OwnerService from "../services/OwnerService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      user_id: 0,
      hash: 0,
      title: "",
      roles:[]
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getid(id) {
      this.user_id = id;
      if (this.user_id != 0) {
        this.title = "แก้ไขข้อมูลผู้ใช้งาน";
        // console.log(this.user_id);
        UserService.getUser(this.user_id).then((res) => {
          // console.log(res.data);
          this.user = res.data;
          this.hash = this.user.password;
        });
      } else {
        this.title = "เพิ่มข้อมูลผู้ใช้งาน";
        this.user = [];
      }
    },
    getUsers() {
      OwnerService.getowners().then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
