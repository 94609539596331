<template>
  <div class="container mt-5">
    <div style="padding: 0px;">
      <div class="card mt-3 mb-3" style="padding-left: 1.5cm; background-color: white; padding-right: 0.5cm">
        <div class="row mt-5">
          <div class="col-sm-6"><input value="คำขอที่" readonly class="title" style="width:15%;" /><input
              v-model="datas.request_at" readonly type="text" class="content" name="name" style="width:30%;" /></div>
        </div>
        <!-- <div class="row">
          <div class="col-sm-6"><input value="รับวันที่" readonly class="title" style="width:15%;" /><input
              v-model="datas.request_date" readonly type="text" class="content" name="name" style="width:30%;" /></div>
        </div>
        <div class="row">
          <div class="col-sm-6"><input value="ผู้รับ" readonly class="title" style="width:15%;" /><input
              v-model="datas.request_receiver" readonly type="text" class="content" name="name" style="width:30%;" />
          </div>
        </div> -->
        <div style="text-align: center; background-color: white; margin: 0px;" class="mb-3">
          <label style="text-align: center; font-size: 1rem">คำขออนุญาตให้ใช้รถผิดประเภท</label><br />
          <label style="text-align: center; font-size: 1rem">และใช้รถทำการขนส่งนอกเส้นทางหรือนอกท้องที่</label>
        </div>
        <div class="row">
          <div class="col-sm-9" style="text-align:right"></div>
          <div class="col-sm-3"><input value="เขียนที่" readonly class="title" style="width:20%;" /><input
              v-model="datas.write_at" readonly type="text" class="content" name="name" style="width:80%;" /></div>
        </div>
        <div class="row">
          <div class="col-md-8" style="text-align:right"></div>
          <div class="col-md-4"><input value="วันที่" readonly class="title" style="width:9%;" /><input
              v-model="datas.write_dates" readonly type="text" class="content" name="name" style="width:17%;" />
            <input value="เดือน" readonly class="title" style="width:11%;" /><input v-model="datas.write_month" readonly
              type="text" class="content" name="name" style="width:30%;" />
            <input value="พ.ศ." readonly class="title" style="width:11%;" /><input v-model="datas.write_year" readonly
              type="text" class="content" name="name" style="width:22%;" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <input value="เรียน นายทะเบียน" readonly class="title" style="width:100%;" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" style="margin-left:60px;"><input value="ด้วยข้าพเจ้า (นาย, นาง, นางสาว)" readonly
              class="title" style="width:20%;" /><input v-model="datas.name" readonly type="text" class="content"
              name="name" style="width:75%;" /></div>

          <div class="col-md-5"><input value="ซึ่งมีอำนาจลงนามผูกพัน" readonly class="title" style="width:38%;" /> <input
              v-model="datas.relate" readonly type="text" class="content" name="name" style="width:61%;" /></div>
          <div class="col-md-4"><input value="สำนักงานตั้งอยู่ที่" readonly class="title" style="width:33%;height:90%;" />
            <input v-model="datas.address" readonly type="text" class="content" name="name" style="width:67%;" />
          </div>
          <div class="col-md-3"><input value="หมู่ที่" readonly class="title" style="width:13%;" /> <input
              v-model="datas.moo" readonly type="text" class="content" name="name" style="width:83%;" /></div>

          <div class="col-md-3"><input value="ซอย" readonly class="title" style="width:14%;" /> <input v-model="datas.soi"
              readonly type="text" class="content" name="name" style="width:84%;" /></div>
          <div class="col-md-3"><input value="ถนน" readonly class="title" style="width:14%;height:90%;" /> <input
              v-model="datas.road" readonly type="text" class="content" name="name" style="width:84%;" /></div>
          <div class="col-md-3"><input value="ตำบล" readonly class="title" style="width:16%;" /> <input
              v-model="datas.tambon_th" readonly type="text" class="content" name="name" style="width:82%;" /></div>
          <div class="col-md-3"><input value="เขต/อำเภอ" readonly class="title" style="width:30%;" /> <input
              v-model="datas.amphur_th" readonly type="text" class="content" name="name" style="width:66%;" /></div>
          <div class="col-md-3"><input value="จังหวัด" readonly class="title" style="width:20%;" /> <input
              v-model="datas.province_th" readonly type="text" class="content" name="name"
              style="width:77%;height:80%;" />
          </div>
          <div class="col-md-3"><input value="โทรศัพท์" readonly class="title" style="width:23%;" /> <input
              v-model="datas.phone" readonly type="text" class="content" name="name" style="width:75%;height:80%;" />
          </div>
          <div class="col-md-6"><input value="ได้รับใบอนุญาตประกอบการขนส่งประเภท" readonly class="title"
              style="width:53%;" />
            <input v-model="datas.type_name" readonly type="text" class="content" name="name"
              style="width:46%;height:80%;" />
          </div>
          <div class="col-md-2">
            <input value="เลขที่" readonly class="title" style="width:23%;" />
            <input v-model="datas.number" readonly type="text" class="content" name="name"
              style="width:77%;height:80%;" />
          </div>
          <div class="col-md-3">
            <input value="หมายเลขเส้นทาง" readonly class="title" style="width:45%;" />
            <input v-model="datas.no_route" readonly type="text" class="content" name="name"
              style="width:55%;height:80%;" />
          </div>
          <div class="col-md-3">
            <input value="หมายเลขประจำรถ" readonly class="title" style="width:46%;" />
            <input v-model="datas.no_car" readonly type="text" class="content" name="name"
              style="width:50%;height:80%;" />
          </div>
          <div class="col-md-12"><input value="มีความประสงค์ที่จะขอใช้รถดังต่อไปนี้" readonly class="title"
              style="width:30%;" />
          </div>
          <div class="col-md-12" style="margin-left:60px;"><input value="๑. เลขทะเบียน" readonly class="title"
              style="width:10%;" />
            <input v-model="datas.license_no1_text" readonly type="text" class="content" name="name"
              style="width:30%;height:80%;" />
          </div>
          <div class="col-md-12" style="margin-left:60px;"><input value="๒. เลขทะเบียน" readonly class="title"
              style="width:10%;" />
            <input v-model="datas.license_no2_text" readonly type="text" class="content" name="name"
              style="width:30%;height:80%;" />
          </div>
          <div class="col-md-12" style="margin-left:60px;"><input value="๓. เลขทะเบียน" readonly class="title"
              style="width:10%;" />
            <input v-model="datas.license_no3_text" readonly type="text" class="content" name="name"
              style="width:30%;height:80%;" />
          </div>
          <div class="col-md-12" style="margin-left:60px;"><input
              value="(ในกรณีที่เป็นรถประจำทางหรือรถขนาดเล็กให้ระบุหมายเลขเส้นทางและหมายเลขประจำรถด้วย)" readonly
              class="title" style="width:100%;" />
          </div>
          <div class="col-md-12"><input value="ทำการขนส่ง" readonly class="title" style="width:12%;" />
            <input class="form-check-input" readonly type="checkbox" style="width:2%;" value="wrongtype" id="wrongtype"
              v-model="datas.wrongtype" disabled>
            <input value="ผิดไปจากประเภทที่จดทะเบียนไว้" readonly class="title" style="width:25%;" />
            <input class="form-check-input" readonly type="checkbox" style="width:2%;" value="offtrack" id="offtrack"
              v-model="datas.offtrack" disabled>
            <input value="นอกเส้นทาง" readonly class="title" style="width:13%;" />
            <input class="form-check-input" readonly type="checkbox" style="width:2%;" value="outside" id="outside"
              v-model="datas.outside" disabled>
            <input value="นอกท้องที่" readonly class="title" style="width:10%;" />
            <input value="เพื่อใช้ในกิจการ" readonly class="title" style="width:10%;" />
            <input v-model="datas.business" readonly type="text" class="content" name="name"
              style="width:29%;height:80%;" />

          </div>
          <div class="col-md-3">
            <input value="ของ" readonly class="title" style="width:13%;" />
            <input v-model="datas.of" readonly type="text" class="content" name="name" style="width:87%;height:80%;" />
          </div>
          <div class="col-md-3">
            <input value="ตั้งแต่วันที่" readonly class="title" style="width:27%;" />
            <input v-model="datas.from_dates" readonly type="text" class="content" name="name"
              style="width:73%;height:80%;" />
          </div>
          <div class="col-md-3">
            <input value="เดือน" readonly class="title" style="width:16%;" />
            <input v-model="datas.from_month" readonly type="text" class="content" name="name"
              style="width:84%;height:80%;" />
          </div>
          <div class="col-md-3">
            <input value="พ.ศ." readonly class="title" style="width:13%;" />
            <input v-model="datas.to_year" readonly type="text" class="content" name="name"
              style="width:83%;height:80%;" />
          </div>
          <div class="col-md-3">
            <input value="เวลา" readonly class="title" style="width:15%;" />
            <input v-model="datas.from_time" readonly type="text" class="content" name="name" 
              style="width:85%;height:80%;" />
          </div>
          <div class="col-md-3">
            <input value="ถึงวันที่" readonly class="title" style="width:18%;" />
            <input v-model="datas.to_dates" readonly type="text" class="content" name="name"
              style="width:82%;height:80%;" />
          </div>
          <div class="col-md-3">
            <input value="เดือน" readonly class="title" style="width:17%;" />
            <input v-model="datas.to_month" readonly type="text" class="content" name="name"
              style="width:83%;height:80%;" />
          </div>
          <div class="col-md-3">
            <input value="พ.ศ." readonly class="title" style="width:13%;" />
            <input v-model="datas.to_year" readonly type="text" class="content" name="name"
              style="width:83%;height:80%;" />
          </div>
          <div class="col-md-2">
            <input value="เวลา" readonly class="title" style="width:23%;" />
            <input v-model="datas.to_time" readonly type="text" class="content" name="name"
              style="width:77%;height:80%;" />
          </div>
          <div class="col-md-5">
            <input value="โดยมีต้นทางที่" readonly class="title" style="width:22%;" />
            <input v-model="datas.origin" readonly type="text" class="content" name="name"
              style="width:78%;height:80%;" />
          </div>
          <div class="col-md-5">
            <input value="ผ่านจังหวัด" readonly class="title" style="width:18%;" />
            <input v-model="datas.stop" readonly type="text" class="content" name="name" style="width:80%;height:80%;" />
          </div>
          <div class="col-md-4">
            <input value="สิ้นสุดปลายทางที่" readonly class="title" style="width:34%;" />
            <input v-model="datas.destination" readonly type="text" class="content" name="name"
              style="width:66%;height:80%;" />
          </div>
          <div class="col-md-8">
            <input value="และกลับจากปลายทางโดยผ่านจังหวัด" readonly class="title" style="width:35%;" />
            <input v-model="datas.stop_through" readonly type="text" class="content" name="name"
              style="width:64%;height:80%;" />
          </div>
          <div class="col-md-12" style="margin-left:60px;"><input
              value="ข้าพเจ้าขอรับรองว่าเมื่อนำรถโดยสารประจำทางหรือรถขนาดเล็กจำนวนดังกล่าวข้างต้นไปใช้ผิดประเภทหรือใช้ทำการขนส่งนอกเส้นทางแล้ว จะมีรถเหลืออยู่"
              readonly class="title" style="width:100%;" />
          </div>
          <div class="col-md-12 mb-3"><input
              value="ไม่น้อยกว่าจำนวนรถขั้นต่ำตามเงื่อนไขที่กำหนดไว้ในใบอนุญาตประกอบการขนส่ง" readonly class="title"
              style="width:100%;" />
          </div>
        </div>
      </div>   <div class="row mb-5">
       
      </div>
      <div class="row">
        <ImportDocs />
      </div>
      <div class="col-md-12 text-center">
          &nbsp;
          <!-- <button
        v-if="currentUser.role_id == 1"
          class="btn btn-secondary"
          style="color: white; width: 200px"
          @click="importdoc()"          
        >
          แนบเอกสาร
        </button>
        &nbsp; -->
        <!-- <button class="btn btn-warning" style="color: white; width: 200px"
            @click="edit()">
            แก้ไขคำขออนุญาต
          </button> -->
          <button v-if="currentUser.role_id == 1 && datas.status_id < 4" class="btn btn-warning mb-3" style="color: white; width: 200px"
            @click="edit()">
            แก้ไขคำขออนุญาต
          </button>
          &nbsp;
          <!-- <button
          class="btn btn-info"
          style="color: white; width: 200px"
          @click="exportToPDF()"
        >
          ดาวน์โหลดไฟล์
        </button> -->
        </div>
      <div class="row" v-if="(datas.status_id > 1 && currentUser.role_id == 1 || currentUser.role_id == 2 )">
        <SendtoOperator />
      </div>
      <div class="row" v-if="(datas.status_id == null || datas.status_id > 0) && currentUser.role_id == 2 || currentUser.role_id == 3">
        <Sendtostationmaster />
      </div>
      <div class="row" v-if="datas.status_id >= 3 && currentUser.role_id == 3 || currentUser.role_id == 4">
        
        <SendRegistrar />
      </div>
      <div class="row"  v-if="datas.status_id && datas.status_id >= 4 && currentUser.role_id == 4">
        <Sentoperatorfromstation />
      
      </div>
      <div class="row"  v-if="datas.status_id == 5 && currentUser.role_id == 2">
        <ImportApprove />
      
      </div>
      <div class="row mb-3"
        v-if="currentUser.role_id == 2 && datas.status_id == 5 && datas.consideration_registrar_status == 1 && datas.file != null">
        <a :href="datas.file" target="_blank"> <button class="btn btn-info" style="color: white; width: 200px">
          <i class="fa fa-print" aria-hidden="true"></i> โหลด/พิมพ์หนังสืออนุญาตฯ
        </button></a>
        &nbsp;&nbsp;
        <button class="btn btn-success" style="color: white; width: 300px" @click="sendemail()">
          <i class="fa fa-paper-plane" aria-hidden="true"></i> จัดส่งหนังสืออนุญาตให้ผู้ประกอบการ
        </button>
      </div>

    </div>
    <form ref="form" @submit.prevent="sendEmail">
              <div class="card-body">
                <div class="form-group mb-3" style="display:none;">
                  <label for="username">อีเมล<span style="color: red">*</span>  ใช้ Gmail เท่านั้น</label>
                  <input
                  name="to_email"
                    v-model="datas.email"
                    type="email"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                </div>
                <div class="form-group mb-3" style="display:none;">
                  <label for="username">form_name</label>
                  <input
                  v-model="datas.from_name"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    name="from_name"
                  />
                </div>
            <div class="form-group mb-3 mt-3" style="display:none;">
                  <label for="username">ข้อความจากนายทะเบียน</label>
                  <textarea name="message" id="message" type="text" rows="3" style="display:none"
                  class="form-control form-control-sm"></textarea>
                  <textarea v-model="datas.file" type="text" rows="3"
                  class="form-control form-control-sm"></textarea>
                </div>
              </div>
            </form>
  </div>
</template>

<script src="https://api-owb.visdchiangrai.com/picker_date.js"></script>

<script>
import emailjs from 'emailjs-com'; 
import PermissionDocService from '../services/PermissionDocService.js'
import ImportDocs from './ImportDocs.vue'
import SendtoOperator from './SendtoOperator.vue'
import Sendtostationmaster from './Sendtostationmaster.vue'
import SendRegistrar from './SendRegistrar.vue'
import Sentoperatorfromstation from './Sentoperatorfromstation.vue'
import axios from 'axios';
import LinkImageService from '../services/LinkImageService'
import ImportApprove from './ImportApprove.vue'
import UserService from '../services/UserService'

export default {
  name: "Cars",
  props: {
    msg: String,
  },
  components: {
    ImportDocs,
    SendtoOperator,
    Sendtostationmaster,
    SendRegistrar,
    Sentoperatorfromstation,
    ImportApprove
  },
  data() {
    return {
      brands: [],
      characteristics: [],
      symbols: [],
      car_types: [],
      datas: [],
      car_id: 0,
      image: "",
      itemPreviewImage: "",
      symbol: [],
      statusedit: false,
      docs:[],
      station_masters:[],
      url:''
    };
  },
  methods: {
    sendEmail() {
        emailjs.sendForm('service_ziurs0r', 'template_7bfnzks',this.$refs.form,
        'v6IRDEPdvfhe_ir4C').then((result) => {
            console.log('SUCCESS!', result.text);
            alert('จัดส่งหนังสืออนุญาตให้ผู้ประกอบการเรียบร้อยแล้ว')
        }, (error) => {
            console.log('FAILED...', error.text);
        }
        )
    },
    sendemail(){
      document.getElementById("message").value = 'เจ้าหน้าที่ออกหนังสืออนุญาตคำขอที่ ' +this.datas.request_at+ ' เรียบร้อยแล้ว '+LinkImageService.getLinkFrontend()+'/permissiondocedit/'+this.car_id
      this.sendEmail()
      UserService.getUser(this.datas.created_by).then(async (res)=>{
      // console.log(res.data);
      if (res.data.line_token != null) {
        await LinkImageService.sendNotify('เจ้าหน้าที่ออกหนังสืออนุญาตคำขอที่ ' +this.datas.request_at+ ' เรียบร้อยแล้ว '+LinkImageService.getLinkFrontend()+'/permissiondocedit/'+this.car_id,res.data.line_token) 
      }
    })
    },
    adddoc(){
      var commenttooperator = {
        officer_id:this.currentUser.id,
        office_to_operator:this.datas.office_to_operator,
        status_id:2,
        update_by:this.currentUser.id
      }
      PermissionDocService.updatecommenttooperator(this.car_id,commenttooperator).then((res)=>{
        alert('แจ้งอีเมลไปยังผู้ประกอบการเพื่ออัพโหลดเอกสารเพิ่มเติมเรียบร้อยแล้ว')
      })
      
    },
    sendtostationmaster(){
      var commenttooperator = {
        station_master_id:3,
        officer_comment:this.datas.officer_comment,
        status_id:3,
        update_by:this.currentUser.id,
        officer_id:this.currentUser.id,
      }
      PermissionDocService.sendtostationmaster(this.car_id,commenttooperator).then((res)=>{
        alert('แจ้งอีเมลไปยังหัวหน้ากลุ่มเรียบร้อยแล้ว')
      })
    },
    sendtoregistrar(){
      var commenttoregistrar = {
        registrar_id:4,
        consideration_station_master_status:this.datas.consideration_station_master_status,
        station_master_comment:this.datas.station_master_comment,
        status_id:4,
        update_by:this.currentUser.id
      }
      PermissionDocService.sendtoregistrar(this.car_id,commenttoregistrar).then((res)=>{
        
        if (this.datas.consideration_station_master_status == true) {
          alert('แจ้งอีเมลไปยังนายทะเบียนเรียบร้อยแล้ว')
        }else{
          alert('บันทึกสำเร็จ')
        }
      })
    },
    senttooperator(){
      
      var senttooperator = {
        consideration_registrar_status:this.datas.consideration_registrar_status,
        status_id:5,
        update_by:this.currentUser.id
      }
      PermissionDocService.senttooperator(this.car_id,senttooperator).then((res)=>{
        
        if (this.datas.consideration_registrar_status == true) {
          alert('แจ้งอัเมลไปยังเจ้าหน้าที่เรียบร้อยแล้ว')
        }else{
          alert('บันทึกสำเร็จ')
        }
      })
    },
    importdoc(){
      this.$router.push("/importdocs/" + this.car_id);
    },
    exportToPDF () {
      this.$router.push("/PrintPermissionDoc/" + this.car_id);
      // this.downloadstatus = true
			// 	html2pdf(this.$refs.document, {
			// 		margin: [1,1.5,0,1.2],
			// 		filename: 'คำขออนุญาตให้ใช้รถผิดประเภท.pdf',
			// 		jsPDF: { unit: 'cm', format: 'a4', orientation: 'p' },
      //     pagebreak: {after: 'section'}
			// 	})
			},
    edit(){
      this.$router.push('/permissiondoc/' + this.car_id);
    },
    getdata(){
      PermissionDocService.getpermission_doc(this.car_id).then((res)=>{
        console.log(res.data);
        this.datas = res.data;
        this.datas.request_date = this.getdateformat(this.datas.request_date)
        if (!this.datas.license_no1_2) {
          this.datas.license_no1_2 = ''
        }
        if (!this.datas.province_license_no1_text) {
          this.datas.province_license_no1_text = ''
        }
        if (!this.datas.license_no2_2) {
          this.datas.license_no2_2 = ''
        }
        if (!this.datas.province_license_no2_text) {
          this.datas.province_license_no2_text = ''
        }
        if (!this.datas.license_no3_2) {
          this.datas.license_no3_2 = ''
        }
        if (!this.datas.province_license_no3_text) {
          this.datas.province_license_no3_text = ''
        }
        if (!this.datas.license_no1) {
          this.datas.license_no1 = ''
        }
        if (!this.datas.license_no2) {
          this.datas.license_no2 = ''
        }
        if (!this.datas.license_no3) {
          this.datas.license_no3 = ''
        }
        this.datas.license_no1_text = this.datas.license_no1 +" "+ this.datas.license_no1_2+" "+ this.datas.province_license_no1_text
        this.datas.license_no2_text = this.datas.license_no2 +" "+ this.datas.license_no2_2+" "+ this.datas.province_license_no2_text
        this.datas.license_no3_text = this.datas.license_no3 +" "+ this.datas.license_no3_2+" "+ this.datas.province_license_no3_text

        var dd = String(this.datas.request_date)
        if (dd == "Invalid Date") {
          this.datas.request_date = ''
        }
// console.log(this.datas.request_date);
        
        this.datas.write_dates = this.getdatesplit(this.datas.write_date)
        this.datas.write_month = this.getmonthsplit(this.datas.write_date)
        this.datas.write_year = this.getyearsplit(this.datas.write_date)
        this.datas.write_year = this.datas.write_year.replace("พ.ศ.",'')
        this.datas.from_dates = this.getdatesplit(this.datas.from_date)
        this.datas.from_month = this.getmonthsplit(this.datas.from_date)
        this.datas.from_year = this.getyearsplit(this.datas.from_date)
        this.datas.from_year = this.datas.from_year.replace("พ.ศ.",'')
        this.datas.from_time = this.datas.from_time
        this.datas.to_dates = this.getdatesplit(this.datas.to_date)
        this.datas.to_month = this.getmonthsplit(this.datas.to_date)
        this.datas.to_year = this.getyearsplit(this.datas.to_date)
        this.datas.to_year = this.datas.to_year.replace("พ.ศ.",'')
        this.datas.to_time = this.datas.to_time
        var name = ''
        if (this.datas.request_at) {
          
         name = this.datas.request_at.replace('/','-')
        }else{
          name =''
        }
    this.url = LinkImageService.getLink()+'/uploads/permissiondocs/หนังสืออนุญาต'+name+'.pdf'
    this.datas.from_name="หนังสืออนุญาต"
        // console.log(this.datas);
      })
    },
    converttime(time){
      var t= ''
      if (time) {
         t = time.split(":")
      t = t[0]+'.'+t[1] +' น.'
      }else{
        t =''
      }
      
      return t
    },
    getdateformat(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
})
// console.log(result);
return result
    },
    getdatesplit(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  day: 'numeric',
})
// console.log(result);
return result
    },
    getmonthsplit(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  month: 'short',
})
// console.log(result);
return result
    },
    getyearsplit(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  year: 'numeric',
})
// console.log(result);
return result
    },
     test() {
      axios({
    method: 'post',
    url: 'https://notify-api.line.me/api/notify',
    headers: {
      'Authorization': 'Bearer ' + 'nRKWjr5P0EZS4e4qU0rGpYo27QQOpOYexwkA9BFglyW',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*'
    },
    data: JSON.stringify({
      message: 'something you would like to push',
    })
  })
.then( function(res) {
  console.log(res.data);
})
.catch( function(err) {
  console.error(err);
});

}
  },
   mounted() {
    if (!this.currentUser) {
     this.$router.push('/login') 
    }
    this.car_id = this.$route.params.id;
    // console.log(this.car_id);
    if (this.car_id != 0) {
      this.getdata()
    } else {
      this.statusedit = true;
      this.datas.status = 1;
    }


  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  font-size: 14px;
  font-weight: bold;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

span {
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
  text-align: justify;
}

body {
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  font-size: 14px;
  color: black;
}

div.aa {
  text-align: justify;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

p {
  text-indent: 25px;
  text-align: justify;
  margin-bottom: 0px;
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

div {
  font-family: 'Niramit';
  font-size: 14px;
}

.title {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.content {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom-style: dotted;
  border-bottom: 1.6px dotted;
}

input {
  margin-bottom: 5px;
}
</style>
