<template>
  <div class="container mt-3" >
    <form ref="form" @submit.prevent="sendEmail">
              <div class="card-body">

                <div class="form-group mb-3" style="display:none;">
                  <label for="username">อีเมล<span style="color: red">*</span>  ใช้ Gmail เท่านั้น</label>
                  <input
                  name="to_email"
                    v-model="datas.email"
                    type="email"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                </div>
                <div class="form-group mb-3" style="display:none;">
                  <label for="username">form_name</label>
                  <input
                  v-model="datas.from_name"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    name="from_name"
                  />
                </div>
              </div>
            </form>
    <div class="card mt-3 mb-5" style="padding-left: 1.5cm; background-color: white; padding-right: 0.5cm">
      <div style="margin-top: 15px; background-color: white">
        <div class="mt-5">
          <span>
            คำขอที่&nbsp;&nbsp;<input disabled v-model="request_at" type="text" />&nbsp;&nbsp;&nbsp;&nbsp; <br />
            <!-- รับวันที่&nbsp;&nbsp;
            <input id="request_date" /><br />
            ผู้รับ&nbsp;&nbsp;<input v-model="datas.request_receiver" type="text" /> -->
          </span>
          <div style="text-align: center;background-color: white; margin: 0px" class="mb-3">
            <label style="text-align: center; font-size: 20px;">คำขออนุญาตให้ใช้รถผิดประเภท</label><br />
            <label style="text-align: center; font-size: 20px;">และใช้รถทำการขนส่งนอกเส้นทางหรือนอกท้องที่</label>
          </div>
          <div style="text-align: right;background-color: white; margin: 0px" class="mb-3">
            เขียนที่&nbsp;&nbsp;<input v-model="datas.write_at" type="text" /><br />
            วันที่&nbsp;&nbsp;
            <input id="write_date" /><br />
          </div>
          <span> <label style="text-align: center">เรียน นายทะเบียน</label><br /></span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span>
            ด้วยข้าพเจ้า (นาย, นาง, นางสาว)&nbsp;&nbsp;<input v-model="datas.name" style="width: 500px"
              type="text" /><br />
          </span>
          <span>
            ซึ่งมีอำนาจลงนามผูกพัน&nbsp;&nbsp;<input style="width: 350px" v-model="datas.relate"
              type="text" />&nbsp;&nbsp;&nbsp; สำนักงานตั้งอยู่ที่&nbsp;&nbsp;<input v-model="datas.address"
              type="text" />&nbsp;&nbsp;&nbsp;&nbsp;หมู่ที่&nbsp;&nbsp;<input v-model="datas.moo" style="width: 90px"
              type="number" /><br />
          </span>
          <span>
            ซอย&nbsp;&nbsp;<input v-model="datas.soi" type="text" />&nbsp;&nbsp;&nbsp;ถนน&nbsp;&nbsp;<input
              v-model="datas.road" type="text" />&nbsp;&nbsp;&nbsp;
            จังหวัด&nbsp;&nbsp;
            <select style="width:17%" @change="onChangeProvince" v-model="datas.province_id">
              <option v-for="(l, i) in provinces" :key="i" :value="l.id"> {{ l.name_th }}</option>
            </select>
            &nbsp;&nbsp;&nbsp;
            เขต/อำเภอ&nbsp;&nbsp;
            <select style="width:17%" @change="onChangeAmphur" v-model="datas.amphur_id">
              <option v-for="(a, i) in amphurs" :key="i" :value="a.id"> {{ a.name_th }}</option>
            </select>

          </span><br />
          <span>
            ตำบล&nbsp;&nbsp;
            <select style="width:17%" @change="onChangeDistrict" v-model="datas.tambon_id">
              <option v-for="(d, i) in districts" :key="i" :value="d.id"> {{ d.name_th }}</option>
            </select>
            &nbsp;&nbsp;&nbsp; โทรศัพท์&nbsp;&nbsp;<input v-model="datas.phone" type="text" />&nbsp;&nbsp;&nbsp;
            <label>ได้รับใบอนุญาตประกอบการขนส่งประเภท</label>&nbsp;&nbsp;
            <select style="width:17%" v-model="datas.type_id">
              <option v-for="(l, i) in licensetypes" :key="i" :value="l.id"> {{ l.name }}</option>
            </select>
          </span><br />
          <span>
            เลขที่&nbsp;&nbsp;<input v-model="datas.number"
              type="text" />&nbsp;&nbsp;&nbsp; หมายเลขเส้นทาง&nbsp;&nbsp;<input v-model="datas.no_route"
              type="text" />&nbsp;&nbsp;&nbsp; หมายเลขประจำรถ&nbsp;&nbsp;<input v-model="datas.no_car"
              type="text" />&nbsp;&nbsp;&nbsp;<br />มีความประสงค์ที่จะขอใช้รถดังต่อไปนี้
          </span><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span>
            ๑. เลขทะเบียน&nbsp;&nbsp;<input v-model="datas.license_no1" style="width: 100px" type="text" />&nbsp;&nbsp;<input v-model="datas.license_no1_2" style="width: 130px" type="text" />&nbsp;&nbsp; 
            <select style="width:17%" v-model="datas.province_license_no1">
              <option v-for="(l, i) in provinces" :key="i" :value="l.id"> {{ l.name_th }}</option>
            </select><br />
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span>
            ๒. เลขทะเบียน&nbsp;&nbsp;<input v-model="datas.license_no2" style="width: 100px" type="text" />&nbsp;&nbsp;<input v-model="datas.license_no2_2" style="width: 130px" type="text" />&nbsp;&nbsp; 
            <select style="width:17%" v-model="datas.province_license_no2">
              <option v-for="(l, i) in provinces" :key="i" :value="l.id"> {{ l.name_th }}</option>
            </select><br />
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span>
            ๓. เลขทะเบียน&nbsp;&nbsp;<input v-model="datas.license_no3" style="width: 100px" type="text" />&nbsp;&nbsp;<input v-model="datas.license_no3_2" style="width: 130px" type="text" />&nbsp;&nbsp; 
            <select style="width:17%" v-model="datas.province_license_no3">
              <option v-for="(l, i) in provinces" :key="i" :value="l.id"> {{ l.name_th }}</option>
            </select><br />
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span>
            (ในกรณีที่เป็นรถประจำทางหรือรถขนาดเล็กให้ระบุหมายเลขเส้นทางและหมายเลขประจำรถด้วย)&nbsp;&nbsp;<br />
          </span>
          <span>
            ทำการขนส่ง&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <input class="form-check-input" type="checkbox" value="wrongtype" id="wrongtype" v-model="datas.wrongtype">
            <label class="form-check-label" for="wrongtype">
              &nbsp;&nbsp; ผิดไปจากประเภทที่จดทะเบียนไว้
            </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <input class="form-check-input" type="checkbox" value="offtrack" id="offtrack" v-model="datas.offtrack">
            <label class="form-check-label" for="offtrack">
              &nbsp;&nbsp;นอกเส้นทาง
            </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input class="form-check-input" type="checkbox" value="outside" id="outside" v-model="datas.outside">
            <label class="form-check-label" for="outside">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; นอกท้องที่
            </label><br />
          </span>
          <span>
            เพื่อใช้ในกิจการ&nbsp;&nbsp;<input v-model="datas.business"
              type="text" />&nbsp;&nbsp;&nbsp;ของ&nbsp;&nbsp;<input v-model="datas.of"
              type="text" />&nbsp;&nbsp;&nbsp;ตั้งแต่วันที่&nbsp;&nbsp;
            <input id="from_date" />
            &nbsp;&nbsp;&nbsp;เวลา&nbsp;&nbsp;
            <input v-model="datas.from_time" type="text" /><br />
          </span>
          <span>
            ถึงวันที่&nbsp;&nbsp;
            <input id="to_date" />&nbsp;&nbsp;&nbsp;เวลา&nbsp;&nbsp;
            <input style="width: 75px" v-model="datas.to_time"
              type="text" />&nbsp;&nbsp;&nbsp;โดยมีต้นทางที่&nbsp;&nbsp;<input v-model="datas.origin" type="text" />
            &nbsp;&nbsp;&nbsp;ผ่านจังหวัด&nbsp;&nbsp;<input style="width: 250px" v-model="datas.stop" type="text" /><br />
          </span>
          <span>
            สิ้นสุดปลายทางที่&nbsp;&nbsp;<input v-model="datas.destination"
              type="text" />&nbsp;&nbsp;&nbsp;และกลับจากปลายทางโดยผ่านจังหวัด&nbsp;&nbsp;<input style="width: 300px"
              v-model="datas.stop_through" type="text" /><br />
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span>
            ข้าพเจ้าขอรับรองว่าเมื่อนำรถโดยสารประจำทางหรือรถขนาดเล็กจำนวนดังกล่าวข้างต้นไปใช้ผิดประเภทหรือใช้ทำการขนส่งนอกเส้นทางแล้ว
            จะมีรถเหลืออยู่ไม่น้อยกว่าจำนวนรถขั้นต่ำตามเงื่อนไขที่กำหนดไว้ในใบอนุญาตประกอบการขนส่ง&nbsp;&nbsp;<br />
          </span>
          <span>
            <br /> 
          </span>
        </div>
        <!-- <div class="row mt-5" style="text-align: center; font-size: 14px">
          <div class="col-sm-6">
            <span style="text-align: center"
              >ลงชื่อ..............................................................................ผู้รับเดินรถร่วม</span
            >
            <br />
            <span
              >(&nbsp;&nbsp;<input v-model="datas.signone" style="width:220px" type="text" />&nbsp;&nbsp;)</span
            >
          </div>
          <div class="col-sm-6">
            <span style="text-align: center"
              >ลงชื่อ..................................................................ผู้เดินรถร่วม</span
            >
            <br />
            <span
              >(&nbsp;&nbsp;<input v-model="datas.signtwo" style="width:220px" type="text" />&nbsp;&nbsp;)</span
            >
          </div>
          <div class="col-sm-6 mt-3">
            <span style="text-align: center"
              >ลงชื่อ.............................................................................พยาน</span
            >
            <br />
            <span
              >(&nbsp;&nbsp;<input v-model="datas.signthree" style="width:220px" type="text" />&nbsp;&nbsp;)</span
            >
          </div>
          <div class="col-sm-6 mt-3">
            <span style="text-align: center"
              >ลงชื่อ.......................................................................พยาน</span
            >
            <br />
            <span
              >(&nbsp;&nbsp;<input v-model="datas.signfour" style="width:220px" type="text" />&nbsp;&nbsp;)</span
            >
          </div>
        </div> -->
      </div>
      <section></section>
      <div>
      </div>
    </div>
    <!-- <div id="app" ref="document" v-else>
      <PermissionDocEdit />
	</div> -->
  <div
      class="card mb-5"
      style="padding-left: 1.5cm; background-color: white; padding-right: 0.5cm"
    >    
    <div>
          <div class="form-group col-md-12 mt-3">
        <label for="exampleFormControlInput1" v-if="currentUser.role_id == 1">แนบไฟล์เอกสาร</label>
        <label for="exampleFormControlInput1" v-if="currentUser.role_id == 2">เอกสาร</label>
         <table class="table mt-3">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">เอกสาร</th>
              <th scope="col">ไฟล์</th>
              <th scope="col" v-if="currentUser.role_id == 1"></th>
            </tr>
          </thead>
          <tbody v-if="docs.length > 0">
            <tr v-for="(dd, i) in docs" :key="i + 1">
              <td><span v-if="dd.name_file != null"><i class="fa fa-check" style="color:green" aria-hidden="true"></i></span>
                 <span v-else><i class="fa fa-times" style="color:red" aria-hidden="true"></i></span> </td>
              <td> <a target="_blank">{{ dd.name }}</a></td>
              <td>
                <a :href="dd.path" target="_blank">{{ dd.name_file }}</a></td>
              <td v-if="currentUser.role_id == 1"> 
                <input type="button" class="btn btn-info btn-sm" id="get_file" value="อัพโหลด" @click="upload(dd.id)" v-if="datas.status_id != 5">
<input type="file" id="my_file" @change="onFileChange" accept="application/pdf,image/png, image/gif, image/jpeg">
               &nbsp;
               <button v-if="dd.name_file != null"
                type="button"
                class="btn btn-danger btn-sm"
                @click="updatestatus(dd.id)"
              >
                <i class="fa fa-trash"></i></button
            >
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <div class="col-md-6 text-left">
                   <button
                    @click="savedoc()"
                    type="button"
                    class="btn btn-success btn-sm"
                  >
                  บันทึกข้อมูล
                  </button></div> -->
      </div>
    </div>
    <section></section>
    <div>
    </div>
    </div>
    <div class="row mt-3 mb-5">
      <div class="col-md-12 text-center">
        &nbsp;
        <button class="btn btn-success" style="color: white; width: 200px" @click="save()">
          บันทึก
        </button>
      </div>
    </div>
    <div v-if="statuspdf">
    <div style="padding: 0px;" ref="document" id="my_invoice">
      <div class="row">
        <div class="col-md-6"><input value="คำขอที่" readonly class="title" style="width:15%;" /><input
            v-model="datas.request_at" readonly type="text" class="content" name="name" style="width:30%;" /></div>
      </div>
      <!-- <div class="row">
        <div class="col-md-6"><input value="รับวันที่" readonly class="title" style="width:15%;" /><input
            v-model="datas.request_dates" readonly type="text" class="content" name="name" style="width:30%;" /></div>
      </div>
      <div class="row">
        <div class="col-md-6"><input value="ผู้รับ" readonly class="title" style="width:15%;" /><input
            v-model="datas.request_receiver" readonly type="text" class="content" name="name" style="width:30%;" /></div>
      </div> -->
      <div style="text-align: center; background-color: white; margin: 0px;" class="mb-3">
        <label style="text-align: center; font-size: 18px">คำขออนุญาตให้ใช้รถผิดประเภท</label><br />
        <label style="text-align: center; font-size: 18px">และใช้รถทำการขนส่งนอกเส้นทางหรือนอกท้องที่</label>
      </div>
      <div class="row">
        <div class="col-md-8" style="text-align:right"></div>
        <div class="col-md-4"><input value="เขียนที่" readonly class="title" style="width:20%;" /><input
            v-model="datas.write_at" readonly type="text" class="content" name="name" style="width:80%;" /></div>
      </div>
      <div class="row">
        <div class="col-md-7" style="text-align:right"></div>
        <div class="col-md-5"><input value="วันที่" readonly class="title" style="width:12%;" /><input
            v-model="datas.write_dates" readonly type="text" class="content" name="name" style="width:15%;" />
          <input value="เดือน" readonly class="title" style="width:15%;" /><input v-model="datas.write_month" readonly
            type="text" class="content" name="name" style="width:25%;" />
          <input value="พ.ศ." readonly class="title" style="width:11%;" /><input v-model="datas.write_year" readonly
            type="text" class="content" name="name" style="width:22%;" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <input value="เรียน นายทะเบียน" readonly class="title" style="width:100%;" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" style="margin-left:60px;"><input value="ด้วยข้าพเจ้า (นาย, นาง, นางสาว)" readonly
            class="title" style="width:30%;" /><input v-model="datas.name" readonly type="text" class="content"
            name="name" style="width:60%;" /></div>

        <div class="col-md-5"><input value="ซึ่งมีอำนาจลงนามผูกพัน" readonly class="title" style="width:55%;" /> <input
            v-model="datas.relate" readonly type="text" class="content" name="name" style="width:40%;" /></div>
        <div class="col-md-4"><input value="สำนักงานตั้งอยู่ที่" readonly class="title" style="width:50%;height:90%;" />
          <input v-model="datas.address" readonly type="text" class="content" name="name" style="width:50%;" />
        </div>
        <div class="col-md-3"><input value="หมู่ที่" readonly class="title" style="width:13%;" /> <input
            v-model="datas.moo" readonly type="text" class="content" name="name" style="width:83%;" /></div>

        <div class="col-md-2"><input value="ซอย" readonly class="title" style="width:30%;" /> <input v-model="datas.soi"
            readonly type="text" class="content" name="name" style="width:50%;" /></div>
        <div class="col-md-2"><input value="ถนน" readonly class="title" style="width:30%;height:90%;" /> <input
            v-model="datas.road" readonly type="text" class="content" name="name" style="width:60%;" /></div>
        <div class="col-md-4"><input value="ตำบล" readonly class="title" style="width:20%;" /> <input
            v-model="datas.tambon_th" readonly type="text" class="content" name="name" style="width:70%;" /></div>
        <div class="col-md-4"><input value="เขต/อำเภอ" readonly class="title" style="width:35%;" /> <input
            v-model="datas.amphur_th" readonly type="text" class="content" name="name" style="width:60%;" /></div>
        <div class="col-md-3"><input value="จังหวัด" readonly class="title" style="width:30%;" /> <input
            v-model="datas.province_th" readonly type="text" class="content" name="name" style="width:60%;height:80%;" />
        </div>
        <div class="col-md-3"><input value="โทรศัพท์" readonly class="title" style="width:35%;" /> <input
            v-model="datas.phone" readonly type="text" class="content" name="name" style="width:60%;height:80%;" />
        </div>
        <div class="col-md-6"><input value="ได้รับใบอนุญาตประกอบการขนส่งประเภท" readonly class="title"
            style="width:80%;" />
          <input v-model="datas.type_name" readonly type="text" class="content" name="name"
            style="width:20%;height:80%;" />
        </div>
        <div class="col-md-2"><input value="เลขที่" readonly class="title" style="width:40%;" />
          <input v-model="datas.number" readonly type="text" class="content" name="name" style="width:60%;height:80%;" />
        </div>
        <div class="col-md-10"><input value="มีความประสงค์ที่จะขอใช้รถดังต่อไปนี้" readonly class="title"
            style="width:70%;" />
        </div>
        <div class="col-md-12" style="margin-left:60px;"><input value="๑. เลขทะเบียน" readonly class="title"
            style="width:15%;" />
          <input v-model="datas.license_no1" readonly type="text" class="content" name="name"
            style="width:30%;height:80%;" />
        </div>
        <div class="col-md-12" style="margin-left:60px;"><input value="๒. เลขทะเบียน" readonly class="title"
            style="width:15%;" />
          <input v-model="datas.license_no2" readonly type="text" class="content" name="name"
            style="width:30%;height:80%;" />
        </div>
        <div class="col-md-12" style="margin-left:60px;"><input value="๓. เลขทะเบียน" readonly class="title"
            style="width:15%;" />
          <input v-model="datas.license_no3" readonly type="text" class="content" name="name"
            style="width:30%;height:80%;" />
        </div>
        <div class="col-md-12" style="margin-left:60px;"><input
            value="(ในกรณีที่เป็นรถประจำทางหรือรถขนาดเล็กให้ระบุหมายเลขเส้นทางและหมายเลขประจำรถด้วย)" readonly
            class="title" style="width:100%;" />
        </div>
        <div style="text-align: justify;" class="col-md-12"><input value="ทำการขนส่ง" readonly class="title"
            style="width:15%;" />
          <input class="form-check-input" readonly type="checkbox" style="width:2%;" value="wrongtype" id="wrongtype"
            v-model="datas.wrongtype">
          <input value="ผิดไปจากประเภทที่จดทะเบียนไว้" readonly class="title" style="width:35%;" />
          <input class="form-check-input" readonly type="checkbox" style="width:2%;" value="offtrack" id="offtrack"
            v-model="datas.offtrack">
          <input value="นอกเส้นทาง" readonly class="title" style="width:15%;" />
          <input class="form-check-input" readonly type="checkbox" style="width:2%;" value="outside" id="outside"
            v-model="datas.outside">
          <input value="นอกท้องที่" readonly class="title" style="width:10%;" />
          <input value="เพื่อใช้ในกิจการ" readonly class="title" style="width:15%;" />
          <input v-model="datas.business" readonly type="text" class="content" name="name"
            style="width:10%;height:80%;" />

        </div>
        <div class="col-md-3">
          <input value="ของ" readonly class="title" style="width:20%;" />
          <input v-model="datas.of" readonly type="text" class="content" name="name" style="width:80%;height:80%;" />
        </div>
        <div class="col-md-3">
          <input value="ตั้งแต่วันที่" readonly class="title" style="width:40%;" />
          <input v-model="datas.from_dates" readonly type="text" class="content" name="name"
            style="width:50%;height:80%;" />
        </div>
        <div class="col-md-3">
          <input value="เดือน" readonly class="title" style="width:25%;" />
          <input v-model="datas.from_month" readonly type="text" class="content" name="name"
            style="width:70%;height:80%;" />
        </div>
        <div class="col-md-3">
          <input value="พ.ศ." readonly class="title" style="width:25%;" />
          <input v-model="datas.from_year" readonly type="text" class="content" name="name" style="width:70%;height:80%;" />
        </div>
        <div class="col-md-3">
          <input value="เวลา" readonly class="title" style="width:20%;" />
          <input v-model="datas.from_times" readonly type="text" class="content" name="name" style="width:80%;height:80%;" />
        </div>
        <div class="col-md-3">
          <input value="ถึงวันที่" readonly class="title" style="width:40%;" />
          <input v-model="datas.to_dates" readonly type="text" class="content" name="name"
            style="width:50%;height:80%;" />
        </div>
        <div class="col-md-3">
          <input value="เดือน" readonly class="title" style="width:25%;" />
          <input v-model="datas.to_month" readonly type="text" class="content" name="name"
            style="width:70%;height:80%;" />
        </div>
        <div class="col-md-3">
          <input value="พ.ศ." readonly class="title" style="width:25%;" />
          <input v-model="datas.to_year" readonly type="text" class="content" name="name" style="width:70%;height:80%;" />
        </div>
        <div class="col-md-2">
          <input value="เวลา" readonly class="title" style="width:35%;" />
          <input v-model="datas.to_times" readonly type="text" class="content" name="name" style="width:60%;height:80%;" />
        </div>
        <div class="col-md-5">
          <input value="โดยมีต้นทางที่" readonly class="title" style="width:32%;" />
          <input v-model="datas.origin" readonly type="text" class="content" name="name" style="width:60%;height:80%;" />
        </div>
        <div class="col-md-5">
          <input value="ผ่านจังหวัด" readonly class="title" style="width:30%;" />
          <input v-model="datas.stop" readonly type="text" class="content" name="name" style="width:65%;height:80%;" />
        </div>
        <div class="col-md-4">
          <input value="สิ้นสุดปลายทางที่" readonly class="title" style="width:50%;" />
          <input v-model="datas.destination" readonly type="text" class="content" name="name"
            style="width:48%;height:80%;" />
        </div>
        <div class="col-md-8">
          <input value="และกลับจากปลายทางโดยผ่านจังหวัด" readonly class="title" style="width:50%;" />
          <input v-model="datas.stop_through" readonly type="text" class="content" name="name"
            style="width:48%;height:80%;" />
        </div>
        <div class="col-md-12" style="margin-left:60px;"><input
            value="ข้าพเจ้าขอรับรองว่าเมื่อนำรถโดยสารประจำทางหรือรถขนาดเล็กจำนวนดังกล่าวข้างต้นไปใช้ผิดประเภท" readonly
            class="title" style="width:100%;" />
        </div>
        <div class="col-md-12"><input
            value="หรือใช้ทำการขนส่งนอกเส้นทางแล้ว จะมีรถเหลืออยู่ ไม่น้อยกว่าจำนวนรถขั้นต่ำตามเงื่อนไขที่กำหนดไว้ในใบอนุญาต"
            readonly class="title" style="width:100%;" />
        </div>
        <div class="col-md-12"><input value="ประกอบการขนส่ง" readonly class="title" style="width:100%;" />
        </div>

      </div>

     
      
      <!-- <div class="row mt-3" style="text-align: center; font-size: 14px">
      <div class="col-sm-6">
        <span style="text-align: center"
          >ลงชื่อ...............................................................ผู้รับเดินรถร่วม</span
        >
        <br />
        <span v-if="datas.signone"
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{datas.signone}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
        <span v-else
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
      </div>
      <div class="col-sm-6">
        <span style="text-align: center"
          >ลงชื่อ..................................................................ผู้เดินรถร่วม</span
        >
        <br />
        <span v-if="datas.signtwo"
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{datas.signtwo}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
        <span v-else
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
      </div>
      <div class="col-sm-6 mt-3">
        <span style="text-align: center"
          >ลงชื่อ.............................................................................พยาน</span
        >
        <br />
        <span v-if="datas.signthree"
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{datas.signthree}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
        <span v-else
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
      </div>
      <div class="col-sm-6 mt-3">
        <span style="text-align: center"
          >ลงชื่อ.......................................................................พยาน</span
        >
        <br />
       <span v-if="datas.signfour"
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{datas.signfour}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
        <span v-else
          >(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</span
        >
      </div>
    </div> -->
    </div>
  </div>
  </div>
</template>

<script src="https://api-owb.visdchiangrai.com/picker_date.js"></script>

<script>
import VueHtml2pdf from "vue-html2pdf";
import ContentToPrint from "./ContentToPrint";

import PermissionDocEdit from "./PermissionDocEdit.vue";
import html2pdf from 'html2pdf.js'
import axios from 'axios';
import PermissionDocService from '../services/PermissionDocService.js'
import ProvinceService from "../services/ProvinceService";
import AmphuresService from "../services/AmphuresService";
import DistrictService from "../services/DistrictService";
import TypeLicenseService from "../services/TypeLicenseService";
import PrintPermissionDoc from "./PrintPermissionDoc.vue";
import LinkImageService from '../services/LinkImageService'
import UserService from '../services//UserService'
import emailjs from 'emailjs-com';
import LogNotifyService from '../services/LogNotifyService'
import ImportDocs from './ImportDocs.vue'
import DocumentService from "../services/DocumentService";
import MapDocService from "../services/MapDocService";

export default {
  name: "Cars",
  props: {
    msg: String,
  },
  components: {
    PermissionDocEdit,
    VueHtml2pdf,
    ContentToPrint,
    PrintPermissionDoc,
    ImportDocs
  },
  data() {
    return {
      brands: [],
      characteristics: [],
      symbols: [],
      car_types: [],
      datas: [],
      car_id: 0,
      image: "",
      itemPreviewImage: "",
      symbol: [],
    editstatus:false,
      tran_id: 0,
      filteredList:[],
      licenses:[],
      license:'',
      docs:[],
      docid:'',
      provinces:[],
      amphurs:[],
      districts:[],
      zipcode:'',
      licensetypes:[],
      list:[],
      statuspdf:false,
      request_at:'',
      doc_id:0,
      docstemp:[],
      docsfailed:[],
    };
  },
  methods: {
    updatestatus(id){
      MapDocService.deletemap_doc_permision(id,this.car_id).then((res)=>{
        this.getdocs()
      });
    },
    async savedoc(){
      var notify ={
          permission_docs_id:this.car_id,
          messages:'ผู้ประกอบการอัพโหลดไฟล์ คำขอที่ '+this.datas.request_at +' ตรวจสอบที่ '+LinkImageService.getLinkFrontend()+'/permissiondocedit/'+this.car_id,
          created_by:this.currentUser.id
}
this.sendEmail(this.datas.office_email)
await LinkImageService.sendNotify('ผู้ประกอบการอัพโหลดไฟล์ คำขอที่ '+this.datas.request_at +' ตรวจสอบที่ '+LinkImageService.getLinkFrontend()+'/permissiondocedit/'+this.car_id,this.datas.office_line_token) 

LogNotifyService.createlog_notify(notify).then(()=>{
  setTimeout(function () {
    location.reload()
            }, 1000);
            alert('แจ้งไปยังเจ้าหน้าที่เรียบร้อยแล้ว')
// console.log(res.data);
})
    },
    getdocs(){
      this.docs = []
      DocumentService.getdocuments().then(async (res)=>{
        // console.log(res.data);
        this.docstemp = res.data
        await MapDocService.getmap_doc_permision(this.car_id).then((docs)=>{
        // console.log(docs.data);
        var doclist = docs.data
        if (doclist.length > 0) {
         for (let d = 0; d < this.docstemp.length; d++) {
          for (let dd = 0; dd < doclist.length; dd++) {
            // console.log(this.docstemp[d].id, doclist[dd].id);
            if (this.docstemp[d].id == doclist[dd].id) {
              this.docstemp[d].path = doclist[dd].path
              this.docstemp[d].name_file = doclist[dd].name_file
            }
          }
         }
        }
        this.docs = this.docstemp
        for (let ff = 0; ff < this.docstemp.length; ff++) {
          if (this.docstemp[ff].name_file == null) {
           this.docsfailed.push({
            id:this.docstemp[ff].id,
            name:this.docstemp[ff].name,
            path :this.docstemp[ff].path,
              name_file :this.docstemp[ff].name_file,
           })
          }
          
        }
      });
      })
// MapDocService.getmap_doc_permision(this.car_id).then((res)=>{
//         console.log(res.data);
//         if (res.data.length == 0) {
         
//         }else{
//           this.docs = res.data
//         }
//       });
    },
    onChangeProvince(){
// console.log(evt.target.value);
this.getamphurs()
    },
    onChangeAmphur(){
// console.log(evt.target.value);
this.getdistricts()
    },
    onChangeDistrict(){
// console.log(evt.target.value);
this.getzipcode()
    },
    getlicensetypes(){
      TypeLicenseService.gettype_licenses('1').then((res)=>{
        this.licensetypes = res.data
      })
    },
    getprovinces(){
      ProvinceService.getprovinces().then((res)=>{
        this.provinces = res.data
        // console.log(res.data);
      })
    },
    getamphurs(){
      AmphuresService.getamphures(this.datas.province_id).then((res)=>{
        this.amphurs = res.data
        this.districts = {}
        this.zipcode = ''
        // console.log(res.data);
      })
    },
    getdistricts(){
      DistrictService.getdistricts(this.datas.amphur_id).then((res)=>{
        this.districts = res.data
        // console.log(res.data);
      })
    },
    getzipcode(){
      DistrictService.getdistrict(this.datas.tambon_id).then((res)=>{
        this.zipcode = res.data.zip_code
        // console.log(res.data);
      })
    },
    upload(id){
      this.doc_id = id
      document.getElementById('my_file').click();
    },
    onFileChange(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.onUploadFile(files[0]);
      // this.createBase64Image(selectedImage);
    },
    onUploadFile(selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      var link = LinkImageService.getLink()
      var http = link + '/uploadimage?type='+this.doc_id+'&&name=' +selectedFile.name;
      var path = link + '/uploads/'+this.doc_id+'/'+selectedFile.name;
      axios
        .post(http, formData)
        .then(() => {    
          MapDocService.deletemap_doc_permision(this.doc_id,this.car_id).then((res=>{
          var file ={
            permission_doc_id:this.car_id,
            document_id:this.doc_id,
            name:selectedFile.name,
            path:path,
            status:1,
            created_by:this.currentUser.id
          }
          MapDocService.createmap_doc_permision(file).then(()=>{
            // console.log(res.data);
           
            this.getdocs()
          })
            
          }))
        
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        this.itemPreviewImage = evt.target.result;
        this.image = evt.target.result;
        this.docs.push({
          id: this.docs.length + 1,
          name : file.name,
          image : evt.target.result,
          width:0,
          height:0
        })
        // console.log(this.docs);
      };

      reader.readAsDataURL(file);
    },
    edit(){
      this.editstatus = true;
      window.scrollTo(0, 0);
    },
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    hasGenerated() {
      alert("PDF generated successfully!");
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    converttime(time){
      var t = time.split(":")
      t = t[0]+'.'+t[1] +' น.'
      return t
    },
    getdateformat(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
})
// console.log(result);
return result
    },
    getdatesplit(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  day: 'numeric',
})
// console.log(result);
return result
    },
    getmonthsplit(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  month: 'short',
})
// console.log(result);
return result
    },
    getyearsplit(date){
      var d = new Date(date)
      // console.log(d);
      const result = d.toLocaleDateString('th-TH', {
  year: 'numeric',
})
// console.log(result);
return result
    },
    createddate(value) {  
      // console.log(value); 
  var streetaddress = String(value);
    var d = streetaddress.substring(0, streetaddress.indexOf("/"));
    var m = streetaddress.substring(5, streetaddress.indexOf("/"));
    m = m.substring(1, 3);
    var y = streetaddress.substring(5);
    var full = m + "/" + d + y;
    // console.log(full);
    var date = new Date(full);
    // console.log(date);
    var day = date.getDate();
    var month = date.getMonth()+1;
    var year = date.getFullYear()-543;
    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    return  year + "-" + month + "-" + day;
  },
  sendEmail(to_email,title) {
//     emailjs.send("service_ziurs0r","template_7bfnzks",{
// header: "test",
// message: "test",
// to_email: "ponnipa.oum@gmail.com",
// });
    var templateParams = {
        to_email: to_email,
        header: 'หนังสืออนุญาตคำขอที่ '+ this.datas.request_at,
    message: title+this.datas.request_at +' ตรวจสอบที่ '+LinkImageService.getLinkFrontend()+'/permissiondocedit/'+this.car_id
  };
//   emailjs.send("service_ziurs0r","template_7bfnzks",{
// header: 'หนังสืออนุญาตคำขอที่ '+ this.datas.request_at,
// message: title+this.datas.request_at +' ตรวจสอบที่ '+LinkImageService.getLinkFrontend()+'/permissiondocedit/'+this.car_id,
// to_email: to_email,
// });
        emailjs.send('service_ziurs0r', 'template_7bfnzks',templateParams,
        'v6IRDEPdvfhe_ir4C').then((result) => {
            console.log('SUCCESS!', result.text);
        }, (error) => {
            console.log('FAILED...', error.text);
        }
        )
    },
    save() {
      var d = new Date()
      var request_at  = ''
      var result = ''
      PermissionDocService.findlast(d.getFullYear()).then((res) => {
        // console.log(res.data);
 result = d.toLocaleDateString('th-TH', {
  year: 'numeric',
})
result= result.replace("พ.ศ.",'')
// console.log(this.list.id);
      request_at = (parseInt(res.data.length)+1)+'/'+result
      request_at = request_at.replace(" ",'')
      if (this.datas.request_at == null) {
        this.datas.request_at = request_at
      }
      this.statuspdf = true
      // console.log(this.datas.request_at);

      this.statuspdf = true
      // console.log(document.getElementById("request_date").value);
            this.exportToPDF()
      //        if (document.getElementById("request_date").value == '') {
      //   alert("กรุณากรอกรับวันที่");
      // } 
      // else if (this.datas.request_receiver == null) {
      //   alert("กรุณากรอกผู้รับ");
      // }
       if (this.datas.write_at == null) {
        alert("กรุณากรอกเขียนที่");
      } else if (document.getElementById("write_date").value == null) {
        alert("กรุณากรอกวันที่");
      } else if (this.datas.name == null) {
        alert("กรุณากรอกชื่อ");
      }
      //  else if (this.datas.relate == null) {
      //   alert("กรุณากรอกลงนาม");
      // }
       else if (this.datas.address == null) {
        alert("กรุณากรอกที่อยู่");
      } else if (this.datas.tambon_id == null) {
        alert("กรุณากรอกตำบล");
      } else if (this.datas.amphur_id == null) {
        alert("กรุณากรอกอำเภอ");
      } else if (this.datas.province_id == null) {
        alert("กรุณากรอกจังหวัด");
      } else if (this.datas.phone == null) {
        alert("กรุณากรอกเบอร์โทรศัพท์");
      } else if (this.datas.license_no1 == null) {
        alert("กรุณากรอกเลขทะเบียน");
      } else if (this.datas.business == null) {
        alert("กรุณากรอกกิจการ");
      } else if (this.datas.of == null) {
        alert("กรุณากรอกเจ้่าของกิจการ");
      } else if (document.getElementById("from_date").value == '') {
        alert("กรุณากรอกตั้งแต่วันที่");
      } else if (this.datas.from_time == null) {
        alert("กรุณากรอกถึงเวลา");
      } else if (document.getElementById("from_date").value == '') {
        alert("กรุณากรอกตั้งแต่วันที่");
      } else if (this.datas.to_time == null) {
        alert("กรุณากรอกถึงเวลา");
      } else if (this.datas.origin == null) {
        alert("กรุณากรอกต้นทาง");
      } else if (this.datas.stop == null) {
        alert("กรุณากรอกผ่านจังหวัด");
      } else if (this.datas.destination == null) {
        alert("กรุณากรอกปลายทาง");
      } else if (this.datas.stop_through == null) {
        alert("กรุณากรอกจังหวัดปลายทางที่กลับ");
      } else {
        // this.datas.request_dates = this.getdateformat(this.createddate(document.getElementById("request_date").value))
        this.datas.write_dates = this.getdatesplit(this.createddate(document.getElementById("write_date").value))
        this.datas.write_month = this.getmonthsplit(this.createddate(document.getElementById("write_date").value))
        this.datas.write_year = this.getyearsplit(this.createddate(document.getElementById("write_date").value))
        this.datas.write_year = this.datas.write_year.replace("พ.ศ.",'')
        this.datas.from_dates = this.getdatesplit(this.createddate(document.getElementById("from_date").value))
        this.datas.from_month = this.getmonthsplit(this.createddate(document.getElementById("from_date").value))
        this.datas.from_year = this.getyearsplit(this.createddate(document.getElementById("from_date").value))
        this.datas.from_year = this.datas.from_year.replace("พ.ศ.",'')
        this.datas.from_times = this.datas.from_time
        this.datas.to_dates = this.getdatesplit(this.createddate(document.getElementById("to_date").value))
        this.datas.to_month = this.getmonthsplit(this.datas.to_date)
        this.datas.to_year = this.getyearsplit(this.createddate(document.getElementById("to_date").value))
        this.datas.to_year = this.datas.to_year.replace("พ.ศ.",'')
        this.datas.to_times = this.datas.to_time
        var trans = {
          // car_owner_id: this.car_id,
          request_at:this.datas.request_at,
          // request_date: this.createddate(document.getElementById("request_date").value),
          // request_receiver: this.datas.request_receiver,
          write_at: this.datas.write_at,
          write_date: this.createddate(document.getElementById("write_date").value),
          name: this.datas.name,
          relate: this.datas.relate,
          address: this.datas.address,
          moo: this.datas.moo,
          soi: this.datas.soi,
          road: this.datas.road,
          tambon_id: this.datas.tambon_id,
          amphur_id: this.datas.amphur_id,
          province_id: this.datas.province_id,
          phone: this.datas.phone,
          type_id: this.datas.type_id,
          number: this.datas.number,
          license_no1: this.datas.license_no1,
          license_no2: this.datas.license_no2,
          license_no3: this.datas.license_no3,
          wrongtype: this.datas.wrongtype,
          offtrack: this.datas.offtrack,
          outside: this.datas.outside,
          business: this.datas.business,
          of: this.datas.of,
          from_date: this.createddate(document.getElementById("from_date").value),
          from_time: this.datas.from_time,
          to_date: this.createddate(document.getElementById("to_date").value),
          to_time: this.datas.to_time,
          origin: this.datas.origin,
          stop: this.datas.stop,
          destination: this.datas.destination,
          stop_through: this.datas.stop_through,
          status_id:this.datas.status_id,
          created_by:this.currentUser.id,
          update_by:this.currentUser.id,
          no_route: this.datas.no_route,
          no_car: this.datas.no_car,
          license_no1_2: this.datas.license_no1_2,
          province_license_no1: this.datas.province_license_no1,
          license_no2_2: this.datas.license_no2_2,
          province_license_no2: this.datas.province_license_no2,
          license_no3_2: this.datas.license_no3_2,
          province_license_no3: this.datas.province_license_no3,
        };
        console.log(trans);
        if (this.car_id != 0) {
          PermissionDocService.updatepermission_doc(this.car_id, trans).then(async (res) => {
            // this.statuspdf = true
            if (this.datas.officer_id == null) {
              this.$router.push("/permissiondocedit/" + this.car_id);
            alert("บันทึกสำเร็จ");
            UserService.getdatabyrole(2).then(async (res)=>{
              // console.log(res.data);
              for (let o = 0; o < res.data.length; o++) {
                  console.log(res.data[o].line_token);
                if (res.data[o].line_token != null) {
                  LinkImageService.sendNotify('ผู้ประกอบการเพิ่มคำขอที่ '+this.datas.request_at + ' ตรวจสอบที่ '+LinkImageService.getLinkFrontend()+'/permissiondocedit/'+this.car_id,res.data[o].line_token) 
                }
                this.sendEmail(res.data[o].email,'ผู้ประกอบการเพิ่มคำขอที่ ')
                var notify ={
          permission_docs_id:this.car_id,
          messages:'ผู้ประกอบการเพิ่มคำขอที่ '+this.datas.request_at +' ตรวจสอบที่ '+LinkImageService.getLinkFrontend()+'/permissiondocedit/'+this.car_id,
          created_by:this.currentUser.id
}
LogNotifyService.createlog_notify(notify).then(()=>{
// console.log(res.data);
})

              }
            })

            // this.exportToPDF()
            // setTimeout(function () {
            //   // this.exportToPDF()
            // }, 300);
            }else{
              UserService.getUser(this.datas.officer_id).then(async (res)=>{
                this.$router.push("/permissiondocedit/" + this.car_id);
            alert("บันทึกสำเร็จ");
            await LinkImageService.sendNotify('ผู้ประกอบการแก้ไขขอที่ '+this.datas.request_at + ' ตรวจสอบที่ '+LinkImageService.getLinkFrontend()+'/permissiondocedit/'+this.car_id,res.data.line_token) 
            this.sendEmail(res.data[o].email,'ผู้ประกอบการแก้ไขขอที่ ')
                var notify ={
          permission_docs_id:this.car_id,
          messages:'ผู้ประกอบการแก้ไขขอที่ '+this.datas.request_at +' ตรวจสอบที่ '+LinkImageService.getLinkFrontend()+'/permissiondocedit/'+this.car_id,
          created_by:this.currentUser.id
}
LogNotifyService.createlog_notify(notify).then(()=>{
// console.log(res.data);
})

            this.exportToPDF()
            setTimeout(function () {
              this.exportToPDF()
            }, 300);
              });
            }
            

            // window.scrollTo(0, 0);
          });
        } else {
          PermissionDocService.createpermission_doc(trans).then((res) => {
            this.car_id = res.data.id
            this.exportToPDF()
            this.$router.push("/permissiondocedit/" + this.car_id);
            alert("บันทึกสำเร็จ");

            UserService.getdatabyrole(2).then(async (res)=>{
              // console.log(res.data);
              for (let o = 0; o < res.data.length; o++) {
                if (res.data[o].line_token != null) {
                  // console.log(res.data[o].line_token);
                  await LinkImageService.sendNotify('ผู้ประกอบการยื่นคำขออนุญาตคำขอที่ '+this.datas.request_at + ' ตรวจสอบที่ '+LinkImageService.getLinkFrontend()+'/permissiondocedit/'+this.car_id,res.data[o].line_token) 
                }
                // console.log(this.datas.email);
                // console.log(this.datas.email);
                this.sendEmail(res.data[o].email,'ผู้ประกอบการยื่นคำขออนุญาตคำขอที่ ')
                var notify ={
          permission_docs_id:this.car_id,
          messages:'ผู้ประกอบการยื่นคำขออนุญาตคำขอที่ '+this.datas.request_at +' ตรวจสอบที่ '+LinkImageService.getLinkFrontend()+'/permissiondocedit/'+this.car_id,
          created_by:this.currentUser.id
}
LogNotifyService.createlog_notify(notify).then(()=>{
// console.log(res.data);
})

              }
            })

            this.exportToPDF()
            setTimeout(function () {
              this.exportToPDF()
            }, 300);
          });
        }
      }
    });
    },
    getdate(value){
      // console.log(value); 
    var date = new Date(value);
    // console.log(date);
    var day = date.getDate();
    var month = date.getMonth()+1;
    var year = date.getFullYear()+543;
    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    return  day + "/" + month + "/" + year;
    },
    getdata() {
      // console.log(1);
      PermissionDocService.getpermission_doc(this.car_id).then((res) => {
        console.log(res.data);
        this.datas = res.data;
        this.request_at = res.data.request_at
        // document.getElementById("request_date").value = this.getdate(this.datas.request_date)
        if (this.datas.write_date) {
          document.getElementById("write_date").value = this.getdate(this.datas.write_date)
        }
        if (this.datas.from_date) {
          document.getElementById("from_date").value = this.getdate(this.datas.from_date)
        }
        if (this.datas.to_date) {
          document.getElementById("to_date").value = this.getdate(this.datas.to_date)
        }
        this.getamphurs()
        this.getdistricts()
        this.getzipcode()

      });
    },
    searchlicense() {
      this.filteredList = []
      this.filteredList = this.licenses.filter((post) => {
        return post.license.toLowerCase().includes(this.datas.license.toLowerCase());
      });
    },
    async getperlist(){
      var d = new Date()
      var year = d.getFullYear()
      await PermissionDocService.getpermission_docs('',year,'','','','').then((res) => {
        this.list = res.data;
        if (this.car_id == 0) {
        
var result = d.toLocaleDateString('th-TH', {
  year: 'numeric',
})
result= result.replace("พ.ศ.",'')
// console.log(this.list.length);
      this.request_at = (parseInt(this.list.length)+1)+'/'+result
      this.request_at = this.request_at.replace(" ",'')
      console.log(this.request_at);
      if (this.car_id == 0) {
        this.datas.request_at = this.request_at
      }
        }
      });
    },
    dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
},

    async exportToPDF () {
        var element = this.$refs.document
        var opt = {
          margin: [1,1.5,0,1.2],
          filename: 'คำขออนุญาตให้ใช้รถผิดประเภท.pdf',
          jsPDF: { unit: 'cm', format: 'a4', orientation: 'p' },
          pagebreak: {after: 'section'}
    };
let blobString = await html2pdf().from(element).set(opt).toPdf().output('datauristring');
    // console.log(blobString);
var name = this.datas.request_at.replace('/','-')
var selectedFile = this.dataURLtoFile(blobString,name+'.pdf');

const formData = new FormData();
      formData.append("file", selectedFile); 
      // console.log(selectedFile);
      var link = LinkImageService.getLink()
      var http = link + '/uploadimage?type=permissiondocs'+'&&name=' +selectedFile.name;
      axios
        .post(http, formData)
        .then(() => {    
        
        })
        .catch((err) => {
          console.log(err);
        });
        
        // this.$router.push("/permissiondocedit/" + this.car_id);
			},
  },
  mounted() {
    this.getperlist()
this.getlicensetypes()
    this.getprovinces()
    this.datas.status_id = 1
    this.car_id = this.$route.params.id;
    console.log(this.car_id);


    picker_date(document.getElementById("request_date"), { year_range: "-20:+"+10 });
    picker_date(document.getElementById("write_date"), { year_range: "-20:+"+10 });
    picker_date(document.getElementById("from_date"), { year_range: "-20:+"+10 });
    picker_date(document.getElementById("to_date"), { year_range: "-20:+"+10 });
    if (this.car_id != 0) {
      this.getdata();
      this.getdocs()
      // this.editstatus = false;
      // this.$router.push('/permissiondocedit/' + this.car_id);
    } else {
      this.editstatus = true;
      this.datas.status = 1; 
      console.log(this.datas);
    
    }

  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  font-size: 14px;
  font-weight: bold;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

span {
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
  text-align: justify;
}

body {
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  font-size: 14px;
  color: black;
}

div.aa {
  text-align: justify;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

p {
  text-indent: 25px;
  text-align: justify;
  margin-bottom: 0px;
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

div {
  font-family: 'Niramit';
  font-size: 14px;
}

.title {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.content {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom-style: dotted;
  border-bottom: 1.6px dotted;
}

.printableArea {
  width: 8.5cm;
  height: 100%;
}

@page {
  size: 8cm auto;
  width: 8cm;
  height: 100%;
  margin: 0;
  margin-left: 5px !important;
}

input {
  margin-bottom: 5px;
}
#my_file {
    display: none;
}
</style>
