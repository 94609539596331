import { render, staticRenderFns } from "./owneredit.vue?vue&type=template&id=12a3e0a0&scoped=true&"
import script from "./owneredit.vue?vue&type=script&lang=js&"
export * from "./owneredit.vue?vue&type=script&lang=js&"
import style0 from "./owneredit.vue?vue&type=style&index=0&id=12a3e0a0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12a3e0a0",
  null
  
)

export default component.exports