import http from "../http-common";

class TypesService {
    gettypess(name) {
        return http.get('/types?name='+name);
    }
    gettypes(id) {
        return http.get('/types/' + id);
    }
    createtypes(data) {
        return http.post('/types', data);
    }
    updatetypes(id, data) {
        return http.put(`/types/${id}`, data);
    }
    deletetypes(id,status) {
        return http.delete('/types/' + id+'/'+status);
    }
}

export default new TypesService();