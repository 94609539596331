import http from "../http-common";

class PositionCarService {
    getposition_cars(name) {
        return http.get('/position_car?name='+name);
    }
    getposition_car(id) {
        return http.get('/position_car/' + id);
    }
    createposition_car(data) {
        return http.post('/position_car', data);
    }
    updateposition_car(id, data) {
        return http.put(`/position_car/${id}`, data);
    }
    deleteposition_car(id,status) {
        return http.delete('/position_car/' + id+'/'+status);
    }
}

export default new PositionCarService();