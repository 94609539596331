<template>
  <div class="container-fluid">
    <div class="card-body mt-3">
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">วันที่จดทะเบียน <span style="color:red">*</span></label>
          <input id="registration_date" class="form-control form-control-sm" />
        </div>
        <div class="form-group col-md-4">
          <label for="inputPassword4">เลขทะเบียน <span style="color:red">*</span></label>
          <input type="text" class="form-control form-control-sm" id="inputPassword4" v-model="user.registration_no">
        </div>
        <div class="form-group col-md-4">
          <label for="inputPassword4">จังหวัด <span style="color:red">*</span></label>
          <select id="inputState" class="form-control form-control-sm" 
            v-model="user.province_id">
            <option v-for="(l, i) in provinces" :key="i" :value="l.id"> {{ l.name_th }}</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">รหัสตรวจสภาพ</label>
          <input class="form-control form-control-sm" v-model="user.inspection_code" />
        </div>
        <div class="form-group col-md-4">
          
        </div>
        <div class="form-group col-md-4">
          <label for="inputPassword4">ประเภท <span style="color:red">*</span></label>
          <select id="inputState" class="form-control form-control-sm" 
            v-model="user.type_id">
            <option v-for="(l, i) in types" :key="i" :value="l.id"> {{l.type}} {{ l.name }}</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">ลักษณะ/มาตรฐาน <span style="color:red">*</span></label>
          <select id="inputState" class="form-control form-control-sm" 
            v-model="user.character_id">
            <option v-for="(l, i) in characters" :key="i" :value="l.id"> {{ l.name }}</option>
          </select>
        </div>
        <div class="form-group col-md-4">
          
        </div>
        <div class="form-group col-md-4">
          <label for="inputPassword4">ยี่ห้อรถ <span style="color:red">*</span></label>
          <select id="inputState" class="form-control form-control-sm" 
            v-model="user.brand_id">
            <option v-for="(l, i) in brands" :key="i" :value="l.id"> {{ l.name }}</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">แบบ/รุ่น</label>
          <input type="text" class="form-control form-control-sm" id="inputPassword4" v-model="user.model">
        </div>
        <div class="form-group col-md-4">
          <label for="inputPassword4">สี <span style="color:red">*</span></label>
          <select id="inputState" class="form-control form-control-sm" 
            v-model="user.color">
            <option v-for="(l, i) in colors" :key="i" :value="l.id"> {{ l.name }}</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">เลขตัวรถ <span style="color:red">*</span></label>
          <input type="text" class="form-control form-control-sm" id="inputPassword4" v-model="user.car_number">
        </div>
        <div class="form-group col-md-4">
          
        </div>
        <div class="form-group col-md-4">
          <label for="inputPassword4">อยู่ที่ <span style="color:red">*</span></label>
          <select id="inputState" class="form-control form-control-sm" 
            v-model="user.position_car_id">
            <option v-for="(l, i) in position_car" :key="i" :value="l.id"> {{ l.name }}</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">ยี่ห้อเครื่องยนต์</label>
          <select id="inputState" class="form-control form-control-sm" 
            v-model="user.brand_machine">
            <option v-for="(l, i) in brand_machines" :key="i" :value="l.id"> {{ l.name }}</option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label for="inputState">เลขเครื่องยนต์</label>
          <input type="text" class="form-control form-control-sm" id="inputPassword4" v-model="user.no_machine">
        </div>
        <div class="form-group col-md-4">
          <label for="inputPassword4">อยู่ที่ <span style="color:red">*</span></label>
          <select id="inputState" class="form-control form-control-sm" 
            v-model="user.position_machine_id">
            <option v-for="(l, i) in position_machine" :key="i" :value="l.id"> {{ l.name }}</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inputState">จำนวน</label>
          <div class="input-group input-group-sm " >
                  <input
                    v-model="user.no"
                    type="number"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                  <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon2">สูบ</span>
                </div>
                <input
                    v-model="user.horsepower	"
                    type="number"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                  <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon2">แรงม้า</span>
                </div>
                <input
                    v-model="user.kilowatt"
                    type="number"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                  <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon2">กิโลวัตต์</span>
                </div>
                </div>
        </div>
        <div class="form-group col-md-4">
          <label for="inputState">จำนวนเพลา ล้อ ยาง <span style="color:red">*</span></label>
          <select id="inputState" class="form-control form-control-sm" 
            v-model="user.axe_id">
            <option v-for="(l, i) in axes" :key="i" :value="l.id"> {{ l.axe }} เพลา {{ l.wheel }} ล้อ ยาง {{ l.tire }} เส้น</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">น้ำหนักรถ</label>
          <div class="input-group input-group-sm " >
                  <input
                    v-model="user.weight"
                    type="number"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                  <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon2">กก.</span>
                </div>
                </div>
                  
        </div>
        <div class="form-group col-md-4">
          <label for="inputState">จำนวนผู้โดยสาร</label>
          <div class="input-group input-group-sm " >
                  <input
                    v-model="user.no_passenger"
                    type="number"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                  <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon2">คน</span>
                </div>&nbsp;
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon2">ยืน</span>
                </div>
                <input
                    v-model="user.stand"
                    type="number"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                  <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon2">คน</span>
                </div>
                </div>
        </div>
        
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputState">น้ำหนักบรรทุก/น้ำหนักลงเพลา</label>
          <div class="input-group input-group-sm " >
                  <input
                    v-model="user.payload"
                    type="number"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                  <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon2">กก.</span>
                </div>
                </div>
                  
        </div>
        <div class="form-group col-md-4">
          <label for="inputState">น้ำหนักรวม</label>
          <div class="input-group input-group-sm " >
                  <input
                    v-model="user.weight_sum"
                    type="number"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                  <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon2">กก.</span>
                </div>
                </div>
        </div>
        <div class="form-group col-md-4">
          <label for="inputState">วันที่สิ้นอายุภาษี <span style="color:red">*</span></label>
          <input id="expire_date" class="form-control form-control-sm" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="inputPassword4">เจ้าของรถ</label>
          <select id="inputState" class="form-control form-control-sm" 
            v-model="user.owner_id">
            <option v-for="(l, i) in owners" :key="i" :value="l.id"> {{ l.name }}</option>
          </select>
        </div>
      </div>
      <button class="btn btn-success" style="color: white; width: 200px" @click="save()">
        บันทึก
      </button>
    </div>
  </div>
</template>
<script src="https://api-owb.visdchiangrai.com/picker_date.js"></script>

<script>
import ProvinceService from "../services/ProvinceService";
import AmphuresService from "../services/AmphuresService";
import LicenseService from "../services/LicenseService";
import TransportTypeService from "../services/TransportTypeService";
import BrandsService from "../services/BrandsService";
import CharacterService from "../services/CharacterService";
import ColorsService from "../services/ColorsService";
import PositionCarService from "../services/PositionCarService";
import PositionMachineService from "../services/PositionMachineService";
import BrandsMachineService from "../services/BrandsMachineService";
import AxeService from "../services/AxeService";
import OwnerService from "../services/OwnerService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      user_id: 0,
      hash: 0,
      title: "",
      roles:[],
      provinces:[],
      amphurs:[],
      districts:[],
      zipcode:'',
      own_id:0,
      transporttypes:[],
      types:[],
      characters:[],
      brands:[],
      colors:[],
      position_machine:[],
      position_car:[],
      brand_machines:[],
      axes:[],
      owners:[]
    };
  },
  mounted() {
    this.own_id = this.$route.params.id;
    this.getprovinces()
    if (this.own_id != 0) {
    this.getOwnership();
    }
    // var date = new Date()
    // var y = date.getUTCFullYear() +543
    // y = y-2566
    // console.log(y);
    picker_date(document.getElementById("registration_date"), { year_range: "-20:+"+10 });
    picker_date(document.getElementById("expire_date"), { year_range: "-20:+"+10 });
    TransportTypeService.gettransport_types().then((res)=>{
this.transporttypes = res.data
this.getmaster()
    })
  },
  methods: {
    getmaster(){
      TransportTypeService.gettransport_types(1).then((res)=>{
        this.types = res.data
      })
      CharacterService.getcharacters(1).then((res)=>{
        this.characters = res.data
        // console.log(res.data);
      })
      BrandsService.getbrands(1).then((res)=>{
        this.brands = res.data
      })
      ColorsService.getcolors(1).then((res)=>{
        this.colors = res.data
      })
      PositionCarService.getposition_cars(1).then((res)=>{
        this.position_car = res.data
      })
      PositionMachineService.getposition_machines(1).then((res)=>{
        this.position_machine = res.data
      })
      BrandsMachineService.getbrandmachines(1).then((res)=>{
        this.brand_machines = res.data
      })
      AxeService.getaxewheeltires(1).then((res)=>{
        this.axes = res.data
      })
      OwnerService.getowners(1).then((res)=>{
        this.owners = res.data
        // console.log(res.data);
      })
    },
    createddate(value) {  
      // console.log(value); 
  var streetaddress = String(value);
    var d = streetaddress.substring(0, streetaddress.indexOf("/"));
    var m = streetaddress.substring(5, streetaddress.indexOf("/"));
    m = m.substring(1, 3);
    var y = streetaddress.substring(5);
    var full = m + "/" + d + y;
    // console.log(full);
    var date = new Date(full);
    // console.log(date);
    var day = date.getDate();
    var month = date.getMonth()+1;
    var year = date.getFullYear()-543;
    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    return  year + "-" + month + "-" + day;
  },
    onChangeProvince(){
// console.log(evt.target.value);
this.getamphurs()
    },
    onChangeAmphur(){
// console.log(evt.target.value);
this.getdistricts()
    },
    onChangeDistrict(){
// console.log(evt.target.value);
this.getzipcode()
    },
    getprovinces(){
      ProvinceService.getprovinces().then((res)=>{
        this.provinces = res.data
        // console.log(res.data);
      })
    },
    getamphurs(){
      AmphuresService.getamphures(this.user.province_id).then((res)=>{
        this.amphurs = res.data
        this.districts = {}
        this.zipcode = ''
        // console.log(res.data);
      })
    },
    save() {
      if (document.getElementById("registration_date").value == "" || document.getElementById("registration_date").value == null) {
        alert("กรุณากรอกวันที่จดทะเบียน");
      }else if (this.user.registration_no == "" || this.user.registration_no == null) {
        alert("กรุณากรอกเลขทะเบียน");
      }else if (this.user.province_id == "" || this.user.province_id == null) {
        alert("กรุณาเลือกจังหวัด");
      } else if (this.user.type_id == "" || this.user.type_id == null) {
        alert("กรุณาเลือกประเภท");
      }else if (this.user.character_id == "" || this.user.character_id == null) {
        alert("กรุณาเลือกลักษณะ/มาตรฐาน");
      }else if (this.user.brand_id == "" || this.user.brand_id == null) {
        alert("กรุณเลือกยี่ห้อรถ");
      }else if (this.user.color == "" || this.user.color == null) {
        alert("กรุณาเลือกสี");
      }else if (this.user.car_number == "" || this.user.car_number == null) {
        alert("กรุณากรอกเลขตัวรถ");
      }else if (this.user.position_car_id == "" || this.user.position_car_id == null) {
        alert("กรุณาเลือกตำแหน่งเลขตัวรถ");
      }else if (this.user.position_machine_id == "" || this.user.position_machine_id == null) {
        alert("กรุณเลือกตำแหน่งเครื่องยนต์");
      }else if (this.user.axe_id == "" || this.user.axe_id == null) {
        alert("กรุณาเลือกจำนวนเพลา ล้อ ยาง");
      }else if (document.getElementById("expire_date").value == "" || document.getElementById("expire_date").value == null) {
        alert("กรุณากรอกวันสิ้นอายุภาษี");
      }else if (this.user.owner_id == "" || this.user.owner_id == null) {
        alert("กรุณากรอกเลือกเจ้าของรถ");
      }
      // else if (this.user.phone == "" || this.user.phone == null) {
      //   alert("กรุณากรอกเบอร์โทรศัพท์");
      // } 
      else {
        // console.log(userdata);
        var license ={
          registration_date: this.createddate(document.getElementById("registration_date").value),
          registration_no: this.user.registration_no,
          province_id: this.user.province_id,
          inspection_code: this.user.inspection_code,
          type_id: this.user.type_id,
          character_id: this.user.character_id,
          brand_id: this.user.brand_id,
          model: this.user.model,
          color: this.user.color,
          car_number: this.user.car_number,
          position_car_id: this.user.position_car_id,
          inspection_code: this.user.inspection_code,
          brand_machine: this.user.brand_machine,
          no_machine: this.user.no_machine,
          position_machine_id: this.user.position_machine_id,
          no: this.user.no,
          horsepower: this.user.horsepower,
          kilowatt: this.user.kilowatt,
          axe_id: this.user.axe_id,
          weight: this.user.weight,
          no_passenger: this.user.no_passenger,
          stand: this.user.stand,
          payload: this.user.payload,
          weight_sum: this.user.weight_sum,
          expire_date: this.createddate(document.getElementById("expire_date").value),
          owner_id: this.user.owner_id,
          status: 1,
          created_by:this.currentUser.id,
            }
        if (this.own_id == 0) {
          
          LicenseService.createlicense(license).then(()=>{

            alert('บันทึกสำเร็จ')
            this.$router.push('/licenses');
            })
        } else {
            // console.log(owner);
            LicenseService.updatelicense(this.own_id, license).then(() => {
            // console.log(res.data);
            
              alert('บันทึกสำเร็จ')
            this.$router.push('/licenses');
          });
        }
      }
    },
    getdate(value){
      // console.log(value); 
    var date = new Date(value);
    // console.log(date);
    var day = date.getDate();
    var month = date.getMonth()+1;
    var year = date.getFullYear()+543;
    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    return  day + "/" + month + "/" + year;
    },
    getOwnership() {
      LicenseService.getlicense(this.own_id).then((res) => {
        this.user = res.data;
        // console.log(res.data);
        document.getElementById("registration_date").value = this.getdate(this.user.registration_date)
        document.getElementById("expire_date").value = this.getdate(this.user.expire_date)
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}

.card {
  margin: 0 auto;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
}

body {
  background-color: gray;
}</style>
