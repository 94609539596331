import http from "../http-common";

class TypeLicenseService {
    gettype_licenses(name) {
        return http.get('/type_license?name='+name);
    }
    gettype_license(id) {
        return http.get('/type_license/' + id);
    }
    createtype_license(data) {
        return http.post('/type_license', data);
    }
    updatetype_license(id, data) {
        return http.put(`/type_license/${id}`, data);
    }
    deletetype_license(id,data) {
        return http.put(`/type_license/deletetype_license/${id}`, data);
    }
}

export default new TypeLicenseService();