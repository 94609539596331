import http from "../http-common";

class AxeService {
    getaxewheeltires(name) {
        return http.get('/axewheeltire?name='+name);
    }
    getaxewheeltire(id) {
        return http.get('/axewheeltire/' + id);
    }
    createaxewheeltire(data) {
        return http.post('/axewheeltire', data);
    }
    updateaxewheeltire(id, data) {
        return http.put(`/axewheeltire/${id}`, data);
    }
    deleteaxewheeltire(id,status) {
        return http.delete('/axewheeltire/' + id+'/'+status);
    }
}

export default new AxeService();