import http from "../http-common";

class TransportTypeService {
    gettransport_types(name) {
        return http.get('/transport_types?name='+name);
    }
    gettransporttype(id) {
        return http.get('/transport_types/' + id);
    }
    createtransporttype(data) {
        return http.post('/transport_types', data);
    }
    updatetransporttype(id, data) {
        return http.put(`/transport_types/${id}`, data);
    }
    deletetransporttype(id,status) {
        return http.delete('/transport_types/' + id+'/'+status);
    }
}

export default new TransportTypeService();