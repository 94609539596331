import http from "../http-common";

class OfficeTypeService {
    getoffice_types() {
        return http.get('/office_type');
    }
    getoffice_type(id) {
        return http.get('/office_type/' + id);
    }
    createoffice_type(data) {
        return http.post('/office_type', data);
    }
    updateoffice_type(id, data) {
        return http.put(`/office_type/${id}`, data);
    }
    deleteoffice_type(id) {
        return http.delete('/office_type/' + id);
    }
}

export default new OfficeTypeService();