<template>
  <div style="margin: 12px;background-color:white">
    <div>
<div style="text-align: center;background-color:white" class="mb-5">
<label style="text-align: center;">หนังสือสัญญาเดินรถร่วมห้างหุ่นส่วนจำกัด จ.โชคชัยหิรัญ</label>
</div>
<div class="row">
  <div class="col-md-12">
  <div class="form-group row">
<label for="inputEmail3" class="col-sm-2 col-form-label">เขียนที่</label>
<div class="col-sm-4">
<input type="text" class="form-control" id="inputEmail3" placeholder="">
</div>
<label for="inputEmail3" class="col-sm-2 col-form-label">วันที่</label>
<div class="col-sm-4">
<input type="text" class="form-control" id="inputEmail3" placeholder="">
</div>
</div>
</div>
<div class="col-md-12">
  <div class="form-group row">
<label for="inputEmail3" class="col-sm-3 col-form-label">หนังสือสัญญาฉบับนี้ทำขึ้นระหว่าง</label>
<div class="col-sm-9">
<input type="text" class="form-control" id="inputEmail3" placeholder="">
</div>
</div>
</div>
<div class="col-md-12">
  <div class="form-group row">
<label for="inputEmail3" class="col-sm-1 col-form-label">บ้านเลขที่</label>
<div class="col-sm-2">
<input type="text" class="form-control" id="inputEmail3" placeholder="">
</div>
<label for="inputEmail3" class="col-sm-1 col-form-label">หมู่</label>
<div class="col-sm-2">
<input type="text" class="form-control" id="inputEmail3" placeholder="">
</div>
<label for="inputEmail3" class="col-sm-1 col-form-label">ตรอก/ซอย</label>
<div class="col-sm-2">
<input type="text" class="form-control" id="inputEmail3" placeholder="">
</div>
<label for="inputEmail3" class="col-sm-1 col-form-label">ถนน</label>
<div class="col-sm-2">
<input type="text" class="form-control" id="inputEmail3" placeholder="">
</div>
</div>
</div>
<div class="col-md-12">
  <div class="form-group row">
<label for="inputEmail3" class="col-sm-1 col-form-label">ตำบล</label>
<div class="col-sm-3">
<input type="text" class="form-control" id="inputEmail3" placeholder="">
</div>
<label for="inputEmail3" class="col-sm-1 col-form-label">อำเภอ</label>
<div class="col-sm-3">
<input type="text" class="form-control" id="inputEmail3" placeholder="">
</div>
<label for="inputEmail3" class="col-sm-1 col-form-label">จังหวัด</label>
<div class="col-sm-3">
<input type="text" class="form-control" id="inputEmail3" placeholder="">
</div>
</div>
</div>
</div>
      <span>          
            ทั้งสองฝ่ายตกลงทำสัญญากันไว้มีข้อความดังต่อไปนี้<br/> 
      </span>
      <span>
        1. สัญญาฉบับนี้ทำขึ้นเนื่องจากผู้เดินรถร่วมประสงค์ประกอบอาชีพเดินรถรับจ้างบรรทุกสัตว์และหรือสิ่งของทั่วราชอาณาจักร <br/>
        โดยขอเข้าร่วมใช้ใบอนุญาตประกอบการขนส่งกับทางห้างหุ้นส่วนจำกัด จ.โชคชัยหิรัญ<br/>
        2. ผู้รับเดินรถร่วมเป็นผู้มีสิทธิ์ประกอบการขนส่งไม่ประจำทางด้วยรถบรรทุกโดยได้รับอนุญาตจากนายทะเบียนตามกฎหมายว่า
ด้วยการขนส่งทางบก เลขที่ ชร ออกวันที่ เดือน พ.ศ.<br/>
3. ผู้เดินรถร่วมเป็นเจ้าของกรรมสิทธิ์หรือผู้เช่่าซื้อรถหมายเลขทะเบียน   ยี่ห้อ    เลขเครื่องยนต์ เลขตัวรถ
<br/>
4. ผู้เดินรถร่วมยินยอมที่จะปฏิบัติตามเงื่อนไขและระเบียบของทางราชการและของทางห้างหุ้นส่วนฯ ทุกประการ หากผู้เดินรถร่วมไม่ปฏิบัติตามเงื่อนไขข้อใดข้อหนึ่งหนังสือสัญญาเดินรถร่วมฉบับนี้ถือว่าไม่สมบูรณ์และให้ถือเป็นโมฆะทันทีโดยผู้เดินรถร่วมตกลงยอมรับสัญญา/ข้อตกลงต่าง ๆ ด้วยความสมัครใจโดยไม่ได้มีการบังคับ ขู่เข็ญ แต่อย่างใดทั้งสิ้น<br/>
5. ค่าใช้จ่ายต่าง ๆ ในการใช้รถ เช่น ค่าน้ำมันเชื้อเพลิง น้ำหล่อลื่น ค่าบำรุงรักษา ค่าธรรมเนียมและค่าภาษีอากรต่าง ๆ ของผู้เดินรถร่วมเป็นผู้ชำระเองทั้งสิ้นการเสียภาษีรถต้องแนบสัญญาเดินรถร่วมของทางห้างฯ<br/>
6. กรณีผู้รับเดินรถร่วมจำต้องชดใช้ค่าเสียหายเบื้องต้นให้แก่ผู้เสียหายอันเนื่องมาจากการใช้รถของผู้เดินรถร่วมตามที่บัญญัติ ไว้ในกฎหมายว่าด้วยกรมการขนส่งทางบกไปด้วยประการใด ๆ ก็ตาม ผู้เดินรถร่วมตกลงยินยอมรับสภาพหนี้กับทางห้างฯ และจะชำระและชดใช้ค่าใช้จ่ายต่าง ๆ รวมทั้งดอกเบี้ยตามกฎหมายแก่ผู้รับเดินรถร่วมภายใน 7 วัน นับตั้งแต่วันที่ผู้รับเดินรถร่วมได้ชดใช้ไป หากผู้เดินรถร่วมไม่ปฏิบัติตามสัญญายินยอมถูกปรับเป็นเงิน 10 เท่าของยอดเงินที่ได้ชำระไปในทันที<br/>
7. ผู้เดินรถร่วม/ผู้ประจำรถมิใช่เป็นตัวแทนหรือลูกจ้างของผู้รับเดินรถร่วมแต่อย่างใดหากนำรถคันดังกล่าวไปใช้ในการกระทำความผิดตามกฎหมายไม่ว่ากรณีใดๆก็ตามหากมีผู้ใดกล่าวหาว่าบุคคลดังหล่าวและเรียกร้องค่าเสียหายใด ๆ ทั้งทางแพ่งและทางอาญาในนามของผู้เดินรถร่วมกับผู้รับเดินรถร่วม ผู้เดินรถร่วม/ผู้ประจำรถต้องรับผิดชอบแต่เพียงฝ่ายเดียวในทุกกรณีและผู้รับเดินรถร่วมมิได้มีค่าตอบแทนและผลประโยขร์ร่วมใด ๆ ในการกระทำและการประกอบอาชีพใด ๆ ของผู้เดินรถร่วม/ผู้ประจำรถ อนึ่ง ผู้เดินรถร่วมจะต้แงจัดทำประกันภัยรถยนต์ตามที่กฎหมายบังคับรวมทั้งต้องจัดทำประกันภัยรถยนต์ประเภทหนึ่ง สอง หรือสาม ในระหว่างที่เดินรถร่วมกับทางห้างฯ<br/>
8. สัญญานี้ผู้รับเดินรถร่วมมีสิทธิ์ที่จะบอกเลิกผู้เดินรถร่วมได้ทันทีโดยมิต้องบอกกล่าว โดยไม่ต้องทำหนังสือแจ้งการบอกเลิกสัญญาแก่ผู้เดินรถร่วม ในกรณีที่ผู้เดินรถร่วมกระทำการด้วยเจตนาในทางไม่ปฏิบัติตามเงื่อนไข และระเบียบหรือกระทำการอื่นใดจนเป็นเหตุให้น่าจะเกิดความเสียหายแก่ผู้เดินรถร่วม<br/>
สัญญาฉบับนี้ทำขึ้นเป็นสองฉบับมีข้อความตรงกันทั้งสองฝ่ายได้อ่านเข้าใจดีแล้วจึงลงลายมือชื่อเป็นสำคัญต่อหน้า พยานและคู่สัญญาได้ยึดถือสัญญาฉบับนี้ไว้ฝ่ายละฉบับ
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: "Cars",
  props: {
    msg: String,
  },
  data() {
    return {
      datas: [],
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>
