import http from "../http-common";

class MaintenanceItemsService {
    getmaint_itemss(id) {
        return http.get('/maint_items?id='+id);
    }
    getmaint_items(id) {
        return http.get('/maint_items/' + id);
    }
    createmaint_items(data) {
        return http.post('/maint_items', data);
    }
    updatemaint_items(id, data) {
        return http.put(`/maint_items/${id}`, data);
    }
    deletemaint_items(id,status) {
        return http.delete('/maint_items/' + id+'/'+status);
    }
}

export default new MaintenanceItemsService();