import http from "../http-common";

class MaintenancelistService {
    getmaint_lists(name) {
        return http.get('/maint_list?name='+name);
    }
    getmaint_list(id) {
        return http.get('/maint_list/' + id);
    }
    createmaint_list(data) {
        return http.post('/maint_list', data);
    }
    updatemaint_list(id, data) {
        return http.put(`/maint_list/${id}`, data);
    }
    deletemaint_list(id,status) {
        return http.delete('/maint_list/' + id+'/'+status);
    }
}

export default new MaintenancelistService();