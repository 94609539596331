import http from "../http-common";

class VehicleService {
    getvehicles() {
        return http.get('/vehicles');
    }
    getvehicle(id) {
        return http.get('/vehicles/' + id);
    }
    createvehicle(data) {
        return http.post('/vehicles', data);
    }
    updatevehicle(id, data) {
        return http.put(`/vehicles/${id}`, data);
    }
    deletevehicle(id,status) {
        return http.delete('/vehicles/' + id+'/'+status);
    }
}

export default new VehicleService();