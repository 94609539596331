import http from "../http-common";

class MemberService {
    getmembers() {
        return http.get('/members');
    }
    getmember(id) {
        return http.get('/members/' + id);
    }
    createmember(data) {
        return http.post('/members', data);
    }
    updatemember(id, data) {
        return http.put(`/members/${id}`, data);
    }
    deletemember(id) {
        return http.delete('/members/' + id);
    }
}

export default new MemberService();