import http from "../../http-common";
import UserService from "../UserService";

class AuthService {
  async login(user) {
    // console.log(user)
    return http.post('user/signin', user)
      .then(response => {
        // console.log(response.data);
        if (response.data.accessToken) {
          localStorage.setItem('usermdm', JSON.stringify(response.data));
        }
        return response.data;
      },
        error => {
          console.log(error);
          this.$router.push("/login");
          alert('ชื่อหรือรหัสผ่านไม่ถูกต้อง')
        }
      );
  }
  async loginuser(user) {
    // console.log(user)
    return http.post('user/signin', user)
      .then(response => {
        // console.log(response.data);
        if (response.data.accessToken) {
          localStorage.setItem('usermdm', JSON.stringify(response.data));
          UserService.getMenubyRoleID(response.data.role_id).then((res)=>{
            console.log(res.data);
        var menu = res.data[0].url
this.$router.push(menu);
          location.reload();
          });
        }
        return response.data;
      },
        error => {
          console.log(error);
          this.$router.push("/login");
          alert('ชื่อหรือรหัสผ่านไม่ถูกต้อง')
        }
      );
  }
  logout() {
    localStorage.removeItem('usermdm');
  }

  role() {
    return http.get('role');
  }

  getpage(id) {
    return http.get(`menu/menubyuserid/`+id);
  }

  
  getname(id) {
    return http.get(`user/`+id);
  }

}
export default new AuthService();