<template>
  <div class="container">
    <h5 class="mt-3">ตารางแสดงการบำรุงรักษารถแต่ละมาตรฐาน/ลักษณะรถ</h5>
    <div class="col mb-3 mt-3" style="text-align: right">
      <div class="form-check">
        <label>หมายเหตุ</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>ต้องบำรุงรักษา &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">ยกเว้นบำรุงรักษา
            </div>
    </div>
    <table class="table table-bordered mt-5" style="background-color: white">
      <thead>
        <tr class="table-active">
          <th scope="col" style="text-align:center">ประเภทรถ</th>
          <th scope="col" style="text-align:center">มาตรฐาน/ลักษณะรถ</th>
          <th scope="col" style="text-align:center" v-for="(h, i) in head" :key="i">{{ h.name }}</th>
        </tr>
      </thead>
      <tbody v-for="(l, i) in list" :key="i">
        <tr>
          <td rowspan="10">{{ l.type }}</td>
          <td> {{ l.name }}</td>
          <td style="text-align:center">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="l.list1">
            </div>
          </td>
          <td style="text-align:center" >
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="l.list2">
            </div>
          </td>
          <td style="text-align:center" >
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="l.list3">
            </div>
          </td>
          <td style="text-align:center" >
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="l.list4">
            </div>
          </td>
          <td style="text-align:center" >
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="l.list5">
            </div>
          </td>
          <td style="text-align:center" >
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="l.list6">
            </div>
          </td>
          <td style="text-align:center" >
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="l.list7">
            </div>
          </td>
          <td style="text-align:center" >
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="l.list8">
            </div>
          </td>
          <td style="text-align:center" >
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="l.list9">
            </div>
          </td>
          <td style="text-align:center" >
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="l.list10">
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <button class="btn btn-success" style="color: white;" @click="save()">
        บันทึก
      </button>
    <div class="modal fade" id="AddMainitem" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>

          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                <div class="form-group mt-3">
                  <label for="username">ชื่อรายการ</label>
                  <input v-model="user.item" type="text" min="1" class="form-control form-control-sm" id="username" />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button id="closedmainitem" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="DeleteOffice" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบหน่วยงาน</h5>

          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-danger" @click="deletebyid()">
              ลบข้อมูล
            </button>
            <button id="closeddeleteuser" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaintenanceItemsService from "../services/MaintenanceItemsService";
import OfficeTypeService from "../services/OfficeTypeService";
import CharacterService from "../services/CharacterService";
import MaintenanceService from "../services/MaintenanceService";
import TypesService from "../services/TypesService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      master: {},
      user_id: 0,
      title: "",
      roles: [],
      id: 0,
      head: [],
      type: []
    };
  },
  mounted() {
    this.id = this.$route.params.id
    console.log(this.id)
    this.getMasters();
    MaintenanceService.getmaintenances().then((res) => {
      this.head = res.data
    })
    CharacterService.getcharacterstable().then((res) => {
      console.log(res.data);
      this.list = res.data
    });

    TypesService.gettypess().then((res) => {
      console.log(res.data);
      this.types = res.data
    });
  },
  methods: {
    getid(id) {
      this.user_id = id;
      if (this.user_id != 0) {
        this.title = "แก้ไขข้อมูลรายการ";
        // console.log(this.user_id);
        MaintenanceItemsService.getmaint_items(this.user_id).then((res) => {
          console.log(res.data);
          this.user = res.data;
        });
      } else {
        this.title = "เพิ่มข้อมูลรายการ";
        this.user = [];
      }
    },
    deletedata(id) {
      this.user_id = id;
    },
    deletebyid() {
      OfficeTypeService.deleteoffice_type(this.user_id).then(() => {
        document.getElementById("closeddeleteuser").click();
        this.getUsers();
      })
    },
    save() {
      // console.log(this.list);
      for (let l = 0; l < this.list.length; l++) {
        // console.log(this.list[l].id);
      var list ={
        list1:this.list[l].list1,
        list2:this.list[l].list2,
        list3:this.list[l].list3,
        list4:this.list[l].list4,
        list5:this.list[l].list5,
        list6:this.list[l].list6,
        list7:this.list[l].list7,
        list8:this.list[l].list8,
        list9:this.list[l].list9,
        list10:this.list[l].list10,
      }
CharacterService.updatetablemain(this.list[l].id,list).then(() => {
  if (this.list.length == l+1) {
    alert('บันทึกเรียบร้อย')
  }
})
        
      }
    },
    getMasters() {
      MaintenanceItemsService.getmaint_itemss(this.id).then((res) => {
        this.list = res.data;
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}

.card {
  margin: 0 auto;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
}

body {
  background-color: gray;
}
</style>
