<template>
  <div id="app">
    <div v-if="!statuseva">
      <Nav  msg="Welcome to Your Vue.js App"/>
    <router-view />
    </div>
    <div v-else>
      <table width=100%>
      <tr>
        <td width=3%></td>
        <td width=0.1% bgcolor="#ffffff"></td>


        <td width=93.8% bgcolor="#of1527">
          <table width=100%>
            <tr>
              <td width=5%></td>
              <td><br><br><br><br>
                <font size="5" color="#1099f0">ระบบประเมินความพึงพอใจการให้บริการ</font>
                <br><br>
                <font size="5" color="#ffffff">สำนักงานขนส่งจังหวัดเชียงราย</font>

              </td>
            </tr>

            <tr>
              <td width=5%></td>
              <td height=100 valign="top">
                <font>
                  <br><br><span v-html="header.title"></span></font>
              </td>
            </tr>

            <tr>
              <td width=5%></td>
              <td>
<div style="background-color:#5472cc" >
                <router-view />
              </div>
              </td>
            </tr>
          </table>
        </td>



        <td width=0.1% bgcolor="#ffffff"></td>
        <td width=3%></td>


      </tr>

    </table>
    </div>
  </div>
</template>

<script>
import Nav from './components/Nav.vue'
import HeaderService from './services/HeaderService.js'
import UserService from './services/UserService'

export default {
  name: 'App',
  components: {
    Nav
  },
  data() {
    return {
      statuseva:false,
      header:{}
    }
  },
  mounted() {
    // console.log(this.currentUser);
    if (this.currentUser) {
      UserService.getUser(this.currentUser.id).then((res)=>{
        // console.log(res.data);
        var expire = new Date(res.data.pass_date)
        expire.setMonth(expire.getMonth() + 3);

        var current = new Date()
        // console.log(current.getFullYear()+'-'+("0" + current.getMonth()+1).slice(-2)+'-'+("0" + current.getDate()).slice(-2));
        var shortMonth = new Date(current.getFullYear()+'-'+("0" + current.getMonth()+1).slice(-2)+'-'+("0" + current.getDate()).slice(-2) + ' 00:00:00');
        // console.log(expire);
        // console.log(shortMonth);
        if (expire < shortMonth) {
          alert('กรุณาเปลี่ยนรหัสผ่านใหม่เพื่อความปลอดภัยในการเข้าใช้งานระบบ เนื่องจากคุณใช้รหัสผ่านนี้เกินระยะเวลาที่กำหนดแล้ว')
        }
      })
    }
    if (this.$route.name == 'evaluation') {
      this.statuseva = true
      HeaderService.getheader(1).then((res)=>{
        // console.log(res.data);
      this.header = res.data
    })
    }
    
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    
  },
}

</script>

<style>
div{
  font-family: 'Niramit', sans-serif;
  font-size: 14px;
}
</style>