import http from "../http-common";

class DocumentService {
    getdocuments() {
        return http.get('/documents');
    }
    getdocument(id) {
        return http.get('/documents/' + id);
    }
    createdocument(data) {
        return http.post('/documents', data);
    }
    updatedocument(id, data) {
        return http.put(`/documents/${id}`, data);
    }
    deletetdocument(id,data) {
        return http.put(`/documents/deletedocuments/${id}`, data);
    }
}

export default new DocumentService();