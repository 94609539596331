<template>
  <div class="container">
    <table class="table table-bordered mt-3">
      <thead>
        <tr class="table-active">
          <th scope="col">ลำดับที่</th>
          <th scope="col">ชื่อ-นามสกุล</th>
          <th scope="col">ชื่อบริษัท</th>
          <th scope="col">ประเภทบริษัท</th>
          <th scope="col">หนังสือมอบอำนาจ</th>
          <th scope="col">สถานะ</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in datalist" :key="i">
          <td>
            {{ i + 1 }}
          </td>
          <td>
            {{ l.firstname }} {{ l.lastname }}
          </td>
          <td>
            {{ l.company_name }}
          </td>
          <td>
            {{ l.operatorname }}
          </td>
          <td>
            <a :href="l.file" target="_blank">{{ l.file_name }}</a>
          </td>
          <td>
           <span v-if="l.activate == 1">ผ่านการตรวจสอบ</span> 
           <span v-if="l.activate == 0 && l.id == null"></span> 
           <span v-if="l.activate == 0 && l.id != null">รอเจ้าหน้าที่ตรวจสอบ</span> 
          </td>
          <!-- <td>{{ l.firstname }} {{ l.lastname }}</td> -->
          <td>
            <a @click="getid(l.id,false)" v-if="l.activate == 1">
              <button
              style="width:40px"
                type="button"
                class="btn btn-danger"
              >
                <i class="fa fa-times"></i></button
            ></a>
            <a @click="getid(l.id,true)" v-if="l.activate == 0 && l.id != null">
              <button
              style="width:40px"
                type="button"
                class="btn btn-success"
              >
                <i class="fa fa-check"></i></button
            ></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import AgencyService from "../services/AgencyService";
import axios from 'axios';
import LinkImageService from "../services/LinkImageService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      id: 0,
      list: [],
      data: {},
      user_id: 0,
      hash: 0,
      roles:[],
      file_name:'',
      datalist:[],
      title:'เพิ่มข้อมูลบริษัท'
    };
  },
  mounted() {
    this.getUsers();
    this.getroles()
  },
  methods: {
    upload(){
      document.getElementById('my_file').click();
    },
    onFileChange(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.onUploadFile(files[0]);
      // this.createBase64Image(selectedImage);
    },
    onUploadFile(selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      var link = LinkImageService.getLink()
      var http = link + '/uploadimage?type=attorney'+'&&name=' +selectedFile.name;
      var path = link + '/uploads/attorney/'+selectedFile.name;
      axios
        .post(http, formData)
        .then(() => {    
          this.data.file = path
          this.data.file_name = selectedFile.name
          this.file_name = selectedFile.name
          console.log(this.data);
          // MapDocService.deletemap_doc_permision(this.doc_id,this.car_id).then((res=>{
          // var file ={
          //   permission_doc_id:this.car_id,
          //   document_id:this.doc_id,
          //   name:selectedFile.name,
          //   path:path,
          //   status:1,
          //   created_by:this.currentUser.id
          // }
          // MapDocService.createmap_doc_permision(file).then(()=>{
          //   // console.log(res.data);
           
          //   this.getdocs()
          // })
            
          // }))
        
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getroles(){
      AgencyService.getAgency().then((res)=>{
        this.roles = res.data
      })
    },
    getid(id,status) {
      var userdata = {
          activate: status,
        };
          AgencyService.updateAgencyActivate(id, userdata).then(() => {
            // console.log(res.data);
            alert('บันทึกสำเร็จ')
            this.getUsers()
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
    },
    savetolist() {
      if (this.data.company_name == "" || this.data.company_name == null ) {
        alert("กรุณากรอกชื่อบริษัท");
      } else if (this.data.file == "" || this.data.file == null) {
        alert("กรุณาเลือกไฟล์หนังสือมอบอำนาจ");
      } else {
        if (this.data.id == null) {
          this.datalist.push({
          user_id: this.currentUser.id,
          company_name: this.data.company_name,
          file_name: this.data.file_name,
          file: this.data.file,
          activate: false,
          status: true,
        })
        }else{
          for (let d = 0; d < this.datalist.length; d++) {
            if (this.datalist[d].id == this.data.id) {
              this.datalist[d].company_name = this.data.company_name
              this.datalist[d].file_name = this.data.file_name
              this.datalist[d].file = this.data.file
            }
            
          }
        }
        this.data = {}
        this.file_name = ''
      }
    },
    save() {
      for (let index = 0; index < this.datalist.length; index++) {
        var userdata = {
          user_id: this.datalist[index].user_id,
          company_name: this.datalist[index].company_name,
          file_name: this.datalist[index].file_name,
          file: this.datalist[index].file,
          activate: this.datalist[index].activate,
          status: this.datalist[index].status,
        };
        if (this.datalist[index].id == '' || this.datalist[index].id == null) {
          AgencyService.createAgency(userdata).then(() => {
            // console.log(res.data);
            // this.getUsers();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        }else{
          AgencyService.updateAgency(this.datalist[index].id, userdata).then(() => {
            // console.log(res.data);
            
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        }
        if (index+1 == this.datalist.length) {
           alert('บันทึกสำเร็จ')
           this.getUsers();
            //     setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
        }
      }
     
    },
    getUsers() {
      AgencyService.getAgencies('','').then((res) => {
        this.datalist = res.data;
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
#my_file {
    display: none;
}
</style>
