<template>
  <div class="container">
    <div
      class="card mb-5"
    >             <div class="card-body login-card-body">
            <form ref="form" @submit.prevent="sendEmail">
              <div class="card-body">
                <div class="form-group mb-3" style="display:none;">
                  <label for="username">อีเมล<span style="color: red">*</span>  ใช้ Gmail เท่านั้น</label>
                  <input
                  name="to_email"
                    v-model="user.email"
                    type="email"
                    min="1"
                    class="form-control form-control-sm"
                    id="to_email"
                  />
                </div>
                <div class="form-group mb-3" style="display:none;">
                  <label for="username">form_name</label>
                  <input
                  v-model="user.from_name"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    name="from_name"
                  />
                </div>
                <div v-if="currentUser.role_id == 2">
                <label for="username">รายการเอกสารที่ไม่ได้แนบ</label>
                <div class="form-group mb-3">
                  <tbody v-if="docs.length > 0">
            <tr v-for="(dd, i) in docsfailed" :key="i + 1">
              <td> <input v-model="dd.status" type="checkbox" @change="selectchk()" :id="dd.id" :name="dd.id" :value="dd.name"> <label :for="dd.id"> {{dd.name}}</label><br> </td>
            </tr>
          </tbody>
        </div></div>
                  <label for="username">ข้อความจากเจ้าหน้าที่ถึงผู้ประกอบการ</label>
                 
                  <textarea name="message" v-model="user.office_to_operator" type="text" rows="3"
                  class="form-control form-control-sm"></textarea>
                
                 <div class="row mt-3" v-if="currentUser.role_id == 2 && user.status_id != 5">
                  <div class="col-md-6 text-left">
                   <button
                    @click="signIn()"
                    type="button"
                    class="btn btn-warning btn-sm"
                  >
                  ส่งให้ผู้ประกอบการขนส่งแก้ไข
                  </button></div>
                  <div class="col-md-3"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
    </div>
</template>

<script>
import emailjs from 'emailjs-com';
import PermissionDocService from '../services/PermissionDocService.js'
import UserService from '../services/UserService'
import LinkImageService from '../services/LinkImageService'
import LogNotifyService from '../services/LogNotifyService'
import MapDocService from "../services/MapDocService";
import DocumentService from "../services/DocumentService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      menu: [],
      user: {},
      per_id:0,
      docs:[],
      docstemp:[],
      docsfailed:[],
      listdoc:[]
    };
  },
  mounted() {
    this.per_id = this.$route.params.id;
    this.getdocs()
    PermissionDocService.getpermission_doc(this.per_id).then(async (res)=>{
        // console.log(res.data);
    this.user = res.data
    this.user.from_name = 'คุณได้รับข้อความจากเจ้าหน้าที่'   
    if (this.user.office_to_operator == null) {
      this.user.office_to_operator == ''
    }
    })
  },
  methods: {
    selectchk(){
this.listdoc = []
for (let d = 0; d < this.docsfailed.length; d++) {
  // console.log(this.docsfailed[d]);
  if (this.docsfailed[d].status == true) {
  this.listdoc.push(this.docsfailed[d].name)
  }
  
}
// console.log(this.user.office_to_operator);
if (this.user.office_to_operator == null) {
  this.user.office_to_operator = ""
}else{
  this.user.office_to_operator = ""
}
for (let f = 0; f < this.listdoc.length; f++) {
  if (this.user.office_to_operator == "") {
    this.user.office_to_operator = this.listdoc[f]+'\n'
  }else{
    this.user.office_to_operator = this.user.office_to_operator 
  + this.listdoc[f]
  }
  
  
}
// this.user.office_to_operator = this.user.office_to_operator + d
    },
    getdocs(){
      DocumentService.getdocuments().then(async (res)=>{
        // console.log(res.data);
        this.docstemp = res.data
        await MapDocService.getmap_doc_permision(this.per_id).then((docs)=>{
        // console.log(docs.data);
        var doclist = docs.data
        if (doclist.length > 0) {
         for (let d = 0; d < this.docstemp.length; d++) {
          for (let dd = 0; dd < doclist.length; dd++) {
            // console.log(this.docstemp[d].id, doclist[dd].id);
            if (this.docstemp[d].id == doclist[dd].id) {
              this.docstemp[d].path = doclist[dd].path
              this.docstemp[d].name_file = doclist[dd].name_file
            }
          }
         }
        }
        this.docs = this.docstemp
        for (let ff = 0; ff < this.docstemp.length; ff++) {
          if (this.docstemp[ff].name_file == null) {
           this.docsfailed.push({
            id:this.docstemp[ff].id,
            name:this.docstemp[ff].name,
            path :this.docstemp[ff].path,
              name_file :this.docstemp[ff].name_file,
              status:false
           })
          }
          // console.log(this.docs);
        }
      });
      })
// MapDocService.getmap_doc_permision(this.car_id).then((res)=>{
//         console.log(res.data);
//         if (res.data.length == 0) {
         
//         }else{
//           this.docs = res.data
//         }
//       });
    },
    generateGuid() {
  var result, i, j;
  result = '';
  for(j=0; j<32; j++) {
    if( j == 8 || j == 12 || j == 16 || j == 20)
      result = result + '-';
    i = Math.floor(Math.random()*16).toString(16).toUpperCase();
    result = result + i;
  }
  return result;
},
    sendEmail() {
      var templateParams = {
        to_email: this.user.email,
        header: 'หนังสืออนุญาตคำขอที่ '+ this.user.request_at,
    message: 'คุณได้รับข้อความจากเจ้าหน้าที่ หนังสืออนุญาตคำขอที่ '+ this.user.request_at+ " "+ this.user.office_to_operator+ ' ตรวจสอบได้ที่ลิงก์ ' +LinkImageService.getLinkFrontend()+'/permissiondocedit/'+this.per_id,
  };
        emailjs.send('service_ziurs0r', 'template_7bfnzks',templateParams,
        'v6IRDEPdvfhe_ir4C').then((result) => {
            console.log('SUCCESS!', result.text);
        }, (error) => {
            console.log('FAILED...', error.text);
        }
        )
        
    
    },
    signIn() {
      var commenttooperator = {
        officer_id:this.currentUser.id,
        office_to_operator:this.user.office_to_operator,
        status_id:2,
        update_by:this.currentUser.id
      }
      // document.getElementById("to_email").value = this.user.from_name +' https://owb.visdchiangrai.com/permissiondocedit/'+this.per_id
      PermissionDocService.updatecommenttooperator(this.per_id,commenttooperator).then(()=>{
        if (this.user.office_to_operator =="" || this.user.office_to_operator == null) {
          alert('บันทึกสำเร็จ')
        }else{
      this.sendEmail()
      UserService.getUser(this.user.created_by).then(async (res)=>{
      // console.log(res.data);
      if (res.data.line_token != null) {
        await LinkImageService.sendNotify(this.user.office_to_operator+' ' +LinkImageService.getLinkFrontend()+'/permissiondocedit/'+this.per_id,res.data.line_token) 
      }
    })
    var notify ={
          permission_docs_id:this.per_id,
          messages:this.user.office_to_operator,
          created_by:this.currentUser.id
}
LogNotifyService.createlog_notify(notify).then(()=>{
// console.log(res.data);
})
       alert('แจ้งอีเมลไปยังผู้ประกอบการเพื่ออัพโหลดเอกสารเพิ่มเติมเรียบร้อยแล้ว')
}
      })
    
    }
    
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  font-size: 14px;
  font-weight: bold;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

span {
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
  text-align: justify;
}

body {
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  font-size: 14px;
  color: black;
}

div.aa {
  text-align: justify;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

p {
  text-indent: 25px;
  text-align: justify;
  margin-bottom: 0px;
  font-size: 14px;
  /* font-family:Angsana New; */
  font-family: 'Niramit';
  color: black;
}

div {
  font-family: 'Niramit';
  font-size: 14px;
}

.title {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.content {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom-style: dotted;
  border-bottom: 1.6px dotted;
}

input {
  margin-bottom: 5px;
}
</style>
